
.vue-pincode-input {
    outline: none;
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px;
    width: 36px;
    text-align: center;
    font-size: 28px;
    background: #fff;
    border: 1px solid #717171;
    border-radius: 4px;
    line-height: 0.8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);

    &:nth-child(3n + 3) {
        margin-right: 10px;
    }

    &:nth-child(4) {
        margin-left: 10px;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &:disabled,
    &.isDisabled {
        background: #dfe3e9;
        cursor: not-allowed;
        color: #717171;
        &.hasToolTip {
            pointer-events: inherit;
        }
    }

    &:focus-visible {
        border: 1px solid #0071be;
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.d-flex {
    display: flex !important;
}
