
.numberInput {
    display: inline-block;
}

.input-group-addon,
.input-group-addon:last-child {
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border: 1px solid #717171;
    border-left: 0;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
}

.input-group input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.iratPoints .numberInput {
    width: 110px;
}

.timerTopBar #durationHours-div .input-group,
.timerTopBar #durationMinutes-div .input-group {
    margin-bottom: 0;
    width: 122px;
}

input.iratDurationMinutes,
input.iratDurationHours,
input.tratDurationMinutes,
input.tratDurationHours,
input.applicationDurationMinutes,
input.applicationDurationHours,
input.evaluationDurationMinutes,
input.evaluationDurationHours {
    width: 64px;
}

input.iratDurationDays,
input.tratDurationDays,
input.applicationDurationDays,
input.evaluationDurationDays {
    width: 64px;
}

.fixedWidthNumber {
    width: 64px !important;
}

.form-control[type='number'] {
    outline: 0;
    outline-offset: 0;
    box-shadow: none;
}

.numberGroup {
    display: inline-flex;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 3px;
    }
}

.input-tooltip {
    position: absolute;
    bottom: 100%;
    right: -1%;
    background-color: rgba(73, 80, 87, 0.9);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    min-width: 10em;
    max-width: 30em;
    font-size: 14px;
    width: 15em;
    white-space: normal;
    font-size: 12px;
}

.input-tooltip-arrow {
    position: absolute;
    top: 100%;
    right: 10%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-top-color: #495057;
    pointer-events: none;
}

@media only screen and (max-width: 360px) {
    .input-tooltip {
        min-width: 100%;
        max-width: inherit;
    }
}
