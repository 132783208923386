/* Test */
.question {
    font-size: 1rem;
    font-weight: normal;
    word-break: break-word;
}

input[type='radio'],
input[type='checkbox'] {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;

    &:focus-visible {
        outline: 3px solid #1941a6;
        outline-offset: 3px;
    }
}

.checkboxField {
    display: flex;

    &.d-inlineBlock {
        display: inline-block;
    }

    [type='checkbox'] {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        background-color: #ffffff;
        border: solid 2px #717171;
        box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
        background-size: 0 0;

        &:not(:only-child) {
            margin-right: 10px;

            &.marginRight0 {
                margin-right: 0;
            }
        }

        &:hover {
            border: 2px solid #717171;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            outline: 10px solid #e7dcf9;
        }

        &:checked {
            background: #761fbb;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 100% 100%;
            border-color: #761fbb;

            &:before {
                display: none;
            }

            &:disabled {
                box-shadow: inherit;
            }
        }

        &:disabled.disabled,
        &.disabled,
        &:disabled,
        &[aria-disabled='true'] {
            background: #efefef;
            border: 2px solid #6c6c6c;
            box-shadow: inherit;

            &:hover {
                background: #efefef;
                border: 2px solid #6c6c6c;
                box-shadow: none;
                outline: none;
            }

            &:checked {
                background: #efefef;
                border: 2px solid #6c6c6c;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236c6c6c' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                background-size: 100% 100%;
            }

            &.hasToolTip {
                pointer-events: inherit;
            }
        }
    }

    input[disabled],
    input.disabled,
    input[aria-disabled='true'] {
        background: #6c6c6c;
        border: 2px solid #6c6c6c;
        box-shadow: none;

        &:checked:before {
            color: #6c6c6c;
        }

        &.hasToolTip {
            pointer-events: inherit;
        }
    }
}

.radioField,
.checkboxField {
    .fa-check-circle,
    .fa-times-circle {
        font-size: 1.5rem !important;
    }

    .wrong {
        color: #ca3333;
    }

    .correct {
        color: #3c7858;
    }

    label {
        display: flex;
        font-size: 1rem;
        margin-left: 20px;
        font-weight: normal;

        &:first-of-type {
            margin-left: 0;
        }

        &.fs-16px {
            font-size: 0.88rem;
        }

        &.control-label {
            font-weight: bold;
        }

        &.d-block {
            display: block;
        }
    }
}

.radioField {
    label {
        width: calc(100% - 30px);
        line-height: 1.2;
    }

    input {
        &[type='radio'] {
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            border: 2px solid #717171;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;
            border-radius: 100px;

            &:checked {
                border: 2px solid #717171;
                background: none;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23761FBB'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }
            }

            &:hover {
                border: 2px solid #717171;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                outline: 10px solid #e7dcf9;
            }

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:disabled,
            &.disabled,
            &[aria-disabled='true'] {
                background: #efefef;
                border: 2px solid #6c6c6c;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    box-shadow: none;
                    border: 2px solid #6c6c6c;
                    outline: none;
                }

                &:checked {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236c6c6c'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }
    }
}

.activitySetup .radioField {
    label {
        width: calc(100% - 30px);
        line-height: 1.2;
    }

    input {
        &[type='radio'] {
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            border: 2px solid #717171;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;
            border-radius: 100px;

            &:checked {
                border: 2px solid #717171;
                background: none;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23761FBB'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }
            }

            &:hover {
                border: 2px solid #717171;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                outline: 10px solid #e7dcf9;
            }

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:disabled,
            &.disabled,
            &[aria-disabled='true'] {
                background: #efefef;
                border: 2px solid #6c6c6c;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    box-shadow: none;
                    border: 2px solid #6c6c6c;
                    outline: none;
                }

                &:checked {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236c6c6c'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }
    }
}

.vote-radio label {
    display: inline-block;
    background-color: #3c7858;
    border-radius: 5px;
    line-height: 1;
    color: #fff;
    padding: 15px;
    font-size: 16px;
}

.voted-radio label {
    display: inline-block;
    background-color: #894812;
    border-radius: 5px;
    line-height: 1;
    color: #fff;
    padding: 15px;
    font-size: 16px;
}

.vote-radio-disabled label {
    display: inline-block;
    background-color: #c5d0de;
    border-radius: 5px;
    line-height: 1;
    color: #fff;
    padding: 15px;
    font-size: 16px;
}

/* End of Test */

.newCheckboxField {
    padding: 8px;
    border-radius: 8px;
    outline: 4px solid transparent;
    border: 4px solid transparent;

    &.paddingLeft35px {
        padding-left: 42px;
    }

    &:focus-within {
        outline: 4px solid #1941a6;
        border: 4px solid rgb(255, 255, 255);

        [type='radio'],
        [type='checkbox'] {
            &:focus-visible {
                outline: none;
            }
        }
    }

    label {
        width: calc(100% - 37px);
        margin-left: 0;
        font-weight: normal;
        display: flex;
        font-size: 1.2rem;
        line-height: 1.5;

        &.widthAuto {
            width: auto;
        }

        &.width100 {
            width: 100%;
        }

        .withOptionKey {
            font-weight: bold;
            padding-right: 5px;
        }

        &.d-block {
            display: block;
        }
        &.textAlignLeft {
            text-align: left;
        }

        &.fs-18px {
            font-size: 1rem;
        }
    }

    .numberInput {
        position: relative;
        width: 80px;
        height: 100%;

        & ~ label {
            width: calc(100% - 90px);
            padding-top: 10px;
        }

        &:not(:only-child) {
            margin-right: 10px;
        }
    }

    .numberInputButtons {
        & ~ label {
            width: calc(100% - 178px);
            padding-top: 10px;

            &.width100 {
                width: 100%;
            }
        }

        &:not(:only-child) {
            margin-right: 10px;
        }
    }

    input {
        border-radius: 8px;
        border-color: #717171;

        &[type='radio'] {
            width: 1.5rem;
            height: 1.5rem;
            background-color: #ffffff;
            border: 2px solid #717171;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;
            border-radius: 100px;

            &:checked {
                border-color: none;
                border: 2px solid #717171;
                background: none;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23761FBB'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }
            }

            &:hover {
                border: 2px solid #717171;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                outline: 10px solid #e7dcf9;
            }

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:disabled,
            &.disabled,
            &[aria-disabled='true'] {
                background: #efefef;
                border: 2px solid #6c6c6c;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    box-shadow: none;
                    border: 2px solid #6c6c6c;
                    outline: none;
                }

                &:checked {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236c6c6c'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }

        &[type='checkbox'] {
            width: 1.5rem;
            height: 1.5rem;
            background-color: #ffffff;
            border: 2px solid #717171;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:hover {
                border: 2px solid #717171;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                outline: 10px solid #e7dcf9;
            }

            &:checked {
                border-color: none;
                background: #761fbb;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;
                border-color: #761fbb;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }
            }

            &:disabled,
            &.disabled,
            &[aria-disabled='true'] {
                background: #efefef;
                border: 2px solid #6c6c6c;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    box-shadow: none;
                    outline: none;
                }

                &:checked {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236c6c6c' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }
    }

    &.smaller-ver {
        padding: 0;

        input {
            width: 20px;
            height: 20px;
            border-radius: 3px;
        }

        label {
            font-size: 1rem;
            line-height: 1.2;

            &.fs-16px {
                font-size: 0.88rem;
            }
        }
    }
}

.numberInput {
    position: relative;
    width: 80px;
    height: 100%;

    &.width100 {
        width: 100%;
    }

    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.766rem;
        color: #222;
        z-index: 4;
        height: 100%;
        max-height: 100%;

        .quantity-button {
            min-height: 18px;
            height: 50%;
            display: flex;
            align-items: center;
            min-width: 22px;
            width: 22px;
            max-width: 26px;
            justify-content: center;
            border: 1px solid #717171;
            background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
            border-radius: 1px;

            &:first-child {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }

            &:last-child {
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &.disabled {
                background: #efefef;
                color: #6b6b6f;
                cursor: not-allowed;
                box-shadow: -1px -1px 2px 0 rgba(202, 202, 202, 0.5);
            }
        }
    }
}

.numberInputButtons {
    display: inline-grid;
    grid-template-columns: 44px 60px 44px;
    gap: 10px;
    border-radius: 8px;
    height: 100%;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }

    .btn-outline-dark {
        min-width: 44px;
        height: 100%;
    }
}

.isStudent,
.test,
.newRadioField,
.questionBankOptionsField,
.presentationMode {
    .radioField,
    .checkboxField {
        padding: 8px;
        border-radius: 8px;
        outline: 4px solid transparent;
        border: 4px solid transparent;

        &.paddingLeft35px {
            padding-left: 42px;
        }

        &:focus-within {
            outline: 4px solid #1941a6;
            border: 4px solid rgb(255, 255, 255);

            [type='radio'],
            [type='checkbox'] {
                &:focus-visible {
                    outline: none;
                }
            }
        }

        label {
            width: calc(100% - 37px);
            margin-left: 0;
            font-weight: normal;
            display: flex;
            font-size: 1.2rem;
            line-height: 1.5;

            &.widthAuto {
                width: auto;
            }

            &.width100 {
                width: 100%;
            }

            .withOptionKey {
                /*font-size: 1.2rem;
                line-height: 1.5;*/
                font-weight: bold;
                padding-right: 5px;
            }

            &.d-block {
                display: block;
            }
            &.textAlignLeft {
                text-align: left;
            }

            &.fs-18px {
                font-size: 1rem;
            }

            &.displayBlock {
                display: block;
            }
        }

        .numberInput {
            position: relative;
            width: 80px;
            height: 100%;

            & ~ label {
                width: calc(100% - 90px);
                padding-top: 10px;
            }

            &:not(:only-child) {
                margin-right: 10px;
            }
        }

        .numberInputButtons {
            & ~ label {
                width: calc(100% - 178px);
                padding-top: 10px;

                &.width100 {
                    width: 100%;
                }
            }

            &:not(:only-child) {
                margin-right: 10px;
            }
        }

        input {
            border-radius: 8px;
            border-color: #717171;

            &[type='radio'] {
                width: 1.5rem;
                height: 1.5rem;
                background-color: #ffffff;
                border: 2px solid #717171;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 0 0;
                border-radius: 100px;

                &:checked {
                    border-color: none;
                    border: 2px solid #717171;
                    background: none;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23761FBB'/%3e%3c/svg%3e");
                    box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                    background-size: 100% 100%;

                    &:before {
                        display: none;
                    }

                    &:disabled {
                        box-shadow: inherit;
                    }
                }

                &:hover {
                    border: 2px solid #717171;
                    box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                    outline: 10px solid #e7dcf9;
                }

                &:not(:only-child) {
                    margin-right: 10px;
                }

                &:disabled,
                &.disabled,
                &[aria-disabled='true'] {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    box-shadow: inherit;

                    &:hover {
                        background: #efefef;
                        box-shadow: none;
                        border: 2px solid #6c6c6c;
                        outline: none;
                    }

                    &:checked {
                        background: #efefef;
                        border: 2px solid #6c6c6c;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236c6c6c'/%3e%3c/svg%3e");
                        background-size: 100% 100%;
                    }
                }
            }

            &[type='checkbox'] {
                width: 1.5rem;
                height: 1.5rem;
                background-color: #ffffff;
                border: 2px solid #717171;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 0 0;

                &:not(:only-child) {
                    margin-right: 10px;
                }

                &:checked {
                    border-color: none;
                    background: #761fbb;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                    box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                    background-size: 100% 100%;
                    border-color: #761fbb;

                    &:before {
                        display: none;
                    }

                    &:disabled {
                        box-shadow: inherit;
                    }
                }

                &:disabled,
                &.disabled,
                &[aria-disabled='true'] {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    box-shadow: inherit;

                    &:hover {
                        background: #efefef;
                        border: 2px solid #6c6c6c;
                    }

                    &:checked {
                        background: #efefef;
                        border: 2px solid #6c6c6c;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236c6c6c' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

    .fa-check-circle,
    .fa-check-square {
        font-size: 1rem;
    }

    .correctOption {
        background-color: #f3fffd;
        border: 2px solid #3c7858;

        .fa-check-circle,
        .fa-check-square {
            color: #3c7858;
        }
    }

    .newCorrect {
        background-color: #eef4f3;
        border: 2px solid #497970;

        .fa-check-circle,
        .fa-check-square {
            color: #497970;
        }
    }

    &.smaller-ver {
        padding: 0;

        input {
            width: 20px;
            height: 20px;
            border-radius: 3px;
        }

        label {
            font-size: 1rem;
            line-height: 1.2;

            &.fs-16px {
                font-size: 0.88rem;
            }
        }
    }
}

.activityType {
    .radioField {
        & > div {
            align-items: baseline;
        }

        label {
            line-height: 0.9;
        }
    }
}

.radioField-multipleRadio {
    label {
        width: calc(100% - 30px);
        line-height: 1.2;
    }

    input {
        &[type='radio'] {
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            border: 2px solid #717171;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;
            border-radius: 100px;

            &:checked {
                border: 2px solid #717171;
                background: none;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23761FBB'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }
            }

            &:hover {
                border: 2px solid #717171;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                outline: 10px solid #e7dcf9;
            }

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:disabled,
            &.disabled,
            &[aria-disabled='true'] {
                background: #efefef;
                border: 2px solid #6c6c6c;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    box-shadow: none;
                    border: 2px solid #6c6c6c;
                    outline: none;
                }

                &:checked {
                    background: #efefef;
                    border: 2px solid #6c6c6c;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236c6c6c'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }
    }
}

.colorPicker {
    input[type='radio'] {
        width: 42px;
        height: 42px;
        border-radius: 8px;
        margin: 0;
        box-shadow: inset 0 0 0 2px #fff;
        border: 2px solid #222;

        &:checked {
            border: 3px solid #761fbb;
            box-shadow: none;
        }

        &:focus-visible {
            outline-offset: 3px;
            outline: 4px solid #1941a6;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        &:hover {
            border: 2px solid #0071be;
        }
    }

    label {
        width: 10px;
    }
}

.checkboxField.dark-mode {
    [type='checkbox'] {
        &:checked {
            background-color: #cca1ef;
            border-color: #cca1ef;
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23222%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
        }
    }
}
