
.tableStatusTag {
    text-align: center;
    padding: 6px 8px 4px 8px;
    border-radius: 44px;
    background-color: #fff;
    font-size: 0.88rem;
    line-height: 1;

    p {
        font-size: 0.88rem;
        display: inline-block;
    }

    &.tableStatusTag-dark,
    &.Starts {
        background: #efefef;
        border: 1px solid #717171;
        color: #3c3c43;
    }

    &.tableStatusTag-primary {
        background: #93c4f9;
        border: 1px solid #0071be;
        color: #3c3c43;
    }

    &.tableStatusTag-warning,
    &.tableStatusTag-emoji-warning {
        background: #e3c1a0;
        border: 1px solid #ac631b;
        color: #3c3c43;
    }

    &.tableStatusTag-emoji-light-success {
        background: #b7e1bb;
        border: 1px solid #267531;
        color: #3c3c43;
    }

    &.tableStatusTag-emoji-success {
        background: #b0e0c5;
        border: 1px solid #1b7c48;
        color: #3c3c43;
    }

    &.tableStatusTag-success {
        background: #a5c9b7;
        border: 1px solid #3c7858;
        color: #3c3c43;
    }

    &.tableStatusTag-emoji-yellow {
        background: #f9e1b2;
        border: 1px solid #90660a;
        color: #3c3c43;
    }

    &.tableStatusTag-danger,
    &.tableStatusTag-emoji-danger {
        background: #e6aeae;
        border: 1px solid #ca3333;
        color: #3c3c43;
    }

    &.danger {
        border: 1px solid #ca3333;
        background: #ca3333;
        color: #fff;
    }

    &.default {
        border: 1px solid #6c6c6c;
        background: #efefef;
        color: #717171;
    }

    &.answered {
        border: 1px solid #3c7858;
        background: #3c7858;
        color: #ffffff;
    }

    &.tableStatusTag-light-warning {
        background: #e8c57b;
        border: 1px solid #e8c57b;
        color: #3c3c43;
        font-weight: normal;
    }

    &.dynamic-status {
        background: var(--main-color);
        border: 2px solid color-mix(in srgb, var(--main-color) 65%, black);
        color: #fff;

        &[style*='--main-color: #BDF3E8'],
        &[style*='--main-color: #C4C9FA'],
        &[style*='--main-color: #DCD3F9'],
        &[style*='--main-color: #FFE3FF'],
        &[style*='--main-color: #DBE1E5'] {
            border: 2px solid color-mix(in srgb, var(--main-color) 50%, black);
            color: #222;
        }
    }
}

.testSidebar-status {
    font-size: 0.667rem;
    line-height: 1;
    padding: 6px 12px;

    p {
        font-size: 0.667rem;
        line-height: 1;
    }

    &.danger {
        padding: 6px 8px;
    }
}
