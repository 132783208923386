
.mini-panel .panel-heading {
    font-size: 14px;
}

.mini-panel .panel-heading .link {
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

.discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.mini-panel.panel {
    margin-bottom: 0;
}

.btnDiscussionTeams {
    margin-top: 10px;
}

.btnDiscussionTeams:first-child {
    margin-top: 0;
}

.mini-table .numberInput input {
    width: 100px;
}

.staticDetails {
    font-size: 14px;
}

.panel-content {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.panel-content:last-child {
    border-bottom: 0;
}

.width230px {
    width: 230px;
}

.modal_comments {
    margin-bottom: 20px;

    &:last-child,
    &:only-child {
        margin-bottom: 0;
    }
}

.gridDiscussion {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
}

.limitText {
    font-size: 14px;
    margin-top: 10px;
    float: right;
    color: #717171;

    &.fontRed {
        color: #ca3333;
    }
}
