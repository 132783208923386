
.input-group {
    &.date {
        &:focus-within {
            outline: 4px solid #1941a6;
            outline-offset: 3px;
            box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        }

        .form-control:focus-visible {
            outline: 0;
            outline-offset: 0;
            box-shadow: none;
        }
    }
}

.has-error {
    .input-group {
        &.date {
            border-color: #ca3333;
        }
    }
}
