<template>
    <div class="create row flex">
        <div class="profileImg col-md-3">
            <div class="col-xs-12" style="padding: 0">
                <kr-file-picture :form="form" name="avatar" :options="{ width: '200', height: '200' }"></kr-file-picture>
            </div>
        </div>
        <div class="col-xs-12 col-md-9">
            <div class="col-xs-12 col-md-4">
                <kr-field
                    :display="$t('modules.accounts.create.organisationNameLabel')"
                    :form="form"
                    name="organisationName"
                    aria-label="organisation name"
                    :options="{
                        placeholder: 'e.g., CognaLearn',
                        required: true,
                    }"
                ></kr-field>
            </div>
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-4">
                    <kr-field
                        :display="$t('modules.accounts.create.firstNameSuperAdminLabel')"
                        :form="form"
                        name="firstname"
                        aria-label="first name of super admin"
                        :options="{ placeholder: 'e.g., John', required: true }"
                    ></kr-field>
                </div>
                <div class="col-xs-12 col-md-4">
                    <kr-field
                        :display="$t('modules.accounts.create.lastNameSuperAdminLabel')"
                        :form="form"
                        name="lastname"
                        aria-label="last name of super admin"
                        :options="{ placeholder: 'e.g., Doe', required: true }"
                    ></kr-field>
                </div>
                <div class="col-xs-12 col-md-4">
                    <kr-field
                        :display="$t('modules.accounts.create.jobDesignationLabel')"
                        :form="form"
                        name="jobDescription"
                        aria-label="JOB DESIGNATION"
                        :options="{ placeholder: '' }"
                    ></kr-field>
                </div>
            </div>
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-4">
                    <template v-if="mode == 'create'">
                        <kr-field
                            :display="$t('modules.accounts.create.emailLabel')"
                            :form="form"
                            name="email"
                            aria-label="email"
                            :options="{
                                placeholder: 'e.g., johndoe@example.com',
                                required: true,
                            }"
                        ></kr-field>
                    </template>
                    <template v-else>
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.create.emailLabel') }}</label>
                            <p class="form-control-static">
                                {{ form.model.email }}
                            </p>
                        </div>
                    </template>
                </div>
                <div class="col-xs-12 col-md-4">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.accounts.create.influencerLevelLabel')"
                        name="influencerLevel"
                        :form="form"
                        :items="kr_var.influencerLevels"
                        item_value_idx="value"
                        item_desc_idx="description"
                    ></kr-select>
                </div>
                <div class="col-xs-12 col-md-4">
                    <kr-field :display="$t('modules.accounts.create.lmsLabel')" :form="form" name="lms" :options="{ placeholder: '' }"></kr-field>
                </div>
            </div>
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-4">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.accounts.create.segmentLabel')"
                        name="segment"
                        :form="form"
                        :items="[
                            { value: 'Higher Ed', description: 'Higher Ed' },
                            { value: 'K-12', description: 'K-12' },
                            { value: 'Training', description: 'Training' },
                        ]"
                        item_value_idx="value"
                        item_desc_idx="description"
                        @changed="processType"
                    ></kr-select>
                </div>
                <div class="col-xs-12 col-md-4">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.accounts.create.fieldOfDisciplineLabel')"
                        name="fieldOfDiscipline"
                        :form="form"
                        :items="kr_var.fieldsOfDiscipline"
                        item_value_idx="value"
                        item_desc_idx="description"
                    ></kr-select>
                </div>
                <div v-if="form.model.fieldOfDiscipline == 'Others'" class="col-xs-12 col-md-4">
                    <kr-field
                        :display="$t('modules.accounts.create.fieldOfDisciplineLabel')"
                        :form="form"
                        name="otherFieldOfDiscipline"
                        :options="{ placeholder: '' }"
                    ></kr-field>
                </div>
            </div>

            <div class="col-xs-12 flex marginTop30">
                <div class="col-xs-12 col-md-4">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.accounts.create.countryLabel')"
                        aria-label="country"
                        name="country"
                        :items="kr_var.countries"
                        :form="form"
                        @changed="
                            form.model.stateOrCity = '';
                            processCities();
                        "
                    >
                        <template #option="props">
                            <template v-for="item in props.model">
                                <option :value="item">
                                    {{ item }}
                                </option>
                            </template>
                        </template>
                    </kr-select>
                </div>
                <div class="col-xs-12 col-md-4">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.accounts.create.cityStateLabel')"
                        aria-label="city/state"
                        name="stateOrCity"
                        :items="cities"
                        :form="form"
                    >
                        <template #option="props">
                            <template v-for="item in props.model">
                                <option :value="item">
                                    {{ item }}
                                </option>
                            </template>
                        </template>
                    </kr-select>
                </div>
                <div class="col-xs-12 col-md-4">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.accounts.create.timezoneLabel')"
                        name="timezone"
                        :items="kr_var.tzStrings"
                        :form="form"
                        item_value_idx="value"
                        item_desc_idx="label"
                    >
                    </kr-select>
                </div>
            </div>
            <div class="col-xs-12 col-md-4 marginTop20">
                <kr-select class="no-error" :display="$t('modules.accounts.create.accountOwnerLabel')" name="accountOwnerUuid" :items="superusers" :form="form">
                    <template #option="props">
                        <template v-for="user in props.model">
                            <option :value="user.uuid">
                                {{ user.displayName }}
                            </option>
                        </template>
                    </template>
                </kr-select>
            </div>
            <div class="col-xs-12 flex marginTop30">
                <div class="col-xs-12 col-md-4 paddingLeft0">
                    <template v-if="mode == 'create'">
                        <kr-select
                            class="no-error"
                            :display="$t('modules.accounts.create.typeLabel')"
                            aria-label="type"
                            name="type"
                            :form="form"
                            :items="[
                                { value: 'Free', description: 'Free' },
                                {
                                    value: 'Free Trial',
                                    description: 'Free Trial',
                                },
                                {
                                    value: 'Paid Trial',
                                    description: 'Paid Trial',
                                },
                                { value: 'Paid', description: 'Paid' },
                                {
                                    value: 'Testing',
                                    description: 'Internal / Testing',
                                },
                            ]"
                            item_value_idx="value"
                            item_desc_idx="description"
                            @changed="processType"
                        ></kr-select>
                    </template>
                    <template v-else>
                        <div class="form-group">
                            <label class="control-label">{{ $t('modules.accounts.edit.typeLabel') }}</label>
                            <p class="form-control-static">
                                {{ form.model.type }}
                            </p>
                        </div>
                    </template>
                </div>
                <div class="hidden-xs hidden-sm col-md-4">
                    <template v-if="mode == 'create'">
                        <kr-select
                            v-if="form.model.type == 'Paid' || form.model.type == 'Paid Trial'"
                            class="no-error"
                            display="PAYMENT METHOD <span class='important'>*</span>"
                            name="paymentMethod"
                            aria-label="payment method"
                            :form="form"
                            :items="[
                                {
                                    value: 'Institution',
                                    description: 'Institution',
                                },
                                {
                                    value: 'Student-Paid',
                                    description: 'Student-Paid',
                                },
                            ]"
                            item_value_idx="value"
                            item_desc_idx="description"
                        ></kr-select>
                    </template>
                    <template v-else>
                        <div class="form-group">
                            <label class="control-label">PAYMENT METHOD</label>
                            <p class="form-control-static">
                                {{ form.model.paymentMethod }}
                            </p>
                        </div>
                    </template>
                </div>
                <div class="col-xs-12 hidden-md hidden-lg marginTop20">
                    <template v-if="mode == 'create'">
                        <kr-select
                            v-if="form.model.type == 'Paid' || form.model.type == 'Paid Trial'"
                            class="no-error"
                            display="PAYMENT METHOD <span class='important'>*</span>"
                            name="paymentMethod"
                            aria-label="payment method"
                            :form="form"
                            :items="[
                                {
                                    value: 'Institution',
                                    description: 'Institution',
                                },
                                {
                                    value: 'Student-Paid',
                                    description: 'Student-Paid',
                                },
                            ]"
                            item_value_idx="value"
                            item_desc_idx="description"
                        ></kr-select>
                    </template>
                    <template v-else>
                        <div class="form-group">
                            <label class="control-label">PAYMENT METHOD</label>
                            <p class="form-control-static">
                                {{ form.model.paymentMethod }}
                            </p>
                        </div>
                    </template>
                </div>
            </div>
            <!-- When Institute is selected in Payment Method Calendar-->
            <div class="col-xs-12 flex marginTop30">
                <div class="col-xs-12 col-md-4">
                    <kr-date
                        :display="$t('modules.accounts.create.contractStartDateLabel')"
                        :form="form"
                        name="startDate"
                        aria-label="contract start date"
                        :options="{
                            maxDate: form.model.expiryDate,
                            time: false,
                            placeholder: 'Select account start date',
                        }"
                    ></kr-date>
                </div>
                <div class="hidden-xs hidden-sm col-md-4">
                    <kr-date
                        :display="$t('modules.accounts.create.contractEndDateLabel')"
                        :form="form"
                        name="expiryDate"
                        aria-label="contract end date"
                        :options="{
                            minDate: form.model.startDate,
                            time: false,
                            placeholder: 'Select account expiry date',
                        }"
                    ></kr-date>
                </div>
                <div class="col-xs-12 hidden-md hidden-lg marginTop20">
                    <kr-date
                        :display="$t('modules.accounts.create.contractEndDateLabel')"
                        :form="form"
                        name="expiryDate"
                        aria-label="contract end date"
                        :options="{
                            minDate: form.model.startDate,
                            time: false,
                            placeholder: 'Select account expiry date',
                        }"
                    ></kr-date>
                </div>
            </div>
            <div class="col-xs-12 flex marginTop30">
                <div class="col-xs-12 col-md-4">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.accounts.create.versionLabel')"
                        name="version"
                        aria-label="version"
                        :form="form"
                        :items="[
                            { value: 'full', description: 'Full' },
                            { value: 'lite', description: 'Lite' },
                        ]"
                        item_value_idx="value"
                        item_desc_idx="description"
                    ></kr-select>
                </div>
            </div>

            <!-- When Student is selected in Payment Method -->

            <!--div class="col-xs-12 marginTop30" v-if="(form.model.type=='Paid'||form.model.type=='Paid Trial')&&form.model.paymentMethod=='Student-Paid'">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>PLAN <span class='important'>*</span></th>
                                <th>PRICE <span class='important'>*</span> </th>
                                <th>PLAN PERIOD <span class='important'>*</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(plan,idx) in form.model.paymentPlans" :key="idx">
                                <td>
                                     <kr-field class="no-error" :form="form" :name="'paymentPlans.'+idx+'.name'" :options="{placeholder:'Add a new plan',hasLabel:false}"></kr-field>
                                </td>
                                <td>
                                    <div class="input-group currency">
                            
                                        <div class="input-group-addon">
                                            <div class="form-dropdown">
                                                <select v-model="plan.currency">
                                                    <option value="USD">USD</option>
                                                    <option value="SGD">SGD</option>
                                                    <option value="EUR">EURO</option>
                                                 </select>
                                            </div>
                                        </div>
                                        <kr-field :form="form" :name="'paymentPlans.'+idx+'.price'" :options="{placeholder:'Enter price',type:'number',hasLabel:false}"></kr-field>
                                    </div>
                                </td>
                                <td>
                                    <div class="row" style="text-align:center;font-size:20px;line-height:30px">
                                        <template v-if="plan.licensePeriod==18&&plan.licensePeriodType=='weekly'">
                                            18 Weeks
                                        </template>
                                        <template v-if="plan.licensePeriod==6">
                                            6 Months
                                        </template>
                                        <template v-if="plan.licensePeriod==12">
                                            12 Months
                                        </template>
                                    </div>    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>-->
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'mode', 'account', 'superusers'],
    data() {
        return {
            cities: [],
        };
    },
    computed: {
        /*canUpdatePaymentPlan(){
                if(!this.account){
                    return false;
                }
                if(!_.isEqual(this.account.paymentPlans,this.form.model.paymentPlans)){
                    if(this.form.model.paymentPlans[0].name!=''&&this.form.model.paymentPlans[0].licensePeriod!=''&&this.form.model.paymentPlans[0].price!=''){
                        return true;
                    }
                }
                return false;
            },*/
    },
    created() {
        var that = this;
        /*if(this.form.model.paymentPlans.length==0){
                this.form.model.paymentPlans.push({name:'',price:'',currency:'USD',licensePeriod:18,licensePeriodType:'weekly'});
                this.form.model.paymentPlans.push({name:'',price:'',currency:'USD',licensePeriod:12,licensePeriodType:'monthly'});
            }
            if(this.form.model.paymentPlans.length==1){
                this.form.model.paymentPlans.push({name:'',price:'0',currency:'USD',licensePeriod:12});
            }*/
        /*Events.listen('addPaymentPlan',function(){
                that.addPaymentPlan();
            })*/
        this.processCities();
    },
    methods: {
        processCities() {
            if (this.form.model.country) {
                this.cities = [];
                for (var i = 0; i < _.get(this.kr_var.countriesAndCitiesUnformatted, this.form.model.country, []).length; i++) {
                    this.cities.push(this.kr_var.countriesAndCitiesUnformatted[this.form.model.country][i].City.trim());
                }
            } else {
                this.cities = [];
            }
        },
        processType() {
            if (this.form.model.type != 'Paid' && this.form.model.type != 'Paid Trial') {
                this.form.model.paymentMethod = 'Institution';
            }
        },
        /*preemptAddNewPaymentPlan(idx){
                if(this.form.model.paymentPlans.length-1==idx){
                    this.addPaymentPlan();
                }
            },*/
        /*addPaymentPlan(){
                this.form.model.paymentPlans.push({name:'',price:'',currency:'SGD',licensePeriod:6});
            },
            deletePaymentPlan(idx){
                this.form.model.paymentPlans.splice(idx,1);
            },*/
        /*savePaymentPlan(plan){
                var that = this;
                if(!plan.uuid){
                    axios.post('accounts/'+this.account.uuid+'/payment-plans',plan).then(function(response){
                        that.$notify({
                            group: 'form',
                            type:'success',
                            title: 'Success',
                            text: 'Payment plan successfully saved'
                        });
                    });
                }else{
                    axios.put('accounts/'+this.account.uuid+'/payment-plans/'+plan.uuid,plan).then(function(response){
                        that.$notify({
                            group: 'form',
                            type:'success',
                            title: 'Success',
                            text: 'Payment plan successfully updated'
                        });
                    })
                }
            },*/
    },
};
</script>

<style scoped>
div.createDropdown {
    display: block;
    margin-bottom: 30px;
    width: 100%;
}

.form-group {
    width: 100%;
}

.form-control-static {
    padding: 0;
    min-height: auto;
    margin-bottom: 30px;
}

.input-group.currency .input-group-addon {
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #d8d8d8;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}

.col-md-4 {
    padding-right: 5px;
}
</style>
