
@keyframes filling {
    from {
        stroke-dashoffset: v-bind('circleSize');
    }
    to {
        stroke-dashoffset: v-bind('filledCircle');
    }
}
.circle-progress {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    &__circle {
        fill: transparent;
        stroke-width: 4px;
        stroke: #fff;
        stroke-dashoffset: 0;
    }

    &__progression {
        fill: transparent;
        stroke-width: 4px;
        stroke: #f59e0b;
        stroke-linecap: round;
        animation-name: filling;
        animation-duration: 0.5s;
        animation-timing-function: ease-in;
        transition: 0.5s all;
    }

    &__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
