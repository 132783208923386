.tableStatusTag {
    text-align: center;
    padding: 6px 8px 4px 8px;
    border-radius: 44px;
    background-color: #fff;
    font-size: 0.88rem;
    line-height: 1;

    p {
        font-size: 0.88rem;
        display: inline-block;
    }

    &.tableStatusTag-default {
        background: #ffffff;
        border: 1px solid #d8d8d8;
        color: #3c3c43;
        box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    }
}
