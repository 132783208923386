<template>
    <div class="padding20">
        <div class="question col-xs-12 col-md-10 marginBottom20">
            <h4 class="margin0 fs-18px fw-bold">Question Stem</h4>
            <kr-math :input="question.question" :safe="!question.questionIsHTML"></kr-math>

            <template v-if="question.attachments && question.attachments.length != 0">
                <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
                <div class="textLink">
                    <div v-for="(file, idx) in question.attachments" class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                        <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                    </div>
                </div>
            </template>
        </div>

        <template v-if="[null, 'preparing', 'cancelled'].includes(question.eGallery.status)">
            <template v-for="(answer, answer_idx) in question.answers">
                <template v-if="answer.isSelected || answer.isGoodAnswer">
                    <div
                        class="borderRadius8 marginTop20"
                        :class="{
                            'solid-warning':
                                (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                            'border-black': answer.answer != null,
                            flex: !isMobileView,
                        }"
                    >
                        <div class="flex gap10 align-items padding10">
                            <div class="dropdown">
                                <button
                                    class="btn btn-outline-default margin0 flexInherit"
                                    :class="{
                                        'btn-default-border-warning':
                                            (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                            (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                                    }"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {{
                                        testObj.others.applicationType == 'team'
                                            ? getDisplayName(answer.team.name)
                                            : getDisplayName(answer.student.fullname, 'student')
                                    }}
                                    <i
                                        v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                        class="far fa-comment-dots marginLeft8"
                                        aria-hidden="true"
                                    />
                                </button>
                                <div class="dropdown-menu padding10">
                                    <div>
                                        <div class="flexOnly marginBottom5 align-items">
                                            <i class="fas fa-comments marginRight5 greyText" />
                                            <span class="lineHeight08 fontBold">Elaboration</span>
                                        </div>
                                        <div v-if="answer.comment">
                                            <div class="wordBreakWord whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</div>

                                            <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModelInfoModal(answer)">View More</button>
                                        </div>
                                        <div v-else>-</div>
                                    </div>
                                    <div class="marginTop20">
                                        <div class="flexOnly marginBottom5 align-items">
                                            <i class="fas fa-paperclip marginRight5 greyText" />
                                            <span class="lineHeight08 fontBold">Attachment(s)</span>
                                        </div>
                                        <div>
                                            <template v-if="answer.attachments && answer.attachments.length != 0">
                                                <div v-for="(file, idx) in answer.attachments" class="flex maxHeight100px">
                                                    <span class="marginRight5">{{ idx + 1 }}.</span>
                                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                        file.filename
                                                    }}</a>
                                                </div>

                                                <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModelInfoModal(answer)">
                                                    View More
                                                </button>
                                            </template>
                                            <template v-else> - </template>
                                        </div>
                                    </div>
                                    <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                        <div class="flexOnly marginBottom5 align-items">
                                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                            <span class="lineHeight08 fontBold">Team Members</span>
                                        </div>
                                        <div class="maxHeight100px">
                                            <ul v-for="(student, idx) in answer.team.members">
                                                <li>
                                                    {{ getDisplayName(student.displayName, 'student', idx) }}
                                                </li>
                                            </ul>
                                        </div>

                                        <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModelInfoModal(answer)">View More</button>
                                    </div>
                                </div>
                            </div>

                            <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess">
                                Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                            </div>
                        </div>
                        <div v-if="question.displayAnswer" class="padding10 borderTop1pxGrey width100">
                            <template v-if="answer.answer != null">
                                <div class="wordBreakWord whiteSpacePreWrap" v-dompurify-html="truncate(answer.answer, 800)" />
                                <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openQuestionModal(answer.answer)">View More</button>
                            </template>
                            <p v-else>
                                <i>
                                    <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                    <template v-else> Student </template>
                                    Has Not Answered Yet.
                                </i>
                            </p>
                        </div>
                    </div>
                </template>
            </template>
        </template>

        <div v-if="(question.eGallery.status == 'ongoing') | ['ended'].includes(question.eGallery.status) | ['released'].includes(question.eGallery.status)">
            <div v-if="question.eGallery.status == 'ongoing'" class="table-responsive">
                <table class="table">
                    <caption>
                        e-Gallery Walk Table
                    </caption>
                    <thead>
                        <tr>
                            <th style="min-width: 200px">Team</th>
                            <th style="min-width: 560px">Answer</th>
                            <th style="min-width: 200px">Vote</th>
                            <th style="min-width: 290px">Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(answer, answer_idx) in question.answers">
                            <tr v-if="answer.uuid" :key="answer.uuid">
                                <td>
                                    <button
                                        class="btn gradient btn-default"
                                        :class="{
                                            'btn-default-border-warning':
                                                (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                                (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                                        }"
                                        @click="testObj.others.applicationType == 'team' ? openModelInfoModal(answer) : openModelInfoModal(answer, 'student')"
                                    >
                                        {{
                                            testObj.others.applicationType == 'team'
                                                ? getDisplayName(answer.team.name)
                                                : getDisplayName(answer.student.fullname, 'student')
                                        }}
                                        <i
                                            v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                            class="far fa-comment-dots marginLeft8"
                                            aria-hidden="true"
                                        />
                                    </button>

                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess marginTop10">
                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                    </div>
                                </td>
                                <td>
                                    <template v-if="answer.answer != null">
                                        <div class="wordBreakWord whiteSpacePreWrap" v-dompurify-html="truncate(answer.answer, 800)" />

                                        <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openQuestionModal(answer.answer)">
                                            View More
                                        </button>
                                    </template>
                                    <template v-else>
                                        <div class="fontItalic greyText">
                                            <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                            <template v-else> Student </template>
                                            Has Not Answered Yet.
                                        </div>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="!isMyAnswer(answer)">
                                        <div v-if="testObj.others.applicationType == 'individual' || isTeamLeader" class="radioField padding0 textAlignCenter">
                                            <input
                                                :id="'voting-' + answer.uuid"
                                                v-model="answer.myVote"
                                                type="radio"
                                                :value="true"
                                                @change="toggleBestAnswer(answer)"
                                                style="margin: 0 auto; position: inherit"
                                            />
                                            <label :for="'voting-' + answer.uuid" class="control-label marginTop5 d-block width100 fs-18px">
                                                <b>
                                                    {{
                                                        testObj.others.applicationType == 'team'
                                                            ? 'Vote for ' + getDisplayName(answer.team.name)
                                                            : 'Vote for ' + getDisplayName(answer.student.fullname, 'student')
                                                    }}
                                                </b>
                                            </label>
                                        </div>
                                        <template v-else>
                                            <p v-if="testObj.others.applicationType == 'team'">Only Team leader can vote</p>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <p class="textAlignCenter">Can't vote for your team / yourself</p>
                                    </template>
                                </td>
                                <td>
                                    <div v-if="!isMyAnswer(answer)" class="discussionComment">
                                        <template v-if="testObj.others.applicationType == 'individual' || isTeamLeader">
                                            <label
                                                v-if="
                                                    (question.eGalleryWalkSettings.teamsMustCommentForAllAnswers && !answer.myComment) ||
                                                    (question.eGalleryWalkSettings.teamsMustCommentForVotedAnswersOnly && answer.myVote && !answer.myComment)
                                                "
                                                class="important fontNormal flexRight marginBottom5"
                                            >
                                                * Required
                                            </label>
                                            <label v-else class="greyText fontNormal flexRight marginBottom5"> (Optional) </label>
                                            <textarea
                                                v-model="answer.myComment"
                                                cols="3"
                                                class="form-control js-autoresize"
                                                placeholder="Type your comments here"
                                                aria-label="Comments"
                                                :class="{
                                                    danger:
                                                        (question.eGalleryWalkSettings.teamsMustCommentForAllAnswers && !answer.myComment) ||
                                                        (question.eGalleryWalkSettings.teamsMustCommentForVotedAnswersOnly &&
                                                            answer.myVote &&
                                                            !answer.myComment),
                                                }"
                                                @keydown="answer._isTouched = true"
                                            />
                                        </template>
                                        <template v-else>
                                            <div class="flexSpaceBetween">
                                                <div v-if="answer.myComment" class="wordBreakWord whiteSpacePreWrap" v-dompurify-html="answer.myComment" />
                                                <p v-else>-</p>
                                            </div>
                                        </template>
                                    </div>
                                    <template v-else> - </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <button
                    :disabled="!canSubmitVote()"
                    class="btn btn-success btn-default col-xs-12"
                    :aria-label="canSubmitVote() ? 'Update Vote & Comments' : 'Update Vote & Comments Disabled'"
                    @click="submit()"
                >
                    <template v-if="question.eGallery.hasSubmitted"> Update Vote & Comments </template>
                    <template v-else> Submit Vote & Comments </template>
                </button>
            </div>

            <template v-if="['ended'].includes(question.eGallery.status)">
                <p class="marginTop30">
                    <i>Please wait for teacher to release results</i>
                </p>
            </template>

            <template v-if="['released'].includes(question.eGallery.status)">
                <template v-for="(answer, answer_idx) in reorderedAnswers(question)">
                    <template v-if="answer.isSelected">
                        <div
                            v-if="answer.answer != null"
                            class="borderRadius8 marginTop20"
                            :class="{
                                'solid-warning':
                                    (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                    (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                                'border-black': answer.answer != null,
                                flex: !isMobileView,
                            }"
                        >
                            <div class="flex gap10 padding10">
                                <div class="dropdown">
                                    <button
                                        class="btn btn-outline-default margin0 flexInherit"
                                        :class="{
                                            'btn-default-border-warning':
                                                (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                                (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                                        }"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {{
                                            testObj.others.applicationType == 'team'
                                                ? getDisplayName(answer.team.name)
                                                : getDisplayName(answer.student.fullname, 'student')
                                        }}
                                        <i
                                            v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                            class="far fa-comment-dots marginLeft8"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <div class="dropdown-menu padding10">
                                        <div v-if="question.displayComments">
                                            <div class="flexOnly marginBottom5 align-items">
                                                <i class="fas fa-comments marginRight5 greyText" />
                                                <span class="lineHeight08 fontBold">Elaboration</span>
                                            </div>
                                            <div v-if="answer.comment">
                                                <div class="wordBreakWord whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</div>

                                                <button
                                                    class="btn btn-link marginLeft0 marginTop10 padding0"
                                                    @click="
                                                        testObj.others.applicationType == 'team'
                                                            ? openModelInfoModal(answer)
                                                            : openModelInfoModal(answer, 'student')
                                                    "
                                                >
                                                    View More
                                                </button>
                                            </div>
                                            <div v-else>-</div>
                                        </div>
                                        <div class="marginTop20">
                                            <div class="flexOnly marginBottom5 align-items">
                                                <i class="fas fa-paperclip marginRight5 greyText" />
                                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                                            </div>
                                            <div>
                                                <template v-if="answer.attachments && answer.attachments.length != 0">
                                                    <div v-for="(file, idx) in answer.attachments" class="flex maxHeight100px">
                                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                            file.filename
                                                        }}</a>
                                                    </div>

                                                    <button
                                                        class="btn btn-link marginLeft0 marginTop10 padding0"
                                                        @click="
                                                            testObj.others.applicationType == 'team'
                                                                ? openModelInfoModal(answer)
                                                                : openModelInfoModal(answer, 'student')
                                                        "
                                                    >
                                                        View More
                                                    </button>
                                                </template>
                                                <template v-else> - </template>
                                            </div>
                                        </div>
                                        <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                            <div class="flexOnly marginBottom5 align-items">
                                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                <span class="lineHeight08 fontBold">Team Members</span>
                                            </div>
                                            <div class="maxHeight100px">
                                                <ul v-for="(student, idx) in answer.team.members">
                                                    <li>
                                                        {{ getDisplayName(student.displayName, 'student', idx) }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModelInfoModal(answer)">View More</button>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess">
                                    Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                </div>
                            </div>

                            <div class="borderTop1pxGrey width100 borderBottom1pxGrey" :class="{ flexOnly: !isMobileView }">
                                <div v-if="question.displayAnswer" class="padding10" :class="{ minusWidth179px: !isMobileView }">
                                    <template v-if="answer.answer != null">
                                        <div class="wordBreakWord whiteSpacePreWrap" v-dompurify-html="truncate(answer.answer, 800)" />

                                        <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openQuestionModal(answer.answer)">
                                            View More
                                        </button>
                                    </template>
                                    <p v-else>
                                        <i>
                                            <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                            <template v-else> Student </template>
                                            Has Not Answered Yet.
                                        </i>
                                    </p>
                                </div>

                                <div v-if="question.displayVotes" class="textAlignCenter padding10" :class="{ 'width179px borderLeft1pxGrey': !isMobileView }">
                                    <label>
                                        <span class="visible-hidden">
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                {{ answer.team.name }}
                                            </template>
                                            <template v-else>
                                                {{ answer.student.fullname }}
                                            </template>
                                        </span>
                                        Vote Results
                                    </label>
                                    <p class="fs-28px">
                                        <b>{{ answer.votes.length }}</b>
                                    </p>
                                </div>

                                <div
                                    v-if="question.displayComments"
                                    class="padding10"
                                    :class="{ 'width400px borderLeft1pxGrey textAlignCenter': !isMobileView }"
                                >
                                    <label> Comment By </label>
                                    <div v-for="(comment, comment_idx) in answer.comments" class="marginTop10 textAlignLeft">
                                        <button
                                            class="btn btn-default margin0"
                                            @click="
                                                testObj.others.applicationType == 'team'
                                                    ? openCommentInfoModal(comment)
                                                    : openCommentInfoModal(comment, 'student')
                                            "
                                        >
                                            {{
                                                testObj.others.applicationType == 'team'
                                                    ? getDisplayName(comment.team.name)
                                                    : getDisplayName(comment.student.fullname, 'student')
                                            }}
                                            <i class="fa fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>

                                        <div v-if="comment.comment">
                                            <div class="wordBreakWord whiteSpacePreWrap marginTop10">{{ truncate(comment.comment, 100) }}</div>
                                            <button
                                                class="btn btn-link marginLeft0 marginTop10 padding0"
                                                @click="
                                                    testObj.others.applicationType == 'team'
                                                        ? openCommentInfoModal(comment)
                                                        : openCommentInfoModal(comment, 'student')
                                                "
                                            >
                                                View More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <template v-if="answer.answer != null">
                                <div v-if="question.displayVotes" class="padding10 width100">
                                    <label :for="'progressbar-' + answer_idx" class="fs-18px width100">
                                        {{ percentVoted(answer_idx) }}% of {{ testObj.others.applicationType == 'team' ? 'team/s' : 'student/s' }} voted
                                    </label>

                                    <progress
                                        :id="'progressbar-' + answer_idx"
                                        role="progressbar"
                                        :value="percentVoted(answer_idx)"
                                        aria-valuemin="0"
                                        :aria-valuenow="percentVoted(answer_idx)"
                                        aria-valuemax="100"
                                        max="100"
                                        class="progressbar height2rem width100"
                                    />
                                </div>

                                <div v-if="question.displayVotes" class="width100 padding10">
                                    <label class="control-label fs-20px">
                                        {{ answer.votes.length }}
                                        <template v-if="answer.votes.length <= 1"> Vote </template>
                                        <template v-else> Votes </template>
                                    </label>

                                    <div class="flex gap10">
                                        <div v-for="(model, model_idx) in answer.votes" class="dropdown" :key="model_idx">
                                            <button
                                                v-tooltip="testObj.others.applicationType == 'team' ? model.name : model.fullname"
                                                class="btn btn-outline-default margin0 flexInherit"
                                                :class="{
                                                    'btn-default-border-warning': testObj.myProgression.team.uuid == model.uuid || testObj.uuid == model.uuid,
                                                }"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                {{ testObj.others.applicationType == 'team' ? truncate(model.name) : truncate(model.fullname) }}
                                            </button>
                                            <div class="dropdown-menu padding10">
                                                <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ul class="marginBottom0">
                                                            <li v-for="(student, idx) in model.members" :key="idx">
                                                                {{ getDisplayName(student.displayName, 'student', idx) }}
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openTeamInfoModal(model)">
                                                        View More
                                                    </button>
                                                </div>
                                                <template v-else>
                                                    <label class="control-label"> Student Fullname </label>
                                                    <p>{{ model.fullname }}</p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
            </template>
        </div>

        <div
            :id="'eGalleryPreparingModal' + question.uuid"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryPreparingModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryPreparingModal' + question.uuid + '-title'" class="modal-title">It's time for e-Gallery Walk!</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <i class="fas fa-edit blue font30" aria-hidden="true" />
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            Your teacher is preparing
                            <strong>e-Gallery Walk</strong> now.
                        </div>
                        <div class="col-xs-12 textAlignCenter">Please get ready to participate!</div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryAttachmentsModal' + question.uuid"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryAttachmentsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryAttachmentsModal' + question.uuid + '-title'" class="modal-title">Attachments</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 col-md-6">
                            <div v-if="previewAttachments && previewAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <div v-for="(file, idx) in previewAttachments" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.</span>
                                            <span>
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                        <div class="col-xs-12 buttonGroup marginTop20">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryTeamInfoModal' + question.uuid"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryTeamInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryTeamInfoModal' + question.uuid + '-title'" class="modal-title">View {{ getDisplayName(previewTeam.name) }}</h2>
                    </div>
                    <div class="modal-body">
                        <div v-if="previewModel.type == 'team'" class="previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewTeam.members">
                                    <li>
                                        {{ getDisplayName(student.displayName, 'student', idx) }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryVotersInfoModal' + question.uuid"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryVotersInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryVotersInfoModal' + question.uuid + '-title'" class="modal-title">Voted By</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <template v-if="testObj.others.applicationType == 'individual'">
                                <ul v-for="(student, idx) in previewVoters">
                                    <li>
                                        {{ getDisplayName(student.displayName, 'student', idx) }}
                                    </li>
                                </ul>
                            </template>
                            <template v-if="testObj.others.applicationType == 'team'">
                                <ul v-for="(team, idx) in previewVoters">
                                    <li>
                                        {{ getDisplayName(team.name, 'team', idx) }}
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryQnsModal' + question.uuid"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryQnsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryQnsModal' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />View Answer
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 wordBreakWord whiteSpacePreWrap imgMax100" v-dompurify-html="questionModal" />
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryModelModal' + question.uuid"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryModelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryModelModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'">
                                View
                                {{ getDisplayName(previewModel.team.name) }}
                            </template>
                            <template v-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <label class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Elaboration</span>
                            </label>
                            <p v-if="previewModel.comment" class="wordBreakWord whiteSpacePreWrap">
                                {{ previewModel.comment }}
                            </p>
                            <p v-else>-</p>
                        </div>
                        <div class="marginTop20">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                            </div>
                            <div>
                                <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                    <div v-for="(file, idx) in previewModel.attachments" class="flex">
                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                    </div>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members">
                                    <li>
                                        {{ getDisplayName(student.displayName, 'student', idx) }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryWalkModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryWalkModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryWalkModal' + question.uuid + '-title'" class="modal-title">e-Gallery WALK</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div class="marginBottom20">The answer you have selected is from</div>
                            <button class="btn btn-default cursor-norm">
                                <template v-for="(model, model_idx) in question.answers">
                                    <template v-if="model.myVote == true">
                                        <template v-if="model.team">
                                            {{ getDisplayName(model.team.name) }}
                                        </template>
                                        <template v-if="model.student">
                                            {{ model.student.fullname }}
                                        </template>
                                    </template>
                                </template>
                            </button>
                            <div class="marginTop20">You will <span class="fontBold">NOT</span> be able to change your vote once you have submitted it</div>
                        </div>
                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button id="modal-logout-btn" class="btn btn-success" data-dismiss="modal" aria-label="Vote" @click.prevent="submit()">Vote</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryCommentModal' + question.uuid"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCommentModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryCommentModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'">
                                View
                                {{ getDisplayName(previewModel.team.name) }}
                            </template>
                            <template v-if="previewModel.type == 'student'">
                                View
                                {{ getDisplayName(previewModel.student.fullname, 'student') }}
                            </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Comment</span>
                            </div>
                            <p v-if="previewModel.comment" class="wordBreakWord whiteSpacePreWrap">
                                {{ previewModel.comment }}
                            </p>
                            <p v-else>-</p>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members">
                                    <li>
                                        {{ getDisplayName(student.displayName, 'student', idx) }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../../../../../../components/auth/auth';
import { testMixins } from './../../../../../../../../../mixins/test';
import { setResizeListeners } from './../../../../../../../../../mixins/auto-resize';
import { isMobile } from 'filestack-js';
export default {
    mixins: [testMixins],
    props: ['testObj', 'question'],

    data() {
        return {
            auth: new KrAuth(),
            previewTeam: {},
            previewVoters: {},
            previewAttachments: [],
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
            },
            questionModal: '',
            debouncedCommentAutosaves: {},
            lastEGalleryStatus: false,
        };
    },
    computed: {
        isAnonymous() {
            return this.testObj.enableAnonymityInEGalleryWalk;
        },
    },
    watch: {
        question: {
            handler: function () {
                var that = this;
                if (
                    [null, 'preparing', 'cancelled'].includes(this.question.eGallery.status) &&
                    this.testObj.eGalleryProgression.currentQuestionUuid == this.question.uuid
                ) {
                    $('#eGalleryPreparingModal' + this.question.uuid).modal('show');
                } else {
                    Vue.nextTick(function () {
                        $('#eGalleryPreparingModal' + that.question.uuid).modal('hide');
                    });
                }
            },
            deep: true,
        },
        'question.answers': {
            handler: function () {
                this.init();
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {
        setResizeListeners(this.$el, '.js-autoresize');
    },
    methods: {
        getDisplayName(name = '', type = 'team', idx = null) {
            /*if (this.isAnonymous) {
                return 'Anonymous ' + (type == 'team' ? 'Team' : 'Student') + (idx != null ? ' ' + (idx + 1) : '');
            } else return name;*/
            return name;
        },
        isMyAnswer(answer) {
            if (this.testObj.others.applicationType == 'team') {
                return this.testObj.myProgression.team.uuid == answer.team.uuid;
            } else {
                return this.auth.user().uuid == answer.student.userPlacementTestUuid;
            }
        },
        canSubmitVote() {
            if (!(this.testObj.others.applicationType == 'individual' || this.isTeamLeader)) {
                return false;
            }
            let hasTouched = false;
            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.question.answers[i]._isTouched) {
                    hasTouched = true;
                    break;
                }
            }
            if (!hasTouched) {
                return false;
            }
            let hasVoted = false;
            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.isMyAnswer(this.question.answers[i]) || !this.question.answers[i].uuid) {
                    continue;
                }
                if (this.question.answers[i].myVote) {
                    hasVoted = true;
                }
                if (this.question.eGalleryWalkSettings.teamsMustCommentForAllAnswers) {
                    if (!this.question.answers[i].myComment) {
                        return false;
                    }
                }
                if (this.question.eGalleryWalkSettings.teamsMustCommentForVotedAnswersOnly) {
                    if (this.question.answers[i].myVote) {
                        if (!this.question.answers[i].myComment) {
                            return false;
                        }
                    }
                }
                // if (
                //   !this.question.eGalleryWalkSettings.teamsMustCommentForAllAnswers &&
                //   !this.question.eGalleryWalkSettings
                //     .teamsMustCommentForVotedAnswersOnly
                // ) {
                //   if (this.question.answers[i].myVote) {
                //     return true;
                //   }
                // }
            }
            return hasVoted;
        },
        submitComment(answer) {
            var that = this;
            axios
                .post('student/tests/' + this.testObj.uuid + '/discussions/save-comment', { answerUuid: answer.uuid, answerComment: answer.myComment })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'comment has been updated',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Failed to update comment, please try again later',
                    });
                });
        },
        reorderedAnswers(q) {
            if (q.type != 'openended' || !['released'].includes(q.eGallery.status)) {
                return;
            }
            let question = _.cloneDeep(q);
            for (var i = 0; i < question.answers.length; i++) {
                question.answers[i]._name = question.answers[i].team ? question.answers[i].team.name : question.answers[i].student.fullname;
                let votes = question.answers[i].votes || [];
                question.answers[i]._voteCount = votes.length;
            }
            this.natSort(question.answers, '_name');

            for (var i = 0; i < question.answers.length; i++) {
                question.answers[i]._natSortIndex = i;
            }
            question.answers = _.orderBy(question.answers, ['_voteCount', '_natSortIndex'], ['desc', 'asc']);
            return question.answers;
        },
        openAttachmentsModal(attachments) {
            var that = this;
            this.previewAttachments = attachments;
            $('#eGalleryAttachmentsModal' + this.question.uuid).modal('show');
            $('#eGalleryAttachmentsModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewAttachments = [];
            });
        },
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#eGalleryTeamInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryTeamInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openVotersInfoModal(voters) {
            var that = this;
            this.previewVoters = voters;
            $('#eGalleryVotersInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryVotersInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewVoters = {};
            });
        },
        highlightAnswerKeywords(answer = '') {
            var res = answer.split(' ');
            var ret = '';
            for (var i = 0; i < res.length; i++) {
                if (
                    _.find(this.question.tags, function (o) {
                        return o.toLowerCase() === res[i];
                    })
                ) {
                    ret += '<span class="highlight">' + res[i] + '</span>&nbsp;';
                } else {
                    ret += res[i] + '&nbsp;';
                }
            }
            return ret;
            //return ret;
        },
        toggleBestAnswer(answer) {
            answer._isTouched = true;
            for (var i = 0; i < this.question.answers.length; i++) {
                let b = this.question.answers[i];
                if (answer.uuid != b.uuid) {
                    if (b.myVote) {
                        b.myVote = false;
                        b._isTouched = true;
                    }
                }
            }
        },
        isRankTaken(question, rank) {
            let answerWithRank = _.find(question.answers, function (o) {
                return o.myRank == rank;
            });
            if (answerWithRank) {
                return true;
            }
            return false;
        },
        init() {
            if (this.lastEGalleryStatus === false) {
                this.lastEGalleryStatus = this.question.eGallery.status;
            }
            if ([null, 'preparing'].includes(this.lastEGalleryStatus) && this.question.eGallery.status == 'ongoing') {
                this.clear();
            }
            this.lastEGalleryStatus = this.question.eGallery.status;
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.myComment) {
                    answer._editComment = true;
                } else {
                    //answer._editComment = false;
                }
                //create debounce function for comment
                if (!this.debouncedCommentAutosaves[answer.uuid] && answer.uuid) {
                    this.debouncedCommentAutosaves[answer.uuid] = _.debounce(this.submitComment, 1000);
                }
                if (!answer._isTouched) {
                    answer._isTouched = false;
                }
            }
            var that = this;

            if (['preparing'].includes(this.question.eGallery.status) && this.testObj.eGalleryProgression.currentQuestionUuid == this.question.uuid) {
                Vue.nextTick(function () {
                    $('#eGalleryPreparingModal' + that.question.uuid).modal('show');
                });
            } else {
                Vue.nextTick(function () {
                    $('#eGalleryPreparingModal' + that.question.uuid).modal('hide');
                });
            }
        },
        clear() {
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                answer.myRank = null;
                answer.myComment = null;
                answer.myVote = false;
            }
        },
        submit() {
            var that = this;
            let data = {};
            // data.votingMethod = this.question.eGallery.votingMethod;
            data.answers = [];
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid || !answer._isTouched) {
                    continue;
                }
                /*if (answer.myVote || answer.myComment) {
          data.answers.push({
            answerUuid: answer.uuid,
            vote: answer.myVote ? 1 : 0,
            comment: answer.myComment ? answer.myComment : "",
          });
        }*/
                data.answers.push({
                    answerUuid: answer.uuid,
                    vote: answer.myVote ? 1 : 0,
                    comment: answer.myComment ? answer.myComment : '',
                });
                answer._isTouched = false;
                /*if (answer.myComment) {
          axios.post(
            "student/tests/" + this.testObj.uuid + "/discussions/save-comment",
            { answerUuid: answer.uuid, answerComment: answer.myComment }
          );
        }*/

                /*if(data.votingMethod=='rank'){
        		data.answers.push({uuid:answer.uuid,value:answer.myRank,comment:answer.myComment?answer.myComment:''});
        	}
        if (data.votingMethod == "best") {
          data.answers.push({uuid: answer.uuid,value: answer.myVote ? 1 : 0,comment: answer.myComment ? answer.myComment : ""});
        }*/
            }
            if (data.answers.length != 0) {
                data.activityQuestionUuid = that.question.uuid;
                axios
                    .post('/student/tests/' + this.testObj.uuid + '/discussions/vote', data)
                    .then(function (response) {
                        that.question.eGallery.hasSubmitted = true;
                        that.$forceUpdate();
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Vote/Comments have been updated',
                        });
                    })
                    .catch(function (errors) {
                        that.$forceUpdate();
                        that.$notify({
                            group: 'form',
                            type: 'error',
                            title: 'Error',
                            text: 'Failed to update vote/comments, please try again later',
                        });
                    });
            }
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        openModelInfoModal(answer, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = answer.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = answer.student;
            }
            this.previewModel.comment = answer.comment;
            this.previewModel.attachments = answer.attachments ? answer.attachments : [];
            $('#eGalleryModelModal' + this.question.uuid).modal('show');

            $('#eGalleryModelModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        openQuestionModal(str) {
            this.questionModal = str;
            $('#eGalleryQnsModal' + this.question.uuid).modal('show');
        },
        openEgalleryWalkModal() {
            $('#eGalleryWalkModal' + this.question.uuid).modal('show');
        },
        openCommentInfoModal(comment, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = comment.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = comment.student;
            }
            this.previewModel.comment = comment.comment;
            $('#eGalleryCommentModal' + this.question.uuid).modal('show');

            $('#eGalleryCommentModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        percentVoted(answer_idx) {
            let present = this.question.answers.length;
            if (present == 0) {
                return null;
            }
            let votes = this.question.answers[answer_idx].votes || [];
            return Math.round((votes.length / present) * 100);
        },
    },
};
</script>

<style>
body.test .panel {
    height: 100%;
}

body.test .panel.heightAuto {
    height: auto;
}

.panel-body.heightAuto {
    height: auto;
}
</style>

<style lang="scss" scoped>
body.test .mini-panel .panel-heading {
    font-size: 14px;
}

body.test .mini-panel .panel-heading .link {
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

body.test .discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

body.test .mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams {
    white-space: normal;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 10px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}
.btnDiscussionTeams {
    margin-top: 10px;
}

.btnDiscussionTeams:first-child {
    margin-top: 0;
}

.width156px {
    width: 156px;
}

.width280px {
    width: 280px;
}

@media (min-width: 992px) {
    .commentTeams div {
        margin-top: 15px;
    }

    .commentTeams div:first-child {
        margin-top: 0;
    }
}
.discussionTableRows:last-child .marginBottom20 {
    margin-bottom: 0;
}

body.test .panel-body.padding5px15px {
    padding: 5px 15px;
}

.border-black {
    border: 1px solid #222;
}

.solid-warning {
    border: 1px solid #ac631c;
}

.minusWidth179px {
    width: calc(100% - 179px);
}

.width179px {
    width: 179px;
}

.big-bold-text {
    font-size: 28px;
    font-weight: bold;
}

.comment-comment div:not(:first-child) {
    margin-top: 10px;
}

.background-warning {
    background-color: rgba(230, 155, 35, 0.5);
}
</style>
