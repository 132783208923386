<template>
    <div v-if="component_done_loading" id="account-create" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">{{ $t('modules.accounts.create.headingLabel') }}</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-send-invitation-btn"
                                class="btn btn-primary"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Send Invitation"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true" />{{ $t('modules.accounts.create.sendButtonLabel') }}
                            </button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-send-invitation-btn"
                                v-tooltip="{ content: 'Send Invitation' }"
                                class="btn btn-primary"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Send Invitation"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <account-form :form="form" mode="create" :superusers="superusers" />
                        <div class="profileBtns buttonGroup marginTop30">
                            <button class="btn btn-primary" :disabled="!canSend ? true : undefined" aria-label="Send Invitation" @click="submit">
                                <i class="fa fa-paper-plane marginRight8" />{{ $t('modules.accounts.create.sendButtonLabel') }}
                            </button>

                            <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                                <button class="btn btn-default" @click="navigate">{{ $t('modules.accounts.create.cancelButtonLabel') }}</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>

        <div id="emailSampleModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="emailSampleModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="emailSampleModal-title" class="modal-title">Preview Email</h2>
                    </div>
                    <div class="modal-body">
                        <div class="emailer">
                            <div class="emailerHeader">
                                <img
                                    height="auto"
                                    src="https://cdn.intedashboard.com/emails/logo/white_intedashboard.png"
                                    width="270"
                                    alt="InteDashboard | TBL Makes Teams Work"
                                    role="presentation"
                                />
                            </div>
                            <div class="emailerContent">
                                <div>Activate your InteDashboard Account</div>
                                <div>
                                    <p>InteDashboard is a new online platform created by a team from</p>
                                    <p>Duke-NUS Medical School exclusively for team-based learning (“TBL”) educators.</p>
                                </div>
                                <div>
                                    <p>InteDashboard automates TBL administration and provides real-time data analytics.</p>
                                </div>
                                <button
                                    v-tooltip="{
                                        content: 'Unable to click. For preview purposes only.',
                                    }"
                                    class="btn btn-primary marginBottom20"
                                    aria-label="Activate My Account"
                                >
                                    Activate My Account&nbsp;<i class="fa fa-caret-right" aria-hidden="true" />
                                </button>

                                <p class="textAlignLeft fontSize14 fontGrey">
                                    <b>Please take note:</b>
                                </p>
                                <p class="textAlignLeft fontSize14 fontGrey flexOnly">
                                    <b>•</b>&nbsp;The 'Activate my account' button will direct you to&nbsp;<a
                                        v-tooltip="{
                                            content: 'Unable to click. For preview purposes only.',
                                        }"
                                        class="link"
                                    >
                                        InteDashboard's main login page.
                                    </a>
                                </p>
                                <p class="textAlignLeft fontSize14 fontGrey flexOnly">
                                    <b>•</b>&nbsp;Please ensure that you are logged out of any other InteDashboard sessions (including any professional
                                    development workshop sessions) prior to account activation.
                                </p>
                            </div>
                            <div class="emailerSubContent">
                                <div>
                                    <div class="col-xs-12 col-md-6">
                                        <p class="marginBottom10">Help Center</p>
                                        <a class="fontBold link" href="mailto:support@intedashboard.com">support@intedashboard.com</a>
                                    </div>
                                </div>
                                <div>
                                    <p class="marginTop10 fontBold">Social Media</p>
                                    <div class="socialMed marginTop10">
                                        <div class="col-xs-1">
                                            <a href="https://www.linkedin.com/company/cognalearn/" target="_blank">
                                                <img
                                                    height="12"
                                                    src="https://cdn.intedashboard.com/emails/facebook.png"
                                                    alt="Visit InteDashboard on Facebook"
                                                    role="presentation"
                                                />
                                            </a>
                                        </div>
                                        <div class="col-xs-1">
                                            <a href="https://twitter.com/InteDashboard" target="_blank">
                                                <img
                                                    width="12"
                                                    src="https://cdn.intedashboard.com/emails/twitter.png"
                                                    alt="Follow InteDashboard on Twitter"
                                                    role="presentation"
                                                />
                                            </a>
                                        </div>
                                        <div class="col-xs-1">
                                            <a href="https://www.linkedin.com/company/cognalearn/" target="_blank">
                                                <img
                                                    height="12"
                                                    src="https://cdn.intedashboard.com/emails/linkedin.png"
                                                    alt="Connect with InteDashboard on LinkedIn"
                                                    role="presentation"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="emailerFooter flexSpaceBetween">
                                <div>
                                    Copyright © CognaLearn Pte Ltd
                                    {{ new Date().getFullYear() }}
                                </div>
                                <div class="fontBold flexOnly">
                                    <div>
                                        <a href="">Terms & Condition</a>
                                    </div>
                                    <div class="marginLeft20">
                                        <a href="">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    avatar: '',
                    organisationName: '',
                    //accountName:'',
                    type: 'default!:Free',
                    paymentMethod: 'default!:Institution',
                    firstname: '',
                    lastname: '',
                    email: '',
                    identity: '',
                    startDate: '',
                    expiryDate: '',
                    //paymentPlans:'type!:array',
                    jobDescription: '',
                    influencerLevel: 'default!:Nano-influencers',
                    segment: 'default!:Higher Ed',
                    fieldOfDiscipline: 'sendType!:readonly|default!:Business',
                    otherFieldOfDiscipline: '',
                    country: '',
                    stateOrCity: '',
                    timezone: '',
                    accountOwnerUuid: '',
                    version: 'default!:full',
                    lms: '',
                },
                props: {},
                urls: {
                    post: 'accounts',
                },
            }),
            superusers: [],
        };
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [
                { name: 'Home' },

                { name: that.$t('modules.accounts.listing.breadcrumbLabel'), link: 'accounts.index' },
                { name: that.$t('modules.accounts.create.breadcrumbLabel') },
            ],
        });
        this.form.model.startDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        this.form.model.expiryDate = moment.utc(this.form.model.startDate, 'YYYY-MM-DDThh:mm:ssZ').add(1, 'years').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

        axios.get('/superusers').then(function (response) {
            that.superusers = response.data;
            that.component_done_loading = true;
        });
    },
    components: {
        'account-form': require(`./form-partials/partial.account.vue`).default,
    },
    methods: {
        openEmailSampleModal() {
            $('#emailSampleModal').modal('show');
        },
        submit() {
            var that = this;
            this.form.model.identity = this.form.model.email;
            //this.form.model.paymentPlans.splice(this.form.model.paymentPlans.length-1,1);
            /*if((this.form.model.type=='Paid'||this.form.model.type=='Paid Trial')&&this.form.model.paymentMethod=='Student-Paid'){
					if(['',null,0].includes(this.form.model.paymentPlans[1].price)){
						this.form.model.paymentPlans[1].price = 0;
					}
				}*/
            this.form.post().then(function (response) {
                //Events.fire('addPaymentPlan');
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: response.data.data.uuid },
                    });
                }
            });
        },
    },
    computed: {
        canSend() {
            if (this.form.model.organisationName && this.form.model.type && this.form.model.firstname && this.form.model.lastname && this.form.model.email) {
                if ((this.form.model.type == 'Paid' || this.form.model.type == 'Paid Trial') && this.form.model.paymentMethod == 'Student-Paid') {
                    /*if(!(this.form.model.paymentPlans[0].name!=''&&this.form.model.paymentPlans[0].licensePeriod!=''&&this.form.model.paymentPlans[0].price!='')){
                        	return false;
                    	}*/
                    /*if(!this.form.model.startDate||!this.form.model.expiryDate||!this.form.model.paymentMethod){
	    					return false;
	    				}*/
                }
                if ((this.form.model.type == 'Paid' || this.form.model.type == 'Paid Trial') && this.form.model.paymentMethod == 'Institution') {
                    if (!this.form.model.startDate || !this.form.model.expiryDate || !this.form.model.paymentMethod) {
                        return false;
                    }
                }
                if (this.form.model.type == 'Demo') {
                    if (!this.form.model.startDate || !this.form.model.expiryDate) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        },
    },
};
</script>
<style scoped>
.modal {
    text-align: center;
    padding: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.emailerHeader {
    padding: 30px;
    text-align: center;
    background-color: #495060;
}

.emailerContent {
    padding: 30px;
    text-align: center;
}

.emailerContent p {
    margin: 0;
}

.emailerContent div {
    margin: 20px;
}

.emailerContent div:first-child {
    font-weight: bold;
    color: #3c7858;
    font-size: 20px;
    margin: 0;
}

.emailerContent button {
    padding: 10px;
    width: 270px;
}

.emailerContent .btn {
    margin-left: 0;
}

.emailerSubContent {
    padding: 30px;
    background-color: #f8f8f8;
    text-align: center;
    font-size: 12px;
}

.emailerSubContent > div:first-child p {
    line-height: 1;
    font-weight: bold;
}

.lineBreak {
    background: #ebebeb;
    height: 2px;
    margin: 20px 0;
}

.socialMed {
    display: flex;
    justify-content: center;
}

.emailerFooter {
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #495060;
    padding: 10px;
}

.emailerFooter a,
.emailerFooter a:hover,
.emailerFooter a:focus,
.emailerFooter a:active {
    color: #fff;
}

@media (min-width: 992px) {
    .emailerSubContent > div:first-child,
    .emailerSubContent span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .emailerContent button {
        width: 270px;
    }
}

@media (max-width: 991px) {
    .emailerSubContent span {
        margin: 10px 0;
    }
}
</style>
