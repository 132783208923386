
.timer_Div {
    .time .number-div {
        background: #fff;
        padding: 6px;
        border-radius: 3px;
        font-size: 28px;
        font-weight: bold;
        line-height: 0.8;
    }

    .time .number-div:last-child {
        margin-left: 5px;
    }

    .colon {
        color: #fff;
        padding: 0 10px;
    }

    .timeText {
        font-size: 1rem;
        margin-bottom: 5px;
    }
}

.cr-dropdowns {
    position: relative;
    display: inline-flex;
    align-items: center;

    .dropdown-menu {
        padding: 0;
        background: #495060;
        border: none;
        left: inherit;
        right: 0;
        text-transform: none;

        &.padding10 {
            padding: 10px;
        }
    }

    .dropdown-submenu {
        position: relative;

        & > .dropdown-menu {
            top: 0;
            right: 100%;
            border-radius: 6px;
            min-width: 172px;
        }

        &:hover {
            & > .dropdown-menu {
                display: block;
            }

            & > a:after {
                border-left-color: #fff;
            }
        }

        & > a {
            display: flex;
            align-items: center;
        }

        & > div > label {
            min-width: 180px;
            justify-content: space-between;
            padding-right: 10px;
        }
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
        -webkit-border-radius: 6px 0 6px 6px;
        -moz-border-radius: 6px 0 6px 6px;
        border-radius: 6px 0 6px 6px;
    }

    .checkbox {
        margin: inherit;

        &:focus-within {
            color: #222;
            background: #dabd6e;
            outline: 4px solid #1941a6;
            outline-offset: 3px;
            box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
            border-radius: 8px;
        }

        input[type='checkbox'] {
            margin: 0;
            position: inherit;

            & ~ label {
                i {
                    width: 14px;
                    text-align: center;
                }

                div:first-child {
                    width: calc(100% - 16px);
                }

                div:last-child {
                    width: 16px;
                }
            }

            &:focus-visible {
                outline: none;
            }
        }

        label {
            min-height: inherit;
            padding: 10px;
            margin-left: 0;
            white-space: nowrap;
            font-size: 1rem;
            line-height: 1.4;
        }
    }

    li {
        color: #fff;

        div:hover {
            background: #dabd6e;
            color: #222;
        }

        a {
            padding: 10px;
            font-size: 1rem;
            color: inherit;

            &:focus {
                background: none;
            }

            &:hover,
            &:focus-visible {
                background: #dabd6e;
                color: #222;
            }
        }

        button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: inherit;
            font-weight: normal;
            border-radius: 0;

            &:hover,
            &:focus-visible {
                background: #dabd6e;
                color: #222;
            }
        }
    }
}

@media (max-width: 992px) {
    .cr-dropdowns {
        &.marginLeft20 {
            margin-left: 0;
        }

        .dropdown-menu {
            left: 0;
            width: fit-content;
        }
    }
}

.night-mode {
    .darkGreen {
        .timeText {
            color: #45b86f;
        }
    }
}
