
.attendanceView {
    width: 100%;
}

#attendance-block .x_content {
    padding: 0;
    padding-top: 20px;
    height: calc(100% - 67px);
}

.namesScroll {
    min-height: 200px;
    height: 100%;
    overflow: auto;
}

@media (max-width: 992px) {
    .namesScroll {
        overflow: visible;
    }
}

.namesScroll div.active .fa-user-check {
    background-image: linear-gradient(129deg, #188e9b, #6dd7c7 98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.namesScroll {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background: #d8d8d8;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #989b9c;
        border-radius: 2px;
    }
}

.profile_image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.badge_profile {
    background: #2a3f54;
    color: white;
    width: 30px;
    height: 30px;
    line-height: 1.2;
    font-size: 18px;
}

.attendanceView .actual-image {
    width: 100%;
    height: 100%;
    background: #222;

    background-position: center;
    background-size: cover;
}

.attendanceView .fa-circle {
    position: absolute;
    right: 0;
    bottom: 0;
}

.attendanceView .fa-circle.online {
    color: #3c7858;
}

.attendanceView .fa-circle.offline {
    color: #ca3333;
}

.dashboardCols #attendance-block .x_panel .container {
    height: 100%;
}

.student-modal-table .table > tbody > tr > td {
    vertical-align: middle;
}

.student-modal-table .form-dropdown {
    width: 100%;
}

.student-modal-table .form-dropdown:after {
    top: 10px;
}

.teamContainer {
    &:first-of-type {
        margin-top: 10px;
    }

    &:not(:first-of-type) {
        margin-top: 20px;
    }

    .studentName {
        &:first-of-type {
            margin-top: 10px;
        }

        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }
}
