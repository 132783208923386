
.enrollmentSetting {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }
}

.w-auto {
    width: auto;
}

.dropdown.open #viewDropdownMenuButton {
    margin-bottom: 0;
}

.setting .dropdown-menu {
    top: inherit;
}
.dropup .dropdown-menu {
    margin-bottom: 10px;
}
