
.dashboardCols .x_panel .container {
    height: 312px;
    display: flex;
}

.dashboardCols .x_panel {
    margin-bottom: 0;
}

.dashboardCols .x_header {
    flex-shrink: 0;
}

.width396px {
    width: 396px;
}

.width284px {
    width: 284px;
}

.autoWidthStatus {
    width: calc(100% - 40px - 396px - 284px);
}

@media (min-width: 1200px) {
    .lg-marginTop20 {
        margin-top: 20px;
    }
}

@media (min-width: 992px) {
    .dashboardCols {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .dashboardCols .column {
        flex: 1;
        margin-right: 20px;
    }

    .dashboardCols .column:last-child {
        margin-right: 0;
    }

    .dashboardCols > div {
        margin-left: 20px;
    }

    .dashboardCols {
        & > div {
            &:first-child,
            &:only-child {
                margin-left: 0;
            }
        }
    }

    .dashboardCols {
        .col-md-6 {
            &:first-child {
                padding-right: 10px;
            }

            &:last-child {
                margin-left: 0;
                padding-left: 10px;
            }
        }
    }
}
