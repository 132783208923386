
.nav.side-menu {
    background: #495060;
    overflow-y: auto;

    & > li a {
        padding: 12px 8px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 6px solid transparent;
        color: #ffffff;
        background: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-shadow: none;

        &:hover {
            background: #ffffff;
            color: #0071be;
            cursor: pointer;
            border-left: 6px solid #0071be30;
        }

        &.active {
            margin-left: 0;
            border-left: 6px solid #0071be;
            border-right: none;
            background: #ffffff;
            color: #0071be;
        }
    }
}

.menu-logo-wrapper {
    padding: 10px;
}

@media (max-width: 991px) {
    .menu-logo-wrapper {
        padding: 10px 25px 10px;
        color: #fff;
        font-size: 24px;
        line-height: 1;
        text-align: center;
    }
}

.help-center {
    position: fixed;
    bottom: 0;
    width: 110px;
}

.isStudent {
    .left_col {
        background: #0071be;
        height: 100%;
    }

    .nav {
        &.side-menu {
            background: none;
        }
    }
}
