
.test_topBarContent {
    padding: 0 20px;
    flex: 1;
}

.test_topBar {
    position: fixed;
    z-index: 200;
    width: 100%;
    top: 0;
    background-color: #23356d;
}

.loadingText {
    font-style: italic;
    text-align: center;
    color: #fff;
}

.test_topBarContent .col-xs-10 {
    text-align: center;
}

.test_title {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
    text-align: left;
}

@media (min-width: 992px) {
    .test_logo {
        padding: 15px 35px;
    }

    .loadingText {
        font-size: 16px;
    }

    .timer_Div {
        margin-bottom: 15px;
    }
    .viewInstructionsEnded {
        margin-top: -25px;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .test_logo {
        padding: 15px;
    }

    .loadingText {
        font-size: 12px;
    }

    .timer_Div {
        margin-bottom: 10px;
    }

    .viewInstructions {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .test_topBarContent {
        padding: 15px;
    }
}
