
.studentList {
    margin-bottom: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 1;

    &:nth-last-of-type(3) {
        margin-bottom: 0;
    }

    &:nth-child(2) {
        margin-top: 30px;
    }

    .header {
        padding: 15px;
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid #d8d8d8;

        &.textAlignLeft {
            text-align: left;
        }

        .random {
            text-align: left;
        }
    }

    .content {
        padding: 20px 25px;

        &.paddingBottom0 {
            padding-bottom: 0;
        }
    }

    .hidden-md.hidden-lg {
        .team {
            border: 0;

            .header {
                border-bottom: 0;
            }
        }
    }
}

.unassignedBtns {
    margin-top: 10px;
}

/* New Team Button */

.sectionDiv {
    margin-top: 30px;
}

.team {
    height: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.team .header {
    padding-left: 10px;
    background-color: #fbfbfb;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    line-height: 1;
    background-image: none;
}

.team .content {
    padding: 10px;
}

.studentList .sectionDiv .btn,
.studentList .sectionDiv .content div {
    margin-left: 0;
    margin-top: 10px;
}

.studentList .sectionDiv .btn:first-child,
.studentList .sectionDiv .content div:first-child,
.studentList .sectionDiv .btn div {
    margin-top: 0;
}

.studentList .sectionDiv .btn .dots {
    color: #717171;
    line-height: 0.5;
}

.studentList .sectionDiv .primaryHover:hover .dots {
    color: #fff;
}

.studentList .table {
    margin-bottom: 0;
}

.studentList table {
    border: none;
    border-radius: 0;
    border-top: 1px solid #d8d8d8;
}

.studentList thead tr:first-child,
.studentList thead tr:only-child {
    background-color: #fbfbfb;
    text-align: center;
    background-image: none;
}

.studentList {
    thead {
        tr {
            &:only-child {
                th {
                    font-size: 1em;
                    padding: 15px 20px;
                    &.paddingTop6 {
                        padding-top: 6px;
                    }
                    &.paddingBottom6 {
                        padding-bottom: 6px;
                    }
                    &.fontSize16 {
                        font-size: 0.88rem;
                    }
                }
            }

            &.table-header-row {
                th {
                    font-size: 0.88rem;
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }
        }
    }

    .table {
        tbody {
            tr {
                td {
                    padding: 10px;
                }
            }
        }
    }
}

.studentList thead tr:last-child {
    background-color: #f6f8fa;

    th {
        padding: 10px;
    }
}

.studentList .hidden-md.hidden-lg div .btn {
    padding: 0 15px 15px;
}

.studentList .hidden-md.hidden-lg div:first-child .btn {
    padding-top: 15px;
}

.studentList .hidden-md.hidden-lg .content div .btn {
    padding: 0 0 15px;
}

.studentList .hidden-md.hidden-lg .content div:last-child .btn {
    padding: 0;
}

.studentList .hidden-md.hidden-lg div .btn .fa-chevron-right {
    color: #0071be;
}

@media (min-width: 992px) {
    .sectionDiv:nth-child(-n + 4),
    .unassignedBtns:nth-child(-n + 4) {
        margin-top: 0;
    }

    .studentList .sectionDiv,
    .studentList .unassignedBtns,
    .studentList .tableSectionDiv {
        width: 23.5%;
        margin-right: 2%;
    }

    .studentList .sectionDiv:nth-child(4n),
    .studentList .unassignedBtns:nth-child(4n) {
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .studentList .sectionDiv,
    .studentList .unassignedBtns {
        width: 100%;
    }

    .sectionDiv:first-child {
        margin-top: 0;
    }
}

@media screen and (max-width: 767px) {
    .studentList .table-responsive {
        margin-bottom: 0;
    }
}

.manageStudents {
    .tabButtons {
        display: flex;

        button {
            border: 1px solid #d8d8d8;
            background-color: #f6f8fa;
            color: #717171;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;

            &.active {
                border: 1px solid #0071be;
                background-color: #ffffff;
                color: #222222;
            }

            &:first-child {
                border-radius: 3px 0 0 3px;
            }

            &:last-child {
                border-radius: 0 3px 3px 0;
            }
        }
    }
}

@media (min-width: 992px) {
    .manageStudents {
        .tabButtons {
            button {
                width: 125px;
            }
        }
    }
}

@media (max-width: 991px) {
    .manageStudents {
        .tabButtons {
            margin-top: 20px;
        }
    }

    .manageStudents {
        .tabButtons {
            button {
                width: 50%;
            }
        }
    }
}

.break-word {
    word-wrap: break-word;
}

.studentList thead tr.teamNameHeader > th {
    background: #fbfbfb;
    border-bottom: 1px solid #ddd;
}

.width310px {
    width: 310px;
}

.width178px {
    width: 178px;
}

#updateSortingModal table {
    height: 250px;
    overflow: auto;
    display: block;
}

#updateSortingModal thead,
#updateSortingModal tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

#updateSortingModal thead {
    position: sticky;
    top: 0;
    border-radius: 3px;
    background-color: #f6f8fa;
    -webkit-box-shadow: 0 0 2px 0 rgb(202 202 202 / 50%);
    box-shadow: 0 0 2px 0 rgb(202 202 202 / 50%);
    border-bottom: solid 1px #d8d8d8;
    background-image: none;
}

#updateSortingModal thead th {
    text-align: center;
}

#updateSortingModal .table > thead > tr > th,
#updateSortingModal .table > thead > tr > td,
#updateSortingModal .table > tbody > tr > th,
#updateSortingModal .table > tbody > tr > td,
#updateSortingModal .table > tfoot > tr > th,
#updateSortingModal .table > tfoot > tr > td {
    padding: 15px 20px;
    line-height: 1.2;
}

#updateSortingModal table th:last-child {
    border-right: solid 1px #d8d8d8;
}

#updateSortingModal table {
    margin-top: 10px;
}

#updateSortingModal thead th:first-child,
#updateSortingModal tbody td:first-child {
    width: 60px;
}

#updateSortingModal .table > tbody > tr:first-child > th,
#updateSortingModal .table > tbody > tr:first-child > td {
    border-top: 0;
}

.studentList {
    .col-xs-11 {
        min-height: 15px;
        line-height: 1;
    }
}

.courseViewStudentsButton--notDetails {
    &-edit {
        & > div {
            &:first-child {
                width: 31px;
            }

            &:last-child {
                width: calc(100% - 78px);
            }
        }
    }

    &-view {
        & > div {
            &:last-child {
                width: calc(100% - 52px);
            }
        }
    }
}
