<template>
    <div v-if="component_done_loading">
        <div v-if="pageType == 'form'" class="marginBottom20">
            <button class="btn btn-success" aria-label="Go to Optional Settings" @click.prevent="submitPointsSettings">
                Go to Optional Settings<i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit
            </button>
        </div>

        <div v-if="pageType != 'confirmation'" class="pageTitle">
            <h1>Point Settings</h1>
            <p class="col-md-6">
                This page allows you to assign points to each question in this activity, with the option to increase or decrease the points per question as
                needed.
            </p>
        </div>

        <kr-panel
            v-if="activityObj.type == 'irat' || (activityObj.type == 'iratntrat' && pageType == 'form')"
            :with-footer="false"
            :options="{ canToggle: false }"
        >
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>IRAT Point Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="iratMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitPointsSettings('irat')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="iratMode = 'view'">Cancel</button>
                    </template>
                    <template v-else="iratMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                iratMode = 'edit';
                                tratMode = 'view';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && iratMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || iratMode == 'edit'" class="marginBottom30 col-xs-4">
                        <div class="merge2ElementsFieldButton">
                            <input
                                v-model="iratSamePoint"
                                type="number"
                                class="form-control maxWidth82"
                                aria-label="Give a point to be distribute for all question"
                                @change="iratSamePoint = parseFloat(iratSamePoint).toFixed(2)"
                            />
                            <button
                                class="btn btn-default"
                                :class="{
                                    disabled: iratSamePoint == null || iratSamePoint <= 0,
                                }"
                                type="button"
                                :aria-label="
                                    iratSamePoint == null || iratSamePoint <= 0
                                        ? 'Set all questions with the same point disabled'
                                        : 'Set all questions with the same point'
                                "
                                @click.prevent="setSamePoint('irat')"
                            >
                                Set all questions with the same point
                            </button>
                        </div>
                    </div>
                    <div class="iratPoints table-responsive hidden-xs hidden-sm">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="numColumn">No.</th>
                                    <th class="width370">Question</th>
                                    <th>Point Given</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(question, idx) in form.model.data">
                                    <td>
                                        <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                            {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>
                                    </td>
                                    <td>
                                        <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" class="wordBreakWord" />
                                    </td>
                                    <td>
                                        <kr-field
                                            v-if="pageType == 'form' || iratMode == 'edit'"
                                            :form="form"
                                            :name="'data.' + idx + '.point'"
                                            :options="{
                                                type: 'number',
                                                hasLabel: false,
                                                min: 0.01,
                                                showError: false,
                                                decimal: 2,
                                            }"
                                            @changed="recomputeIRATPoints()"
                                        />
                                        <template v-else>
                                            {{ question.point }}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 marginTop30 flexRight">
                            <div class="col-xs-12 col-sm-4 col-md-2">
                                <div class="control-label">Total Points For IRAT</div>
                                <div class="form-group">
                                    <input id="irat-total-points" class="form-control" :value="total_irat_points" disabled aria-label="Total Points for IRAT" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="(question, idx) in form.model.data" class="hidden-md hidden-lg pointSettingDiv">
                        <div class="header flexSpaceBetween align-items">
                            <div class="fontBold">QUESTION NO.</div>
                            <div>
                                <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                    {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="body">
                            <div v-dompurify-html="question.question.question" />
                            <!-- <div class="marginTop10 fontBold">
								More&nbsp;<i class="fas fa-caret-down"></i>
							</div>	 -->
                        </div>
                        <div class="footer">
                            <div class="flexSpaceBetween align-items">
                                <div class="fontBold">Point Given</div>
                                <div>
                                    <kr-field
                                        v-if="pageType == 'form' || iratMode == 'edit'"
                                        :form="form"
                                        :name="'data.' + idx + '.point'"
                                        :options="{
                                            type: 'number',
                                            hasLabel: false,
                                            min: 0.01,
                                            showError: false,
                                            decimal: 2,
                                        }"
                                        @changed="recomputeIRATPoints()"
                                    />
                                    <template v-else>
                                        {{ question.point }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>
        <kr-panel
            v-if="activityObj.type == 'trat' || (activityObj.type == 'iratntrat' && pageType == 'form')"
            :with-footer="false"
            :options="{ canToggle: false }"
        >
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>TRAT Point Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="tratMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitPointsSettings('trat')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="tratMode = 'view'">Cancel</button>
                    </template>
                    <template v-else="tratMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                iratMode = 'view';
                                tratMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && tratMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div v-if="pageType == 'form' || tratMode == 'edit'" class="marginBottom30">
                        <label class="checkboxField">
                            <input
                                v-model="tratSameScore"
                                type="checkbox"
                                class="checkbox-field"
                                aria-label="Set all questions with the same attempt points (only questions with at least 4 options)"
                                @change="processTratSameScore"
                            />
                            <span style="width: calc(100% - 30px)">
                                Set all questions with the same attempt points (only questions with at least 4 options)
                            </span>
                        </label>
                    </div>
                    <div v-if="tratSameScore && (pageType == 'form' || tratMode == 'edit')" class="iratPoints table-responsive marginBottom30">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Attempt Number</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(score, idx) in tratOptionScores">
                                    <td>Attempt Number {{ idx + 1 }}</td>
                                    <td>
                                        <input
                                            v-model="tratOptionScores[idx]"
                                            type="number"
                                            class="form-control width50px"
                                            :aria-label="'Attempt Number ' + (idx + 1)"
                                            @change="processTratSameScore"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="fontBold marginBottom10">TRAT Attempt Points</div>
                    <div class="iratPoints table-responsive hidden-xs hidden-sm">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="numColumn">No.</th>
                                    <th class="width388">Question</th>
                                    <th>No. Of Attempts</th>
                                    <template v-for="n in (1, max_options_count)">
                                        <th>
                                            {{ n }}<template v-if="n % 10 == 1">st </template>
                                            <template v-else-if="n % 10 == 2">nd </template>
                                            <template v-else-if="n % 10 == 3">rd </template>
                                            <template v-else>th </template>
                                            Attempt
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(question, idx) in form.model.data">
                                    <td>
                                        <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                            {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>
                                    </td>
                                    <td>
                                        <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" class="wordBreakWord" />
                                    </td>
                                    <td>
                                        {{ question.question.options.length }}
                                    </td>
                                    <template v-for="(point, point_idx) in question.points">
                                        <td>
                                            <kr-field
                                                v-if="pageType == 'form' || tratMode == 'edit'"
                                                :form="form"
                                                :name="'data.' + idx + '.points.' + point_idx"
                                                :options="{
                                                    type: 'number',
                                                    hasLabel: false,
                                                    min:
                                                        point_idx + 1 < question.points.length
                                                            ? Math.max(point_idx == 0 ? 1 : 0, question.points[point_idx + 1])
                                                            : point_idx == 0
                                                            ? 1
                                                            : 0,
                                                    max: point_idx != 0 ? question.points[point_idx - 1] : 99999999999,
                                                    showError: false,
                                                }"
                                                @changed="
                                                    form.model.data.splice();
                                                    recomputeTRATPoints();
                                                "
                                            />
                                            <template v-else>
                                                {{ point }}
                                            </template>
                                        </td>
                                    </template>
                                    <template v-for="n in (1, max_options_count - question.question.options.length)">
                                        <td />
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 marginTop30 flexRight">
                            <div class="col-xs-12 col-sm-4 col-md-2">
                                <div class="control-label">Total Points For TRAT</div>
                                <div class="form-group">
                                    <input id="trat-total-points" class="form-control" :value="total_trat_points" disabled aria-label="Total Points for TRAT" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="(question, idx) in form.model.data" class="hidden-md hidden-lg pointSettingDiv">
                        <div class="header flexSpaceBetween align-items">
                            <div class="fontBold">QUESTION NO.</div>
                            <div>
                                <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                    {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="body">
                            <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" />
                            <!-- <div class="marginTop10 fontBold">
								More&nbsp;<i class="fas fa-caret-down"></i>
							</div>	 -->
                        </div>
                        <div class="footer">
                            <div class="marginBottom20 fontBold">
                                No. Of Attempts:
                                {{ question.question.options.length }}
                            </div>
                            <template v-for="(point, point_idx) in question.points">
                                <div class="attemptsDiv flexSpaceBetween align-items">
                                    <div class="fontBold">
                                        {{ point_idx + 1 }}<template v-if="(point_idx + 1) % 10 == 1"> ST </template>
                                        <template v-else-if="(point_idx + 1) % 10 == 2"> ND </template>
                                        <template v-else-if="(point_idx + 1) % 10 == 3"> RD </template>
                                        <template v-else> TH </template>
                                        Attempt Point
                                    </div>
                                    <div>
                                        <kr-field
                                            v-if="pageType == 'form' || tratMode == 'edit'"
                                            :form="form"
                                            :name="'data.' + idx + '.points.' + point_idx"
                                            :options="{
                                                type: 'number',
                                                hasLabel: false,
                                                min:
                                                    point_idx + 1 < question.points.length
                                                        ? Math.max(point_idx == 0 ? 1 : 0, question.points[point_idx + 1])
                                                        : 1,
                                                max: point_idx != 0 ? question.points[point_idx - 1] : 99999999999,
                                                showError: false,
                                            }"
                                            @changed="
                                                form.model.data.splice();
                                                recomputeTRATPoints();
                                            "
                                        />
                                        <template v-else>
                                            {{ point }}
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>

        <kr-panel v-if="activityObj.type == 'iratntrat' && pageType == 'confirmation'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>IRAT & TRAT Point Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="iratandtratMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitPointsSettings('iratntrat')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="iratandtratMode = 'view'">Cancel</button>
                    </template>
                    <template v-else="iratandtratMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                iratandtratMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && iratandtratMode == 'view'"> Not Set </template>
                <template v-else>
                    <h3>IRAT Settings</h3>
                    <div v-if="pageType == 'form' || iratandtratMode == 'edit'" class="marginBottom30 col-xs-4">
                        <div class="merge2ElementsFieldButton">
                            <input
                                v-model="iratSamePoint"
                                type="number"
                                class="form-control maxWidth82"
                                min="1"
                                aria-label="Give a point to be distribute for all question"
                            />

                            <button
                                class="btn btn-default"
                                :class="{
                                    disabled: iratSamePoint == null || iratSamePoint <= 0,
                                }"
                                type="button"
                                :aria-label="
                                    iratSamePoint == null || iratSamePoint <= 0
                                        ? 'Set all questions with the same point disabled'
                                        : 'Set all questions with the same point'
                                "
                                @click.prevent="setSamePoint('irat')"
                            >
                                Set all questions with the same point
                            </button>
                        </div>
                    </div>
                    <div class="iratPoints table-responsive hidden-xs hidden-sm">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="numColumn">No.</th>
                                    <th class="width600px">Question</th>
                                    <th>Point Given</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(question, idx) in form.model.data">
                                    <td>
                                        <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                            {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>
                                    </td>
                                    <td>
                                        <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" class="wordBreakWord" />
                                    </td>
                                    <td>
                                        <kr-field
                                            v-if="iratandtratMode == 'edit'"
                                            :form="form"
                                            :name="'data.' + idx + '.point'"
                                            :options="{
                                                type: 'number',
                                                hasLabel: false,
                                                min: 0,
                                                showError: false,
                                            }"
                                            @changed="recomputeIRATPoints()"
                                        />
                                        <template v-else>
                                            {{ question.point }}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 marginTop30 flexRight">
                            <div class="col-xs-12 col-sm-4 col-md-2">
                                <div class="control-label">Total Points For IRAT</div>
                                <div class="form-group">
                                    <input
                                        id="irat-trat-irat-total-points"
                                        class="form-control"
                                        :value="total_irat_points"
                                        disabled
                                        aria-label="Total Points for IRAT"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="(question, idx) in form.model.data" class="hidden-md hidden-lg pointSettingDiv">
                        <div class="header flexSpaceBetween align-items">
                            <div class="fontBold">Queston No.</div>
                            <div>
                                <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                    {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="body">
                            <div v-dompurify-html="question.question.question" />
                            <!-- <div class="marginTop10 fontBold">
								More&nbsp;<i class="fas fa-caret-down"></i>
							</div>	 -->
                        </div>
                        <div class="footer">
                            <div class="flexSpaceBetween align-items">
                                <div class="fontBold">POINT GIVEN</div>
                                <div>
                                    <kr-field
                                        v-if="iratandtratMode == 'edit'"
                                        :form="form"
                                        :name="'data.' + idx + '.point'"
                                        :options="{
                                            type: 'number',
                                            hasLabel: false,
                                            min: 0,
                                            showError: false,
                                        }"
                                        @changed="recomputeIRATPoints()"
                                    />
                                    <template v-else>
                                        {{ question.point }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="marginTop30">
                        <h3>TRAT Settings</h3>
                    </div>
                    <div v-if="pageType == 'form' || tratMode == 'edit'" class="marginBottom30">
                        <label class="checkboxField">
                            <input
                                v-model="tratSameScore"
                                type="checkbox"
                                class="checkbox-field"
                                aria-label="Set all questions with the same attempt points (Only questions with at least 4 options)"
                                @change="processTratSameScore"
                            />
                            <span style="width: calc(100% - 30px)">
                                Set all questions with the same attempt points (only questions with at least 4 options)
                            </span>
                        </label>
                    </div>
                    <div v-if="tratSameScore && (pageType == 'form' || tratMode == 'edit')" class="iratPoints table-responsive marginBottom30">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Attempt Number</th>
                                    <th class="width100px">Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(score, idx) in tratOptionScores">
                                    <td>Attempt Number {{ idx + 1 }}</td>
                                    <td>
                                        <input
                                            v-model="tratOptionScores[idx]"
                                            type="number"
                                            class="form-control width50px"
                                            :aria-label="'Attempt Number ' + (idx + 1)"
                                            @change="processTratSameScore"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="iratPoints table-responsive hidden-xs hidden-sm">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="numColumn">No.</th>
                                    <th class="width388">Question</th>
                                    <th>No. Of Attempts</th>
                                    <template v-for="n in (1, max_options_count)">
                                        <th>
                                            {{ n }}<template v-if="n % 10 == 1"> ST </template>
                                            <template v-else-if="n % 10 == 2"> ND </template>
                                            <template v-else-if="n % 10 == 3"> RD </template>
                                            <template v-else> TH </template>
                                            Attempt
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(question, idx) in form.model.data">
                                    <td>
                                        <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                            {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>
                                    </td>
                                    <td>
                                        <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" class="wordBreakWord" />
                                    </td>
                                    <td>
                                        {{ question.question.options.length }}
                                    </td>
                                    <template v-for="(point, point_idx) in question.points">
                                        <td>
                                            <kr-field
                                                v-if="iratandtratMode == 'edit'"
                                                :form="form"
                                                :name="'data.' + idx + '.points.' + point_idx"
                                                :options="{
                                                    type: 'number',
                                                    hasLabel: false,
                                                    min:
                                                        point_idx + 1 < question.points.length
                                                            ? Math.max(point_idx == 0 ? 1 : 0, question.points[point_idx + 1])
                                                            : point_idx == 0
                                                            ? 1
                                                            : 0,
                                                    max: point_idx != 0 ? question.points[point_idx - 1] : 99999999999,
                                                    showError: false,
                                                }"
                                                @changed="
                                                    form.model.data.splice();
                                                    recomputeTRATPoints();
                                                "
                                            />
                                            <template v-else>
                                                {{ point }}
                                            </template>
                                        </td>
                                    </template>
                                    <template v-for="n in (1, max_options_count - question.question.options.length)">
                                        <td />
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 marginTop30 flexRight">
                            <div class="col-xs-12 col-sm-4 col-md-2">
                                <div class="control-label">Total Points For TRAT</div>
                                <div class="form-group">
                                    <input
                                        id="irat-trat-trat-total-points"
                                        class="form-control"
                                        :value="total_trat_points"
                                        disabled
                                        aria-label="Total Points for TRAT"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="(question, idx) in form.model.data" class="hidden-md hidden-lg pointSettingDiv">
                        <div class="header flexSpaceBetween align-items">
                            <div class="fontBold">QUESTION NO.</div>
                            <div>
                                <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                    {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="body">
                            <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" />
                            <!-- <div class="marginTop10 fontBold">
								More&nbsp;<i class="fas fa-caret-down"></i>
							</div>	 -->
                        </div>
                        <div class="footer">
                            <div class="marginBottom20 fontBold">
                                No. Of Attempts:
                                {{ question.question.options.length }}
                            </div>
                            <template v-for="(point, point_idx) in question.points">
                                <div class="attemptsDiv flexSpaceBetween align-items">
                                    <div class="fontBold">
                                        {{ point_idx + 1 }}<template v-if="(point_idx + 1) % 10 == 1"> ST </template>
                                        <template v-else-if="(point_idx + 1) % 10 == 2"> ND </template>
                                        <template v-else-if="(point_idx + 1) % 10 == 3"> RD </template>
                                        <template v-else> TH </template>
                                        Attempt Point
                                    </div>
                                    <div>
                                        <kr-field
                                            v-if="iratandtratMode == 'edit'"
                                            :form="form"
                                            :name="'data.' + idx + '.points.' + point_idx"
                                            :options="{
                                                type: 'number',
                                                hasLabel: false,
                                                min:
                                                    point_idx + 1 < question.points.length
                                                        ? Math.max(point_idx == 0 ? 1 : 0, question.points[point_idx + 1])
                                                        : point_idx == 0
                                                        ? 1
                                                        : 0,
                                                max: point_idx != 0 ? question.points[point_idx - 1] : 99999999999,
                                                showError: false,
                                            }"
                                            @changed="
                                                form.model.data.splice();
                                                recomputeTRATPoints();
                                            "
                                        />
                                        <template v-else>
                                            {{ point }}
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>
        <kr-panel v-if="activityObj.type == 'application'" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Application Point Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <template v-if="applicationMode == 'edit'">
                        <button class="btn btn-success margin0 flexInherit" @click.prevent="submitPointsSettings('application')">Update</button>
                        <button class="btn btn-default margin0 flexInherit" exact @click.prevent="applicationMode = 'view'">Cancel</button>
                    </template>
                    <template v-else="applicationMode == 'view'">
                        <button
                            class="btn btn-default margin0 flexInherit"
                            @click.prevent="
                                applicationMode = 'edit';
                                init();
                            "
                        >
                            <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                        </button>
                    </template>
                </div>
            </template>
            <template #content>
                <template v-if="!isSet && applicationMode == 'view' && pageType == 'confirmation'"> Not Set </template>
                <template v-else>
                    <div class="iratPoints table-responsive hidden-xs hidden-sm">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="numColumn">No.</th>
                                    <th class="width600px">Question</th>
                                    <th>Point Given</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(question, idx) in form.model.data">
                                    <td>
                                        <button class="btn gradient btn-default" @click.prevent="openQuestionInfoModal(question)">
                                            {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                        </button>
                                    </td>
                                    <td>
                                        <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" class="wordBreakWord" />
                                    </td>
                                    <td>
                                        <kr-field
                                            v-if="pageType == 'form' || applicationMode == 'edit'"
                                            :form="form"
                                            :name="'data.' + idx + '.point'"
                                            :options="{
                                                type: 'number',
                                                hasLabel: false,
                                                min: 0,
                                                showError: false,
                                            }"
                                            @changed="recomputeApplicationPoints()"
                                        />
                                        <template v-else>
                                            {{ question.point }}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12 marginTop30 flexRight">
                            <div class="col-xs-12 col-sm-4 col-md-2">
                                <div class="control-label">Total Points For Application</div>
                                <div class="form-group">
                                    <input
                                        id="application-total-points"
                                        class="form-control"
                                        :value="total_application_points"
                                        disabled
                                        aria-label="Total Points for Application"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="(question, idx) in form.model.data" class="hidden-md hidden-lg pointSettingDiv">
                        <div class="header flexSpaceBetween align-items">
                            <div class="fontBold">QUESTION NO.</div>
                            <div>
                                <button class="btn gradient btn-default" :aria-label="idx + 1" @click.prevent="openQuestionInfoModal(question)">
                                    {{ idx + 1 }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="body">
                            <kr-math :input="question.question.question" :safe="!question.question.questionIsHTML" />
                            <!-- <div class="marginTop10 fontBold">
								More&nbsp;<i class="fas fa-caret-down"></i>
							</div>	 -->
                        </div>
                        <div class="footer">
                            <div class="flexSpaceBetween align-items">
                                <div class="fontBold">POINT GIVEN</div>
                                <div>
                                    <kr-field
                                        v-if="pageType == 'form' || applicationMode == 'edit'"
                                        :form="form"
                                        :name="'data.' + idx + '.point'"
                                        :options="{
                                            type: 'number',
                                            hasLabel: false,
                                            min: 0,
                                            showError: false,
                                        }"
                                        @changed="recomputeApplicationPoints()"
                                    />
                                    <template v-else>
                                        {{ question.point }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>
        <div v-if="pageType == 'form'" class="marginTop10">
            <!--<button class="btn btn-default ">
                <i class="fas fa-caret-left"></i>&nbsp;Back
            </button>-->
            <button class="btn btn-success" aria-label="Go to Optional Settings" @click.prevent="submitPointsSettings">
                Go to Optional Settings<i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit
            </button>
        </div>
        <div id="questionInfoModal-point-settings" class="modal" tabindex="-1" role="dialog" aria-labelledby="questionInfoModalPointSettings-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="questionInfoModalPointSettings-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <question-display :question="previewQuestion" />
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../../../components/forms/form';
export default {
    props: ['activityObj', 'options'],
    data() {
        return {
            component_done_loading: false,
            total_irat_points: 0,
            total_trat_points: 0,
            total_application_points: 0,
            form: new KrForm({
                fields: {
                    data: 'type!:array',
                },
                props: {
                    successMessage: false,
                },
                urls: {
                    post: 'activities',
                },
            }),
            pageType: 'form',
            iratMode: 'view',
            tratMode: 'view',
            iratandtratMode: 'view',
            applicationMode: 'view',
            max_options_count: 0,
            isSet: true,
            previewQuestion: {},

            iratSamePoint: null,
            tratSamePoint: null,
            applicationSamePoint: null,
            tratSameScore: false,
            tratOptionScores: [5, 3, 1, 0],
        };
    },
    watch: {
        options: {
            handler: function () {
                this.pageType = _.get(this.options, 'pageType', 'form');
            },
            deep: true,
        },
    },

    created() {
        this.pageType = _.get(this.options, 'pageType', 'form');
        this.init();
        this.component_done_loading = true;
    },
    methods: {
        setSamePoint(type = 'irat') {
            for (var i = 0; i < this.form.model.data.length; i++) {
                if (type == 'irat') {
                    this.form.model.data[i].point = this.iratSamePoint;
                }
                if (type == 'application') {
                    this.form.model.data[i].point = this.applicationSamePoint;
                }
            }
            if (type == 'irat') {
                this.recomputeIRATPoints();
            }
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Updated questions to use same point',
            });
        },
        saveAndExit() {
            this.submitPointsSettings(false, true);
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question.question;
            $('#questionInfoModal-point-settings').modal('show');
        },
        init() {
            this.form.model.data = [];
            let questionGroups = this.activityObj.questions;
            let total_questions_count = 0;
            let defaultPoints = this.activityObj.defaultTratSettings
                ? _.isArray(this.activityObj.defaultTratSettings)
                    ? this.activityObj.defaultTratSettings
                    : _.get(this.activityObj, 'defaultTratSettings.points', [5, 3, 1, 0])
                : [5, 3, 1, 0];
            this.tratOptionScores = defaultPoints;
            for (var i = 0; i < questionGroups.length; i++) {
                for (var j = 0; j < questionGroups[i].length; j++) {
                    total_questions_count++;
                }
            }
            for (var i = 0; i < questionGroups.length; i++) {
                for (var j = 0; j < questionGroups[i].length; j++) {
                    let points = [];
                    let point = 1;
                    let options = _.get(questionGroups[i][j], 'options', []);
                    if (!options) {
                        options = [];
                    }
                    if (this.max_options_count < options.length) {
                        this.max_options_count = options.length;
                    }
                    /*for(var k =options.length-1;k>=0;k--){
							let p = k+1;
							if(k==0){
								p = 0;
							}
							points.push(p);
						}*/
                    if (defaultPoints[0] == 0) {
                        defaultPoints[0] = 1;
                    }
                    let firstAttempt = null;
                    if (options.length == 2) {
                        firstAttempt = defaultPoints[2] == 0 ? 1 : defaultPoints[2];
                        points = [firstAttempt, defaultPoints[3]];
                    } else if (options.length == 3) {
                        firstAttempt = defaultPoints[1] == 0 ? 1 : defaultPoints[1];
                        points = [firstAttempt, defaultPoints[2], defaultPoints[3]];
                    } else if (options.length == 4) {
                        points = _.cloneDeep(defaultPoints);
                    } else if (options.length >= 5) {
                        points = _.cloneDeep(defaultPoints);
                        for (var k = points.length; k < options.length; k++) {
                            points.push(0);
                        }
                    }
                    let irat = _.get(this.activityObj, 'settings.irat.questions', []);
                    let trat = _.get(this.activityObj, 'settings.trat.questions', []);
                    let application = _.get(this.activityObj, 'settings.application.questions', []);

                    let qObj = _.find(irat, function (o) {
                        return o.uuid == questionGroups[i][j].uuid;
                    });
                    if (qObj) {
                        point = qObj.point ? qObj.point : point;
                    }
                    qObj = _.find(application, function (o) {
                        return o.uuid == questionGroups[i][j].uuid;
                    });
                    if (qObj) {
                        point = qObj.point ? qObj.point : point;
                    }
                    qObj = _.find(trat, function (o) {
                        return o.uuid == questionGroups[i][j].uuid;
                    });
                    if (qObj) {
                        points = qObj.points && qObj.points.length == points.length ? qObj.points : points;
                    }
                    if (this.activityObj.type == 'irat') {
                        this.form.model.data.push({
                            question: questionGroups[i][j],
                            uuid: questionGroups[i][j].uuid,
                            point: point,
                        });
                    }
                    if (this.activityObj.type == 'trat') {
                        this.form.model.data.push({
                            question: questionGroups[i][j],
                            uuid: questionGroups[i][j].uuid,
                            points: points,
                        });
                    }
                    if (this.activityObj.type == 'application') {
                        this.form.model.data.push({
                            question: questionGroups[i][j],
                            uuid: questionGroups[i][j].uuid,
                            point: point,
                        });
                    }
                    if (this.activityObj.type == 'iratntrat') {
                        this.form.model.data.push({
                            question: questionGroups[i][j],
                            uuid: questionGroups[i][j].uuid,
                            point: point,
                            points: points,
                        });
                    }
                }
            }
            if (this.activityObj.pointSettingsVersion) {
                this.isSet = true;
            } else {
                this.isSet = false;
            }
            if (this.activityObj.type == 'irat' || this.activityObj.type == 'iratntrat') {
                this.recomputeIRATPoints();
            }
            if (this.activityObj.type == 'trat' || this.activityObj.type == 'iratntrat') {
                this.recomputeTRATPoints();
            }
            if (this.activityObj.type == 'application') {
                this.recomputeApplicationPoints();
            }
        },
        submitPointsSettings(mode = 'irat', exitOnSave = false) {
            var that = this;
            let url = '/activities/' + this.activityObj.uuid + '/settings?settings=point';
            this.form.put(url).then(function (response) {
                if (!response.krFormError) {
                    that.$emit('activity-updated', response.data.data);
                    if (exitOnSave === true) {
                        Vue.nextTick(function () {
                            that.$emit('save-and-exit');
                        });
                    } else {
                        if (that.pageType == 'form') {
                            Vue.nextTick(function () {
                                that.$emit('next-step');
                            });
                        } else {
                            if (mode == 'irat') {
                                that.iratMode = 'view';
                            }
                            if (mode == 'trat') {
                                that.tratMode = 'view';
                            }
                            if (mode == 'iratntrat') {
                                that.iratandtratMode = 'view';
                            }
                            if (mode == 'application') {
                                that.applicationMode = 'view';
                            }
                        }
                        Vue.nextTick(function () {
                            that.init();
                        });
                    }
                }
            });
        },
        recomputeIRATPoints() {
            if (this.activityObj.type == 'irat' || this.activityObj.type == 'iratntrat') {
                this.total_irat_points = 0;
                let questions = _.get(this, 'form.model.data', []);
                for (var i = 0; i < questions.length; i++) {
                    this.total_irat_points += Number(questions[i].point);
                }
            } else {
                this.total_irat_points = 0;
            }
        },
        recomputeApplicationPoints() {
            if (this.activityObj.type == 'application') {
                this.total_application_points = 0;
                let questions = _.get(this, 'form.model.data', []);
                for (var i = 0; i < questions.length; i++) {
                    this.total_application_points += Number(questions[i].point);
                }
            } else {
                this.total_application_points = 0;
            }
        },
        recomputeTRATPoints() {
            if (this.activityObj.type == 'trat' || this.activityObj.type == 'iratntrat') {
                this.total_trat_points = 0;
                let questions = _.get(this, 'form.model.data', []);
                for (var i = 0; i < questions.length; i++) {
                    this.total_trat_points += Number(questions[i].points[0]);
                }
            } else {
                this.total_trat_points = 0;
            }
        },
        processTratSameScore() {
            if (this.tratSameScore) {
                if (this.tratOptionScores[0] <= 0) {
                    this.tratOptionScores[0] = 1;
                }
                if (this.activityObj.type == 'trat' || this.activityObj.type == 'iratntrat') {
                    for (var i = 0; i < this.form.model.data.length; i++) {
                        if (this.form.model.data[i].points.length >= 4) {
                            for (var j = 0; j < this.form.model.data[i].points.length; j++) {
                                if (j < 4) {
                                    this.form.model.data[i].points[j] = Number(this.tratOptionScores[j]);
                                } else {
                                    this.form.model.data[i].points[j] = 0;
                                }
                            }
                        }
                    }
                    this.recomputeTRATPoints();
                    this.form.model.data.splice();
                }
            }
        },
    },
    components: {
        'question-display': require(`./../../../../questions/partials/question-display.vue`).default,
    },
};
</script>

<style scoped>
.pointSettingDiv {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    border-radius: 3px;
    margin-top: 20px;
}

.pointSettingDiv:first-child {
    margin-top: 0;
}

.pointSettingDiv .header,
.pointSettingDiv .footer {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 15px 20px;
}

.pointSettingDiv .header {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border-bottom: solid 1px #d8d8d8;
}

.pointSettingDiv .footer {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border-top: solid 1px #d8d8d8;
}

.pointSettingDiv .body {
    /* max-height: 105px; */
    overflow: auto;
    padding: 15px 20px;
}

.pointSettingDiv .numberInput input {
    width: 62px;
}

.attemptsDiv {
    margin-top: 10px;
}

.attemptsDiv:first-child {
    margin-top: 0;
}
</style>
