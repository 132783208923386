<template>
    <div v-if="component_done_loading">
        <kr-panel :with-footer="false" :options="{ canToggle: false }" class="overflowXinherit">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Prework Materials ({{ testObj.materials.length }})</h2>
                </div>

                <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px }">
                    <button class="btn btn-default" @click.prevent="openFileStack">
                        <i class="fas fa-upload marginRight8" aria-hidden="true"></i>Prework Material
                    </button>
                </div>
            </template>
            <template #content>
                <div v-if="testObj.materials.length > 0" class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="minWidth90px width90px maxWidth90px" scope="col">Actions</th>
                                <th scope="col">File</th>
                                <th class="minWidth130px width130px maxWidth130px" scope="col">Opened</th>
                                <th class="minWidth130px width130px maxWidth130px" scope="col">Downloaded</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(attachment, idx) in testObj.materials">
                                <td class="py-9px">
                                    <div class="dropup">
                                        <button
                                            :id="`viewDropdownMenuButton-${idx}`"
                                            class="btn btn-outline-default icon-only margin0"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="More Actions"
                                        >
                                            <i class="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <div
                                            :id="`dropdown-${idx}`"
                                            class="dropdown-menu dropdown-menu-left dropdown-menu-new"
                                            :aria-labelledby="`viewDropdownMenuButton-${idx}`"
                                        >
                                            <ul :key="idx" role="menu">
                                                <li role="menuitem">
                                                    <a class="primary-state" target="_blank" :href="attachment.material.url">
                                                        <span>
                                                            <span class="material-symbols-outlined filled marginRight8" aria-hidden="true">
                                                                switch_account </span
                                                            >Preview Attachment
                                                        </span>
                                                    </a>
                                                </li>
                                                <li v-if="idx != 0" role="menuitem">
                                                    <a class="primary-state" href="#" @click.prevent="changeOrdering(idx, 'up', attachment.uuid)">
                                                        <span>
                                                            <span class="material-symbols-outlined filled marginRight8" aria-hidden="true"> arrow_upward </span
                                                            >Move Up
                                                        </span>
                                                    </a>
                                                </li>
                                                <li v-if="idx != testObj.materials.length - 1" role="menuitem">
                                                    <a class="primary-state" href="#" @click.prevent="changeOrdering(idx, 'down', attachment.uuid)">
                                                        <span>
                                                            <span class="material-symbols-outlined filled marginRight8" aria-hidden="true">
                                                                arrow_downward </span
                                                            >Move Down
                                                        </span>
                                                    </a>
                                                </li>
                                                <hr />
                                                <li role="menuitem">
                                                    <a class="danger-state" href="#" @click.prevent="openDeleteMaterialConfirmationModal(idx)">
                                                        <span> <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Remove Attachment </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ attachment.material.filename }}</td>
                                <td>
                                    <button
                                        class="btn btn-outline-primary"
                                        :aria-label="`${getOpenedPercentage(attachment)}%`"
                                        @click.prevent="openStudentsList(attachment, 'opened')"
                                    >
                                        {{ getOpenedPercentage(attachment) }}%
                                        <i class="fas fa-chevron-right marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </td>
                                <td>
                                    <button
                                        class="btn btn-outline-primary"
                                        :aria-label="`${getDownloadPercentage(attachment)}%`"
                                        @click.prevent="openStudentsList(attachment, 'downloaded')"
                                    >
                                        {{ getDownloadPercentage(attachment) }}%
                                        <i class="fas fa-chevron-right marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>&nbsp;</div>
            </template>
        </kr-panel>
        <div id="viewStudentsModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="viewStudentsModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="viewStudentsModal-title" class="modal-title">
                            <template v-if="previewStudentList.type == 'opened'">
                                Students who opened the material ({{ previewStudentList.data.length }})
                            </template>
                            <template v-if="previewStudentList.type == 'downloaded'">
                                Students who downloaded the material ({{ previewStudentList.data.length }})
                            </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <ul>
                                    <template v-for="(student, idx) in previewStudentList.data">
                                        <li>{{ student }}</li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12">
                                    <button class="btn btn-default" style="width: 100%" data-dismiss="modal" aria-label="Back">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="itemAnalysisDeleteMaterialModal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="itemAnalysisDeleteMaterialModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="itemAnalysisDeleteMaterialModal-title" class="modal-title">Delete Material Confirmations</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: center">
                                <div>Are you sure you want to delete this material?</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-logout-btn"
                                        class="btn btn-danger"
                                        style="width: 100%; padding-right: 10px"
                                        data-dismiss="modal"
                                        aria-label="Yes, Delete"
                                        @click.prevent="deleteMaterial(delete_idx)"
                                    >
                                        Yes, Delete
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default" style="width: 100%" data-dismiss="modal" aria-label="No, Cancel">
                                        No, Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as filestack from 'filestack-js';
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: true,
            previewStudentList: {
                type: 'opened',
                data: [],
            },
            materials: [],
            delete_idx: '',
        };
    },
    watch: {
        testObj: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    methods: {
        openDeleteMaterialConfirmationModal(delete_idx) {
            this.delete_idx = delete_idx;
            $('#itemAnalysisDeleteMaterialModal').modal('show');
        },
        deleteMaterial(idx) {
            let material = this.testObj.materials[idx];
            var that = this;
            axios
                .delete('tests/' + that.testObj.uuid + '/materials/' + material.uuid)
                .then(function (response) {
                    that.testObj.materials.splice(idx, 1);
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Material has been deleted from test',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to delete material at this moment, please try again later',
                    });
                });
        },
        openFileStack() {
            this.materials = [];
            this.client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            this.client
                .picker({
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.png',
                        '.csv',
                        '.doc',
                        '.docx',
                        '.dot',
                        '.dotx',
                        '.key',
                        '.numbers',
                        '.odt',
                        '.odp',
                        '.ods',
                        '.pages',
                        '.pdf',
                        '.ppt',
                        '.pot',
                        '.potx',
                        '.pps',
                        '.ppsx',
                        '.pub',
                        '.rtf',
                        '.sldx',
                        '.txt',
                        '.xls',
                        '.xlt',
                        '.xlsx',
                        '.mp4',
                        '.mp3',
                    ],
                    maxFiles: 20,
                    uploadInBackground: false,
                    onUploadStarted: function (file) {
                        //that.is_uploading = true;
                    },
                    onUploadDone: function (res) {
                        for (var i = 0; i < res.filesUploaded.length; i++) {
                            that.materials.push(res.filesUploaded[i]);
                        }
                        axios
                            .post('tests/' + that.testObj.uuid + '/materials', {
                                materials: that.materials,
                            })
                            .then(function (response) {
                                that.$notify({
                                    group: 'form',
                                    type: 'success',
                                    title: 'Success',
                                    text: 'Materials have been added to the test',
                                });
                            })
                            .catch(function (errors) {
                                that.$notify({
                                    group: 'form',
                                    type: 'error',
                                    title: 'Error',
                                    text: 'Unable to add materials at this moment, please try again later',
                                });
                            });
                    },
                })
                .open();
        },
        init() {
            /*if(_.isEmpty(this.testObj.materials)){
					this.testObj.materials = [];
				}*/
        },
        openStudentsList(attachment, type = 'opened') {
            if (type == 'opened') {
                this.previewStudentList.type = 'opened';
                this.previewStudentList.data = attachment.opened;
            }
            if (type == 'downloaded') {
                this.previewStudentList.type = 'downloaded';
                this.previewStudentList.data = _.difference(attachment.downloaded, attachment.opened);
            }
            $('#viewStudentsModal').modal('show');
        },
        getDownloadPercentage(attachment) {
            let total_students = this.testObj.students.length;
            if (total_students == 0) {
                return 0;
            }
            return Math.floor((_.difference(attachment.downloaded, attachment.opened).length / total_students) * 100);
        },
        getOpenedPercentage(attachment) {
            let total_students = this.testObj.students.length;
            if (total_students == 0) {
                return 0;
            }
            return Math.floor((attachment.opened.length / total_students) * 100);
        },
        changeOrdering(idx, type, material_uuid) {
            var that = this;
            var old_order = idx;
            axios
                .patch(`activities/${this.testObj.activityUuid}/materials/${material_uuid}/reorder`, {
                    order: type == 'up' ? idx - 1 : idx + 1,
                })
                .then(function (response) {
                    that.testObj.materials.splice(type == 'up' ? idx - 1 : idx + 1, 0, that.testObj.materials.splice(old_order, 1)[0]);
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'The material order has been updated successfully.',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
    },
};
</script>
<style scoped>
#itemAnalysisDeleteMaterialModal .modal-body .row:last-child {
    margin-top: 20px;
}

#itemAnalysisDeleteMaterialModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#itemAnalysisDeleteMaterialModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>
