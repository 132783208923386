
.cognalearn-question-highlight {
    background: var(--highlight-color);
}

.highlight-area {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}
