
.cardCons {
    margin: 0 0 20px 20px;
    width: calc(100% - 20px);

    font-size: inherit;
    text-align: inherit;
    font-weight: inherit;
    padding: inherit;
    height: 100%;

    .card {
        border-radius: 3px;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
        box-shadow: 4px 4px 4px 0 rgba(202, 202, 202, 0.5);
        position: relative;
    }

    .card-header {
        background-color: #495060;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 15px;
        line-height: 1;

        div {
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .card-body {
        & > div {
            padding: 10px 15px;
            height: 80px;

            div:first-child {
                max-height: 72%;
                overflow: hidden;
            }

            div:last-child {
                line-height: 1;
                font-weight: bold;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    .card-footer {
        background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
        border-top: 1px solid #d8d8d8;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
        color: #717171;
        font-size: 12px;

        .fa {
            font-size: 10px;
            width: 10px;
            height: 10px;

            &.fontSize14 {
                font-size: 14px;
                width: inherit;
                height: inherit;
            }
        }
    }

    &:hover {
        cursor: pointer;

        .card {
            border: 1px solid #0071be;
        }

        .card-footer {
            border-top: 1px solid #0071be;
        }
    }
}
.cardBtns button {
    border-radius: 100px;
    padding: 6px 8.93px;
}

.cardBtns {
    position: absolute;
    right: 15px;
    bottom: 56px;
}

.flexSpaceBetween > button {
    border: none;
    padding: none;
}

.flexSpaceBetween > button .fa {
    color: #717171;
}

@media (min-width: 992px) {
    .questionIndexPanel {
        padding-left: 20px;
    }

    .flexSpaceBetween > button {
        border: 1px solid #d8d8d8;
        text-align: center;
        padding: 10px 20px;
        line-height: 1;
        border-radius: 3px;
    }

    .questionIndexPanel .pagination {
        margin-left: 20px;
    }

    .questionIndexPanel .pagination.marginLeft0 {
        margin-left: 0;
    }

    .flexSpaceBetween > button .fa {
        font-size: 12px;
    }
}
