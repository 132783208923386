<template>
    <div class="col-xs-12 right-container">
        <div class="col-md-12 form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">PREDICTION</div>
                </template>
                <template #content>
                    <div>
                        <div class="container marginBottom30">
                            <div class="row flex justify-content-between">
                                <div class="col-sm-7">
                                    <div>
                                        <b>(Predicted Final Performance) Final Grade based on IRAT</b>
                                    </div>
                                    <div>
                                        <kr-scatter-chart :chartdata="chartdata" :options="chartoptions" :height="300"></kr-scatter-chart>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-sm-offset-1">
                                    <div>
                                        <kr-panel :with-footer="false">
                                            <template #title> LEGEND </template>
                                            <template #content>
                                                <div class="row flex align-items-center">
                                                    <div class="legend-box red"></div>
                                                    At risk students
                                                </div>
                                                <div class="row flex align-items-center">
                                                    <div class="legend-box blue"></div>
                                                    Well performing students
                                                </div>
                                            </template>
                                        </kr-panel>
                                        <kr-panel :with-footer="false" class="adjust_score_box">
                                            <template #title> Adjust Threshold % </template>
                                            <template #content>
                                                <div class="row flex align-items-center">
                                                    <div class="col-sm-6">
                                                        <b>Score %</b>
                                                    </div>
                                                    <div class="col-sm-6 text-right">
                                                        <button type="button" class="btn undo-btn" aria-label="Undo" @click="score = 20">
                                                            <i class="fas fa-undo marginRight8" aria-hidden="true"></i>Undo
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="numberInput">
                                                        <input v-model.number="score" type="number" class="form-control" />
                                                        <span class="quantity-nav">
                                                            <div
                                                                class="quantity-button quantity-up"
                                                                :class="{
                                                                    disabled: score == 100,
                                                                }"
                                                                @click="quantityUp"
                                                            >
                                                                <i class="fas fa-plus" aria-hidden="true"></i>
                                                            </div>
                                                            <div
                                                                class="quantity-button quantity-down"
                                                                :class="{
                                                                    disabled: score == 0,
                                                                }"
                                                                @click="quantityDown"
                                                            >
                                                                <i class="fas fa-minus" aria-hidden="true"></i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                        </kr-panel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="viewFilter floatLeft marginBottom10">
                            <div class="form-group displayContent">
                                <!-- <label class="col-form-label">VIEW</label> -->
                                <div class="form-dropdown viewFilter marginLeft0">
                                    <select
                                        v-model="isAtRisk"
                                        class="form-control"
                                        :aria-label="isAtRisk == true ? 'At risk students' : 'Well performing students'"
                                    >
                                        <option :value="true">At risk students</option>
                                        <option :value="false">Well performing students</option>
                                        <!-- <option value="*">All</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <th>NAME</th>
                                    <th>SECTION</th>
                                    <th>TEAM</th>
                                    <th>AVG. IRAT %</th>
                                    <th>(PREDICTED FINAL PERFORMANCE) FINAL GRADE %</th>
                                    <th v-if="isAtRisk">Weak Topics</th>
                                </thead>
                                <tbody>
                                    <template v-for="(student, student_idx) in predictions.students">
                                        <tr v-if="(isAtRisk && student.finalGradeIrat < score) || (!isAtRisk && student.finalGradeIrat >= score)">
                                            <td>{{ student.fullname }}</td>
                                            <td>{{ student.section }}</td>
                                            <td>{{ student.team }}</td>
                                            <td>{{ round(student.avgIrat) }}%</td>
                                            <td>{{ round(student.finalGradeIrat) }}%</td>
                                            <td v-if="isAtRisk">
                                                <div v-for="(tag, idx) in generateRandomWords()" class="random-label">
                                                    <span> <i class="fa fa-tag marginRight8" aria-hidden="true"></i>{{ tag }} </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-if="noData">
                                        <td colspan="5" style="text-align: center">NO STUDENT(S)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../components/auth/auth';
export default {
    components: {},
    props: ['courseObj'],
    data() {
        var auth = new KrAuth();
        return {
            course: null,
            students: [],
            auth: auth,
            trash_field: true,
            component_done_loading: true,
            predictions: {},
            score: 20,
            isAtRisk: true,
            chartdata: {},
            chartoptions: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: 100,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Predicted Grade (%)',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: 100,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Average IRAT (%)',
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].name || '';
                            if (label) {
                                label += ' - ';
                            }
                            label += tooltipItem.yLabel + '%';
                            return label;
                        },
                    },
                },
            },
        };
    },
    computed: {
        noData() {
            if (!this.predictions.students) {
                return true;
            }
            if (this.isAtRisk) {
                for (var i = 0; i < this.predictions.students.length; i++) {
                    let student = this.predictions.students[i];
                    if (student.finalGradeIrat < this.score) {
                        return false;
                    }
                }
                return true;
            } else {
                for (var i = 0; i < this.predictions.students.length; i++) {
                    let student = this.predictions.students[i];
                    if (student.finalGradeIrat >= this.score) {
                        return false;
                    }
                }
                return true;
            }
        },
    },
    watch: {
        score: {
            handler: function () {
                this.renderChart();
            },
        },
    },
    created() {
        var that = this;

        if (!this.courseObj) {
            axios.get('/courses/' + this.$route.params.id).then(function (response) {
                that.course = response.data.data;
                that.fetch().then(function (response) {
                    that.predictions = response.data.data;
                    //that.component_done_loading = true;
                    Vue.nextTick(function () {
                        that.renderChart();
                    });
                });
            });
        } else {
            this.course = this.courseObj;
            that.fetch().then(function (response) {
                that.predictions = response.data.data;
                //that.component_done_loading = true;
                Vue.nextTick(function () {
                    that.renderChart();
                });
            });
        }
    },
    methods: {
        generateRandomWords() {
            let array = ['Algebra', 'Calculus and analysis', 'Geometry and topology', 'Combinatorics', 'Logic', 'Number Theory'];
            let n = Math.floor(Math.random() * array.length) + 1;
            const shuffled = array.sort(() => 0.5 - Math.random());
            // Get sub-array of first n elements after shuffled
            let selected = shuffled.slice(0, n);
            return selected;
        },
        fetch() {
            return axios.get(`/courses/${this.course.uuid}/analytics/prediction`);
        },
        getSearchData(data) {
            this.searchData = data;
        },
        quantityUp() {
            if (this.score < 100) {
                this.score++;
            }
        },
        quantityDown() {
            if (this.score > 0) {
                this.score--;
            }
        },
        // TODO: Update data from API
        renderChart() {
            let students = this.predictions.students;
            let wellStudents = [];
            let unwellStudents = [];
            for (var i = 0; i < students.length; i++) {
                if (this.score <= students[i].finalGradeIrat) {
                    wellStudents.push({
                        x: students[i].avgIrat,
                        y: this.round(students[i].finalGradeIrat, 1),
                        name: students[i].fullname,
                    });
                } else {
                    unwellStudents.push({
                        x: students[i].avgIrat,
                        y: this.round(students[i].finalGradeIrat, 1),
                        name: students[i].fullname,
                    });
                }
            }
            this.chartdata = {
                datasets: [
                    {
                        label: 'At risk students',
                        data: unwellStudents,
                        backgroundColor: '#f8d7da',
                    },
                    {
                        label: 'Well performing students',
                        data: wellStudents,
                        backgroundColor: '#cce5ff',
                    },
                ],
            };
        },
    },
};
</script>

<style scoped>
.right-container {
    padding: 20px 0px;
    background: white;
}

.align-items-center {
    align-items: center;
}

.legend-box {
    width: 40px;
    height: 25px;
    border: solid 1px;
    border-radius: 3px;
    margin: 10px 10px;
}

.red {
    background-color: #f8d7da;
}

.blue {
    background-color: #cce5ff;
}

.adjust_score_box {
    width: 50%;
}

.numberInput {
    position: relative;
    margin: 5px 0px;
    width: 100%;
}

.undo-btn {
    color: #717171;
    padding-right: 0px !important;
}
.random-label {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
