
.input-group.date {
    margin-bottom: 0;
}

.width117px {
    width: 117px;
}

#earlyStartDurationInMinutes-div {
    width: fit-content;
}

.modifyNumberGroup {
    display: inline-flex;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 3px;
    }

    .numberInput {
        display: inline-block;
    }

    .form-control {
        outline: 0;
        outline-offset: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 64px;

        &.disabled {
            border: none;
            &.hasTooltip {
                pointer-events: inherit;
            }
        }
    }
}
