<template>
    <div v-if="component_done_loading" class="test" :class="{ disabledImagePointerEvents: !test.settings.enableImagePointerEvents }">
        <test-topbar :test-obj="test" />
        <main class="col-xs-12 right-container clarification">
            <team-leadership :test-obj="test" class="marginBottom20" @is-team-leader="processIsTeamLeader($event)" />
            <div v-if="test.clarificationStatus == 'released'" class="alert alert-primary flex align-items marginBottom20">
                <div class="flexOnly marginRight8" :class="{ 'align-items': !isMobileView }">
                    <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                    <p>Please note that your team is highlighted in</p>
                </div>
                <div class="statusTag statusTagLightWarning">
                    <i class="fa fa-users greyText marginRight8" aria-hidden="true" /> {{ test.myProgression.team.name }}
                </div>
            </div>

            <kr-panel :with-footer="false">
                <template #title>
                    <h2>All Clarifying Questions ({{ clarified_questions.length }})</h2>
                </template>
                <template #content>
                    <template v-if="isMobileView">
                        <button
                            v-if="canEditClarifications"
                            class="btn btn-primary marginBottom20"
                            aria-label="New Freestyle"
                            @click.prevent="openRequestModal(add_question)"
                        >
                            <i class="fas fa-plus marginRight8" aria-hidden="true" />New Freestyle
                        </button>

                        <div v-for="(question, question_idx) in clarified_questions" v-if="isMobileView" class="mini-panel panel">
                            <div class="panel-heading">
                                <template v-if="question._type == 'question'">
                                    Question {{ question.question_no }}
                                    <template v-if="question.studentAnswer.isBookmarked"> &nbsp;<i class="bookmarked fa fa-bookmark" /> </template>
                                </template>
                                <template v-else> Freestyle </template>
                            </div>
                            <div class="panel-body">
                                <div>
                                    <div v-if="question._type == 'question'">
                                        <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML" />
                                        <div class="marginTop10 fontBold blue pointer" aria-label="Read More" @click.prevent="openQuestionInfoModal(question)">
                                            Read More<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="alert alert-primary marginTop10">
                                            <div>
                                                <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                            </div>
                                            &nbsp;
                                            <span>
                                                For freestyle clarification, you may type in any clarifying questions OR vote for questions raised by other
                                                teams if your team has a similar question about the test.
                                            </span>
                                            <div>
                                                <button type="button" class="close minWidthAuto" data-dismiss="modal" aria-label="Close Modal">
                                                    <i class="fa-solid fa-xmark" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="question._type == 'question'" class="marginTop20">
                                    <div class="control-label">Answers Choices In Sequence</div>
                                    <div
                                        v-if="question._type == 'question' && question.studentAnswer?.attempts?.length > 0"
                                        class="statusTag"
                                        :class="{
                                            tagCorrect: question.percent == 100,
                                            tagPrimary: question.percent >= 50 && question.percent < 100,
                                            tagWarning: question.percent < 50 && question.percent > 0,
                                            tagIncorrect: question.percent == 0,
                                        }"
                                    >
                                        <template v-for="(key, idx) in question.studentAnswer.attempts"> {{ key }}&nbsp; </template>
                                    </div>
                                    <template v-else> - </template>
                                </div>

                                <div class="marginTop20">
                                    <div>
                                        <label class="control-label marginBottom0">We would like to clarify...</label>
                                        <template v-if="question._status != 'voted' && !(!question._status && !canEditClarifications)">
                                            <template v-if="canEditClarifications">
                                                <button
                                                    v-if="question._status || question._type == 'freestyle'"
                                                    v-tooltip="
                                                        question._type == 'question'
                                                            ? 'Edit clarification for question ' + question.question_no
                                                            : question._type == 'freestyle'
                                                            ? 'Edit freestyle clarification'
                                                            : ''
                                                    "
                                                    class="btn btn-primary d-block"
                                                    :aria-label="
                                                        question._type == 'question'
                                                            ? 'Edit clarification for question ' + question.question_no
                                                            : question._type == 'freestyle'
                                                            ? 'Edit freestyle clarification'
                                                            : ''
                                                    "
                                                    @click.prevent="openRequestModal(question)"
                                                >
                                                    <i class="fa fa-edit marginRight8" aria-hidden="true" /> Edit
                                                </button>
                                                <button
                                                    v-else
                                                    v-tooltip="question.studentAnswer?.attempts?.length == 0 ? 'You did not submit any answer' : ''"
                                                    class="btn btn-primary d-block"
                                                    :class="{
                                                        'disabled hasToolTip': question.studentAnswer.attempts.length == 0,
                                                    }"
                                                    :aria-label="
                                                        question.studentAnswer?.attempts?.length > 0 && question._type == 'question'
                                                            ? 'Request clarification for question ' + question.question_no
                                                            : question._type == 'freestyle'
                                                            ? 'Request freestyle clarification'
                                                            : ''
                                                    "
                                                    @click.prevent="question.studentAnswer?.attempts?.length == 0 ? '' : openRequestModal(question)"
                                                >
                                                    Request
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    v-if="question._status"
                                                    v-tooltip="
                                                        question._type == 'question'
                                                            ? 'View question ' + question.question_no + ' clarification'
                                                            : question._type == 'freestyle'
                                                            ? 'View freestyle calrification'
                                                            : ''
                                                    "
                                                    class="btn btn-primary d-block"
                                                    :aria-label="
                                                        question._type == 'question'
                                                            ? 'View question ' + question.question_no + ' clarification'
                                                            : question._type == 'freestyle'
                                                            ? 'View freestyle calrification'
                                                            : ''
                                                    "
                                                    @click.prevent="openRequestModal(question)"
                                                >
                                                    <i class="fas fa-eye marginRight8" aria-hidden="true" /> View
                                                </button>
                                            </template>
                                        </template>
                                    </div>
                                    <template v-if="question._type == 'question'">
                                        <div v-if="question.studentAnswer?.attachments?.length > 0" class="flex align-items marginBottom20 col-xs-12 col-md-12">
                                            <div class="col-xs-12">
                                                <label class="control-label">Uploaded Files</label>
                                                <div v-for="(file, idx) in question.studentAnswer.attachments" class="flexSpaceBetween">
                                                    <div>
                                                        <span>{{ idx + 1 }}.</span>
                                                        <span>
                                                            <i class="fa fa-file-image-o" aria-hidden="true" />
                                                        </span>
                                                        <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="question._type == 'freestyle'">
                                        <div v-if="question.attachments?.length > 0" class="flex align-items marginBottom20 col-xs-12 col-md-12">
                                            <div class="col-xs-12">
                                                <label class="control-label">Uploaded Files</label>
                                                <div v-for="(file, idx) in question.attachments" class="flexSpaceBetween">
                                                    <div>
                                                        <span>{{ idx + 1 }}.</span>
                                                        <span>
                                                            <i class="fa fa-file-image-o" aria-hidden="true" />
                                                        </span>
                                                        <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="question._type == 'question'">
                                        <div class="heightMax100px">
                                            {{ question.studentAnswer.clarifications }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="heightMax100px">
                                            {{ question.freestyle }}
                                        </div>
                                    </template>
                                </div>

                                <div v-if="question._status" class="marginTop20">
                                    <div class="control-label">My Team Has…</div>
                                    <div class="fontBold flexOnly">
                                        <i class="fa fa-check-circle textSuccess marginRight8" aria-hidden="true" />
                                        <template v-if="question._status == 'requested'">
                                            <span> Requested </span>
                                        </template>
                                        <template v-if="question._status == 'voted'">
                                            <span> Voted </span>
                                        </template>
                                    </div>
                                </div>

                                <div v-if="canEditClarifications && question._status" class="marginTop20">
                                    <button class="col-xs-12 btn btn-danger" aria-label="Remove" @click.prevent="removeClarifications(question)">
                                        Remove<i class="fa fa-trash marginLeft8" aria-hidden="true" />
                                    </button>
                                </div>

                                <div v-if="question._type == 'question' && test.clarificationStatus == 'released'" class="marginTop20">
                                    <div class="control-label">Other Team Clarifications</div>
                                    <template v-for="(request, request_idx) in question.otherClarifications">
                                        <div class="marginTop20">
                                            <div class="col-xs-6 buttonGroup">
                                                <button
                                                    class="btn gradient btn-default flex1"
                                                    :aria-label="request.team"
                                                    @click.prevent="openTeamInfoModal(request)"
                                                >
                                                    {{ request.team }}
                                                </button>
                                                <button
                                                    v-if="request.attachments?.length > 0"
                                                    class="btn gradient btn-default flex1"
                                                    aria-label="Team's Attachments"
                                                    @click.prevent="
                                                        openTeamAttachmentsModal({
                                                            name: request.team,
                                                            attachments: request.attachments,
                                                        })
                                                    "
                                                >
                                                    <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                </button>
                                            </div>
                                            <div class="marginTop10">
                                                {{ request.clarifications }}
                                            </div>
                                        </div>
                                    </template>
                                    <template
                                        v-if="!question.otherClarifications || (question.otherClarifications && question.otherClarifications.length == 0)"
                                    >
                                        No Other Clarifications
                                    </template>
                                </div>

                                <div
                                    v-if="question._type == 'freestyle' && test.clarificationStatus == 'released' && !question.isRequestedByMe"
                                    class="marginTop20"
                                >
                                    <div class="control-label">Team Who Requested</div>
                                    <div class="marginTop20">
                                        <div class="col-xs-6 buttonGroup">
                                            <button
                                                class="btn gradient btn-default flex1"
                                                :aria-label="question.requestedBy.name"
                                                @click.prevent="openTeamInfoModal(question.requestedBy)"
                                            >
                                                {{ question.requestedBy.name }}
                                            </button>
                                            <button
                                                v-if="question.attachments?.length > 0"
                                                class="btn gradient btn-default flex1"
                                                aria-label="Team's Attachments"
                                                @click.prevent="
                                                    openTeamAttachmentsModal({
                                                        name: question.requestedBy.name,
                                                        attachments: question.attachments,
                                                    })
                                                "
                                            >
                                                <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                            </button>
                                        </div>
                                        <div class="marginTop10">
                                            {{ question.freestyle }}
                                        </div>
                                    </div>
                                </div>

                                <div v-if="test.clarificationStatus == 'released'" class="marginTop20">
                                    <div class="control-label">Teams Assigned</div>
                                    <div v-for="(team, team_idx) in question.assignedTo" class="marginTop10">
                                        <button
                                            v-if="team.uuid != test.myProgression.team.uuid"
                                            class="btn gradient btn-default"
                                            :aria-label="team.name"
                                            @click.prevent="openTeamInfoModal(team)"
                                        >
                                            <i class="fa fa-users marginRight8" aria-hidden="true" />{{ team.name }}
                                        </button>
                                        <button v-else class="statusTag statusTagLightWarning" :aria-label="team.name" @click.prevent="openTeamInfoModal(team)">
                                            <i class="fa fa-users greyText marginRight8" aria-hidden="true" />{{ team.name }}
                                        </button>
                                    </div>
                                    <template v-if="!(question.assignedTo?.length > 0)"> No Team Assigned </template>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div v-else class="table-responsive">
                        <table class="table">
                            <caption>
                                Clarification Table
                            </caption>
                            <thead>
                                <tr>
                                    <th style="width: 5%" scope="col">Bookmark</th>
                                    <th style="width: 30%" scope="col">Areas That Need Clarification</th>
                                    <th scope="col">Answers Choices In Sequence</th>
                                    <th style="width: 25%" scope="col">We Would Like To Clarify...</th>
                                    <th style="width: 144px" scope="col">My Team Has...</th>
                                    <th v-if="canEditClarifications" style="width: 125px" scope="col">Actions</th>
                                    <th v-if="test.clarificationStatus == 'released'" style="width: 146px" scope="col">Assigned Team</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(question, questionIdx) in clarified_questions" :key="'clarified-questions-' + questionIdx">
                                    <tr>
                                        <td>
                                            <div v-if="question._type == 'question'" class="textAlignCenter">
                                                <i
                                                    v-tooltip="question.studentAnswer.isBookmarked ? 'Is bookmarked' : 'Not bookmark'"
                                                    :class="{
                                                        'fa bookmarked': question.studentAnswer.isBookmarked,
                                                        'fa-regular': !question.studentAnswer.isBookmarked,
                                                    }"
                                                    class="fa-bookmark"
                                                    role="img"
                                                    :aria-label="question.studentAnswer.isBookmarked ? 'Is bookmarked' : 'Not bookmark'"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <template v-if="question._type == 'question'">
                                                <button class="btn gradient btn-default marginBottom20" @click.prevent="openQuestionInfoModal(question)">
                                                    Question
                                                    {{ question.question_no }}<i class="fa fa-info-circle marginLeft8" aria-hidden="true" />
                                                </button>
                                                <h3 class="margin0 fs-18px">Question Stem</h3>
                                                <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML" />
                                            </template>
                                            <template v-else>
                                                <h3 class="margin0 fs-20px">Freestyle</h3>
                                                <div class="alert alert-primary marginTop10">
                                                    <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                                    <p>For freestyle clarification, you may type in any clarifying questions</p>
                                                </div>
                                            </template>
                                        </td>
                                        <td>
                                            <div
                                                v-if="question._type == 'question' && question.studentAnswer?.attempts?.length > 0"
                                                class="statusTag whiteSpaceNowrap"
                                                :class="{
                                                    tagCorrect: question.percent == 100,
                                                    tagPrimary: question.percent >= 50 && question.percent < 100,
                                                    tagWarning: question.percent < 50 && question.percent > 0,
                                                    tagIncorrect: question.percent == 0,
                                                }"
                                            >
                                                <template v-for="(key, idx) in question.studentAnswer.attempts">
                                                    {{ key }}
                                                    <template v-if="idx != question.studentAnswer.attempts.length - 1">&nbsp;</template>
                                                </template>
                                            </div>
                                        </td>
                                        <td>
                                            <template v-if="test.clarificationStatus != 'released'">
                                                <template v-if="question._type == 'question'">
                                                    <div
                                                        v-if="question.studentAnswer?.attachments?.length > 0"
                                                        class="flex align-items marginBottom20 col-xs-12 col-md-12"
                                                    >
                                                        <div class="col-xs-12">
                                                            <label class="control-label">Uploaded Files</label>
                                                            <div v-for="(file, idx) in question.studentAnswer.attachments" class="flexSpaceBetween">
                                                                <div>
                                                                    <span>{{ idx + 1 }}.</span>
                                                                    <span>
                                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                                    </span>
                                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">
                                                                        {{ file.filename }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="question._type == 'freestyle'">
                                                    <div v-if="question.attachments?.length > 0" class="flex align-items marginBottom20 col-xs-12 col-md-12">
                                                        <div class="col-xs-12">
                                                            <label class="control-label">Uploaded Files</label>
                                                            <div v-for="(file, idx) in question.attachments" class="flexSpaceBetween">
                                                                <div>
                                                                    <span>{{ idx + 1 }}.</span>
                                                                    <span>
                                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                                    </span>
                                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">
                                                                        {{ file.filename }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="question._type == 'question'">
                                                    <p v-if="question.studentAnswer.clarifications" class="whiteSpacePreLine marginRight20">
                                                        {{ question.studentAnswer.clarifications }}
                                                    </p>
                                                </template>
                                                <template v-else>
                                                    <p v-if="question.freestyle" class="whiteSpacePreLine marginRight20">{{ question.freestyle }}</p>
                                                </template>
                                                <span v-if="question._status != 'voted' && !(!question._status && !canEditClarifications)">
                                                    <template v-if="canEditClarifications">
                                                        <button
                                                            v-if="question._status || question._type == 'freestyle'"
                                                            class="btn btn-outline-default"
                                                            :aria-label="
                                                                question._type == 'question'
                                                                    ? 'Edit clarification for question ' + question.question_no
                                                                    : question._type == 'freestyle'
                                                                    ? 'Edit freestyle clarification'
                                                                    : ''
                                                            "
                                                            @click.prevent="openRequestModal(question)"
                                                        >
                                                            <i class="fa fa-edit marginRight8" aria-hidden="true" /> Edit Clarification
                                                        </button>
                                                        <button
                                                            v-else
                                                            v-tooltip="question.studentAnswer?.attempts?.length == 0 ? 'You did not submit any answer' : ''"
                                                            class="btn btn-primary"
                                                            :class="{
                                                                'disabled hasToolTip': question.studentAnswer?.attempts?.length == 0,
                                                            }"
                                                            @click.prevent="question.studentAnswer?.attempts?.length == 0 ? '' : openRequestModal(question)"
                                                        >
                                                            Request
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button
                                                            v-if="question._status"
                                                            v-tooltip="
                                                                question._type == 'question'
                                                                    ? 'View question ' + question.question_no + ' calrification'
                                                                    : question._type == 'freestyle'
                                                                    ? 'View freestyle calrification'
                                                                    : ''
                                                            "
                                                            class="btn btn-primary"
                                                            @click.prevent="openRequestModal(question)"
                                                        >
                                                            <i class="fas fa-eye marginRight8" aria-hidden="true" /> View Clarification
                                                        </button>
                                                    </template>
                                                </span>
                                            </template>
                                            <template v-else>
                                                <template v-if="question._type == 'question'">
                                                    <template v-if="question._status == 'requested'">
                                                        <div>
                                                            <div
                                                                class="col-xs-8"
                                                                :class="{
                                                                    buttonGroup: question.studentAnswer?.attachments?.length > 0,
                                                                }"
                                                            >
                                                                <button
                                                                    class="btn gradient btn-default whiteSpaceNowrap"
                                                                    @click.prevent="openTeamInfoModal(test.myProgression.team)"
                                                                >
                                                                    <i class="fa fa-users greyText marginRight8" aria-hidden="true" />
                                                                    {{ test.myProgression.team.name }}
                                                                </button>
                                                                <button
                                                                    v-if="question.studentAnswer?.attachments?.length > 0"
                                                                    class="btn gradient btn-default whiteSpaceNowrap"
                                                                    @click.prevent="
                                                                        openTeamAttachmentsModal({
                                                                            name: test.myProgression.team.name,
                                                                            attachments: question.studentAnswer.attachments,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                                </button>
                                                            </div>
                                                            <div class="marginTop10">
                                                                <p>{{ question.studentAnswer.clarifications }}</p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-for="(request, request_idx) in question.otherClarifications">
                                                        <div class="marginTop20">
                                                            <div
                                                                class="col-xs-8"
                                                                :class="{
                                                                    buttonGroup: request.attachments?.length > 0,
                                                                }"
                                                            >
                                                                <button
                                                                    class="btn gradient btn-default whiteSpaceNowrap"
                                                                    :aria-label="request.team"
                                                                    @click.prevent="openTeamInfoModal(request)"
                                                                >
                                                                    {{ request.team }}
                                                                </button>
                                                                <button
                                                                    v-if="request.attachments?.length > 0"
                                                                    class="btn gradient btn-default whiteSpaceNowrap"
                                                                    aria-label="Team's Attachments"
                                                                    @click.prevent="
                                                                        openTeamAttachmentsModal({
                                                                            name: request.team,
                                                                            attachments: request.attachments,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                                </button>
                                                            </div>
                                                            <div class="marginTop10">
                                                                <p>{{ request.clarifications }}</p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-if="test.clarificationStatus == 'released' && question._type == 'freestyle'">
                                                <div>
                                                    <div
                                                        class="col-xs-8"
                                                        :class="{
                                                            buttonGroup: question.attachments?.length > 0,
                                                        }"
                                                    >
                                                        <button
                                                            class="btn gradient btn-default whiteSpaceNowrap"
                                                            :class="{ self: question.requestedBy.uuid == test.myProgression.team.uuid }"
                                                            @click.prevent="openTeamInfoModal(question.requestedBy)"
                                                        >
                                                            <i class="fa fa-users marginRight8" aria-hidden="true" />
                                                            {{ question.requestedBy.name }}
                                                        </button>

                                                        <button
                                                            v-if="question.attachments?.length > 0"
                                                            class="btn gradient btn-default"
                                                            @click.prevent="
                                                                openTeamAttachmentsModal({
                                                                    name: question.requestedBy.name,
                                                                    attachments: question.attachments,
                                                                })
                                                            "
                                                        >
                                                            <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                        </button>
                                                    </div>

                                                    <div class="marginTop10">
                                                        <p>{{ question.freestyle }}</p>
                                                    </div>
                                                </div>
                                            </template>
                                        </td>
                                        <td>
                                            <div v-if="question._status" class="fontBold flexOnly">
                                                <i class="fa fa-check-circle textSuccess marginRight8" aria-hidden="true" />
                                                <template v-if="question._status == 'requested'">
                                                    <span> Requested </span>
                                                </template>
                                                <template v-if="question._status == 'voted'">
                                                    <span> Voted </span>
                                                </template>
                                            </div>
                                        </td>
                                        <td v-if="canEditClarifications">
                                            <button class="btn btn-danger" @click.prevent="openDeleteClarificationModal(question)">
                                                <i class="fa fa-trash marginRight8" aria-hidden="true" />Remove
                                            </button>
                                        </td>
                                        <td v-if="test.clarificationStatus == 'released'">
                                            <div v-for="(team, team_idx) in question.assignedTo" class="multiselect marginTop10">
                                                <button
                                                    class="btn gradient btn-default"
                                                    :class="{ self: team.uuid == test.myProgression.team.uuid }"
                                                    @click.prevent="openTeamInfoModal(team)"
                                                >
                                                    <i class="fa fa-users marginRight8" aria-hidden="true" />
                                                    {{ team.name }}
                                                </button>
                                                <template v-if="!question.assignedTo || (question.assignedTo && question.assignedTo.length == 0)">
                                                    No Team Assigned
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-if="clarified_questions.length == 0">
                                    <td colspan="6">No Clarified Questions</td>
                                </tr>
                                <tr v-if="canEditClarifications">
                                    <td />
                                    <td />

                                    <td />
                                    <td>
                                        <button class="btn btn-primary" aria-label="New Freestyle" @click.prevent="openRequestModal(add_question)">
                                            <i class="fas fa-plus marginRight8" aria-hidden="true" />New Freestyle
                                        </button>
                                    </td>
                                    <td />
                                    <td />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </kr-panel>
        </main>

        <div id="clarificationRequestModal" class="modal default-modal" tabindex="-1" role="dialog" aria-labelledby="clarificationRequestModal-title">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="clarificationRequestModal-title" class="modal-title capitalize">View {{ crudRequest._type }}</h1>
                    </div>
                    <div class="modal-body" :class="{ flex: !isMobileView }">
                        <div class="col-xs-12 col-md-6" :class="{ paddingRight10: !isMobileView }">
                            <template v-if="crudRequest._type == 'question'">
                                <h2 class="margin0 fs-18px">Question Stem</h2>
                                <question-display :question="crudRequest" :options="{ showCorrectAnswer: false }" />
                            </template>
                            <template v-else>
                                <h2 class="margin0 fs-18px">Freestyle</h2>
                                <div class="alert alert-primary marginTop10">
                                    <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                    <p>
                                        For freestyle clarification, you may type in any clarifying questions OR vote for questions raised by other teams if
                                        your team has a similar question about the test.
                                    </p>
                                </div>
                            </template>
                        </div>
                        <div class="col-xs-12 col-md-6" :class="{ marginTop20: isMobileView }">
                            <template v-if="crudRequest._type == 'question'">
                                <div class="marginBottom15">
                                    <h2 class="margin0 fs-18px">Answers Choices In Sequence</h2>
                                    <div
                                        v-if="crudRequest.studentAnswer?.attempts?.length > 0"
                                        class="statusTag"
                                        :class="{
                                            tagCorrect: crudRequest.percent == 100,
                                            tagPrimary: crudRequest.percent >= 50 && crudRequest.percent < 100,
                                            tagWarning: crudRequest.percent < 50 && crudRequest.percent > 0,
                                            tagIncorrect: crudRequest.percent == 0,
                                        }"
                                    >
                                        <template v-for="(key, idx) in crudRequest.studentAnswer.attempts"> {{ key }}&nbsp; </template>
                                    </div>
                                    <template v-else> - </template>
                                </div>
                            </template>
                            <div class="clarifyPanel">
                                <div v-if="crudRequest._attachments?.length > 0" class="flex align-items marginBottom20 col-xs-12">
                                    <div class="col-xs-12">
                                        <h2 class="margin0 fs-18px">Attachments</h2>
                                        <div v-for="(file, idx) in crudRequest._attachments" class="flexSpaceBetween">
                                            <div>
                                                <span>{{ idx + 1 }}.</span>
                                                <span>
                                                    <i class="fa fa-file-image-o" aria-hidden="true" />
                                                </span>
                                                <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                            </div>
                                            <div>
                                                <span class="important" aria-label="Delete" @click.prevent="deleteAttachment(crudRequest, idx)">
                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clarifyBody">
                                    <label :for="'clarificationField_' + crudRequest.uuid" class="control-label"> Fill in clarification </label>
                                    <textarea
                                        :id="'clarificationField_' + crudRequest.uuid"
                                        v-model="crudRequest._clarifications"
                                        :disabled="!canEditClarifications ? true : undefined"
                                        rows="5"
                                        cols="3"
                                        class="form-control"
                                        placeholder="Type clarification here…"
                                    />
                                </div>
                                <div class="clarifyFooter flexSpaceBetween align-items">
                                    <a v-if="canEditClarifications" href="#" class="link" @click.prevent="addAttachments(crudRequest)">
                                        <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Add Attachments
                                    </a>
                                    <div>
                                        <template v-if="crudRequest.uuid">
                                            <button
                                                class="btn btn-primary"
                                                :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                @click.prevent="updateClarifications(crudRequest)"
                                            >
                                                Submit
                                            </button>
                                            <button
                                                v-if="canEditClarifications"
                                                :disabled="!crudRequest._status ? true : undefined"
                                                class="btn btn-danger"
                                                data-dismiss="modal"
                                                @click.prevent="openDeleteClarificationModal(crudRequest)"
                                            >
                                                <i class="fa fa-trash marginRight8" aria-hidden="true" /> Delete
                                            </button>
                                        </template>

                                        <button
                                            v-else
                                            class="btn btn-primary"
                                            :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                            @click.prevent="addClarifications(crudRequest)"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="clarificationQuestionInfoModal" class="modal default-modal" tabindex="-1" role="dialog" aria-labelledby="clarificationQuestionInfoModal-title">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="clarificationQuestionInfoModal-title" class="modal-title">View Question</h1>
                    </div>
                    <div class="modal-body" :class="{ flex: !isMobileView }">
                        <div class="col-xs-12 col-md-6">
                            <h2 class="margin0 fs-18px">Question Stem</h2>
                            <question-display :question="previewQuestion" :options="{ showCorrectAnswer: false }" />
                        </div>
                        <div v-if="previewQuestion._type == 'question'" class="col-xs-12 col-md-6" :class="{ marginTop20: isMobileView }">
                            <h2 class="margin0 fs-18px">Answers Choice In Sequence</h2>
                            <div
                                v-if="previewQuestion.studentAnswer?.attempts?.length > 0"
                                class="statusTag"
                                :class="{
                                    tagCorrect: previewQuestion.percent == 100,
                                    tagPrimary: previewQuestion.percent >= 50 && previewQuestion.percent < 100,
                                    tagWarning: previewQuestion.percent < 50 && previewQuestion.percent > 0,
                                    tagIncorrect: previewQuestion.percent == 0,
                                }"
                            >
                                <template v-for="(key, idx) in previewQuestion.studentAnswer.attempts">
                                    {{ key }}
                                    <template v-if="idx != previewQuestion.studentAnswer.attempts.length - 1">&nbsp;</template>
                                </template>
                            </div>
                            <template v-else> - </template>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="clarificationTeamInfoModal" class="modal default-modal" tabindex="-1" role="dialog" aria-labelledby="clarificationTeamInfoModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="clarificationTeamInfoModal-title" class="modal-title">View {{ previewTeam.name }}</h1>
                    </div>
                    <div class="modal-body">
                        <ul v-for="(student, idx) in previewTeam.members">
                            <li>{{ student }}</li>
                        </ul>
                        <ul v-for="(student, idx) in previewTeam.students">
                            <li>
                                {{ student.firstname + (student.lastname ? student.lastname : '') }}
                            </li>
                        </ul>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="clarificationTeamAttachmentsModal"
            class="modal default-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clarificationTeamAttachmentsModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="clarificationTeamAttachmentsModal-title" class="modal-title">{{ previewTeam.name }} Attachments</h1>
                    </div>
                    <div class="modal-body">
                        <div class="marginBottom20 col-xs-12 col-md-6">
                            <div v-if="previewTeam.attachments && previewTeam.attachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <label class="control-label">Uploaded Files</label>
                                    <div v-for="(file, idx) in previewTeam.attachments" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.</span>
                                            <span>
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 buttonGroup">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="deleteClarificationModal"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="deleteClarificationModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deleteClarificationModal-title" class="modal-title">Delete Confirmation</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">Removing Clarification</h2>

                        <div class="collapsingQuestion">
                            <button
                                class="btn"
                                :data-target="'#viewQuestion-' + crudRequest.question_no"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewQuestion-' + crudRequest.question_no"
                            >
                                Question
                                {{ crudRequest.question_no }}
                            </button>

                            <div :id="'viewQuestion-' + crudRequest.question_no" class="collapse padding10">
                                <div class="question textAlignLeft">
                                    <kr-math :input="crudRequest.question" :safe="!crudRequest.questionIsHTML" />
                                </div>
                            </div>
                        </div>

                        <p class="marginTop20">Do you wish to continue?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="removeClarifications(crudRequest)">Yes, Remove</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as filestack from 'filestack-js';
import KrAuth from '../../../../../components/auth/auth';
export default {
    data() {
        return {
            auth: new KrAuth(),
            component_done_loading: false,
            client: filestack.init(process.env.VUE_APP_FILESTACK_API_KEY), //filestack client
            test: {},
            display_test_proper: false,
            clarified_questions: [],
            not_clarified_questions: [],
            question: {},
            results: [],
            add_question: {
                _type: 'freestyle',
                _clarifications: '',
                _attachments: [],
            },
            isTeamLeader: false,
            previewQuestion: {},
            previewTeam: {},
            previewRequest: {},
            crudRequest: {},
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            that.test = response.data.data;
            if (['Lapsed', 'Expired'].includes(that.auth.user().status) && that.auth.user().account.paymentMethod == 'Student-Paid') {
                that.$router.push({ name: 'errors.403' });
            }
            that.init();
            that.component_done_loading = true;
            that.processEchoListeners();
            Vue.nextTick(function () {
                if (!_.get(that, 'test.settings.allowTeamClarifications')) {
                    that.$router.push({ name: 'tests.index' });
                }
            });
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                let idx = _.findIndex(that.clarified_questions, function (o) {
                    return o.uuid == e.activityQuestionUuid;
                });
                if (idx != -1) {
                    //update-delete
                    that.clarified_questions[idx].studentAnswer.clarifications = e.answer.clarifications;
                    that.clarified_questions[idx].studentAnswer.attachments = e.answer.attachments;
                    if (!that.clarified_questions[idx].studentAnswer.attachments) {
                        that.clarified_questions[idx].studentAnswer.attachments = [];
                    }
                    that.clarified_questions[idx]._clarifications = e.answer.clarifications;
                    that.clarified_questions[idx]._attachments = _.cloneDeep(that.clarified_questions[idx].studentAnswer.attachments);
                    that.clarified_questions[idx]._edit = false;

                    if (e.answer.clarifications) {
                        that.clarified_questions[idx]._status = 'requested';
                    } else {
                        that.clarified_questions[idx]._status = '';
                    }
                } else {
                    //create
                    let idx = _.findIndex(that.not_clarified_questions, function (o) {
                        return o.uuid == e.activityQuestionUuid;
                    });
                    if (idx != -1) {
                        //should always be the case
                        that.not_clarified_questions[idx].studentAnswer.clarifications = e.answer.clarifications;
                        that.not_clarified_questions[idx].studentAnswer.attachments = e.answer.attachments;
                        if (!that.not_clarified_questions[idx].studentAnswer.attachments) {
                            that.not_clarified_questions[idx].studentAnswer.attachments = [];
                        }
                        that.not_clarified_questions[idx]._clarifications = e.answer.clarifications;
                        that.not_clarified_questions[idx]._attachments = _.cloneDeep(that.not_clarified_questions[idx].studentAnswer.attachments);
                        that.not_clarified_questions[idx]._edit = false;
                        that.not_clarified_questions[idx]._status = 'requested';
                        /*Vue.set(
                            that.not_clarified_questions[idx],
                            '_clarifications',
                            e.answer.clarifications
                        );
                        Vue.set(
                            that.not_clarified_questions[idx],
                            '_attachments',
                            _.cloneDeep(
                                that.not_clarified_questions[idx].studentAnswer
                                    .attachments
                            )
                        );
                        Vue.set(
                            that.not_clarified_questions[idx],
                            '_edit',
                            false
                        );
                        Vue.set(
                            that.not_clarified_questions[idx],
                            '_status',
                            'requested'
                        );*/
                        that.not_clarified_questions[idx].isRequestedByMe = true;
                        let removed = that.not_clarified_questions.splice(idx, 1)[0];
                        that.clarified_questions.push(removed);
                    } else {
                        //console.log('created new clarification error');
                    }
                }
            };
            //Store Answer?
            let c1 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.myProgression.team.uuid}`).listen('ClarificationSaved', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'ClarificationSaved',
                handler: h1,
            });

            let h2 = (e) => {
                this.test.clarificationStatus = e.clarificationStatus;
                if (this.test.clarificationStatus == 'released') {
                    //alert('init results!');
                    var that = this;
                    this.fetchTest().then(function (response) {
                        that.test = response.data.data;
                        that.init();
                    });
                }
            };
            let c2 = window.Echo.private(`test.${this.test.testUuid}.student`).listen('TestClarificationStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'TestClarificationStatusUpdated',
                handler: h2,
            });

            let h3 = (e) => {
                let isRequestedByMe = e.freestyle.requestedBy.uuid == this.test.myProgression.team.uuid;
                if (isRequestedByMe) {
                    let idx = _.findIndex(this.clarified_questions, function (o) {
                        return o.uuid == e.freestyle.uuid;
                    });
                    if (idx != -1) {
                        this.clarified_questions[idx].freestyle = e.freestyle.freestyle;
                        this.clarified_questions[idx].attachments = e.freestyle.attachments;
                        if (!this.clarified_questions[idx].attachments) {
                            this.clarified_questions[idx].attachments = [];
                        }
                        this.clarified_questions[idx].votedBy = e.freestyle.votedBy ? e.freestyle.votedBy : [];
                        this.clarified_questions[idx]._clarifications = e.freestyle.freestyle;
                        this.clarified_questions[idx]._attachments = _.cloneDeep(this.clarified_questions[idx].attachments);
                        this.clarified_questions[idx]._edit = false;

                        if (!this.clarified_questions[idx].votedBy.includes(this.test.myProgression.team.uuid)) {
                            this.clarified_questions[idx].isVotedByMe = false;
                            this.clarified_questions[idx]._status = 'requested';
                            //delete this.clarified_questions[idx]._status;
                            //this.clarified_questions.splice(idx, 1);
                        } else {
                            this.clarified_questions[idx]._status = 'voted';
                            this.clarified_questions[idx].isVotedByMe = true;
                        }
                    } else {
                        let temp_idx = _.findIndex(this.test.clarifications.freestyles, function (o) {
                            return o.uuid == e.freestyle.uuid;
                        });
                        if (temp_idx != -1) {
                            this.test.clarifications.freestyles[temp_idx].freestyle = e.freestyle.freestyle;
                            this.test.clarifications.freestyles[temp_idx].attachments = e.freestyle.attachments;
                            if (!this.test.clarifications.freestyles[temp_idx].attachments) {
                                this.test.clarifications.freestyles[temp_idx].attachments = [];
                            }
                            this.test.clarifications.freestyles[temp_idx].votedBy = e.freestyle.votedBy ? e.freestyle.votedBy : [];
                            this.test.clarifications.freestyles[temp_idx]._clarifications = e.freestyle.freestyle;
                            this.test.clarifications.freestyles[temp_idx]._attachments = _.cloneDeep(this.test.clarifications.freestyles[temp_idx].attachments);
                            this.test.clarifications.freestyles[temp_idx]._edit = false;
                            if (!this.test.clarifications.freestyles[temp_idx].votedBy.includes(this.test.myProgression.team.uuid)) {
                                this.test.clarifications.freestyles[temp_idx].isVotedByMe = false;
                                this.test.clarifications.freestyles[temp_idx]._status = '';
                            } else {
                                this.test.clarifications.freestyles[temp_idx].isVotedByMe = true;
                                this.test.clarifications.freestyles[temp_idx]._status = 'voted';
                                this.clarified_questions.push(this.test.clarifications.freestyles[temp_idx]);
                            }
                        } else {
                            let clarification = {
                                uuid: e.freestyle.uuid,
                                attachments: e.freestyle.attachments ? e.freestyle.attachments : [],
                                freestyle: e.freestyle.freestyle,
                                _clarifications: e.freestyle.freestyle,
                                _edit: false,
                                _type: 'freestyle',
                                _status: '',
                                isRequestedByMe: true,
                                isVotedByMe: false,
                                isAssignedToMe: false,
                                assignedTo: e.freestyle.assignedTo ? e.freestyle.assignedTo : [],
                                requestedBy: e.freestyle.requestedBy,
                                votedBy: e.freestyle.votedBy ? e.freestyle.votedBy : [],
                            };
                            //this.clarified_questions.push(clarification);
                            this.test.clarifications.freestyles.push(clarification);
                            this.init();
                            //console.log(this.test.clarifications.freestyles);
                            //console.log(this.test.clarifications.freestyles);
                        }
                    }
                }
            };
            let c3 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.myProgression.team.uuid}`).listen('ClarificationFreestyleSaved', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ClarificationFreestyleSaved',
                handler: h3,
            });

            let h4 = (e) => {
                let idx = _.findIndex(this.test.clarifications.freestyles, function (o) {
                    return o.uuid == e.freestyle.uuid;
                });
                if (idx != -1) {
                    this.test.clarifications.freestyles.splice(idx, 1);
                }
                idx = _.findIndex(this.clarified_questions, function (o) {
                    return o.uuid == e.freestyle.uuid;
                });
                if (idx != -1) {
                    this.clarified_questions.splice(idx, 1);
                }
            };
            let c4 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.myProgression.team.uuid}`).listen('ClarificationFreestyleDeleted', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'ClarificationFreestyleDeleted',
                handler: h4,
            });
            let h5 = (e) => {
                if (e.test.uuid != this.test.testUuid) {
                    return false;
                }
                that.test.enableAnonymityInPresentationMode = e.test.enableAnonymityInPresentationMode;
                this.fetchTest().then(function (response) {
                    that.test = response.data.data;
                });
            };
            let c5 = window.Echo.private(`activity.${this.test.activityUuid}.student`).listen('TestVisibilityUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'TestVisibilityUpdated',
                handler: h5,
            });
        },
        canSubmitClarification(question) {
            if (question.uuid) {
                if (question._type == 'question') {
                    if (
                        question.studentAnswer.clarifications != question._clarifications ||
                        !_.isEqual(question.studentAnswer.attachments, question._attachments)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (question._type == 'freestyle') {
                    if (question.freestyle != question._clarifications || !_.isEqual(question.attachments, question._attachments)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                if (question._type == 'question') {
                    if (question._clarifications != '') {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (question._type == 'freestyle') {
                    if (question._clarifications != '') {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        openDeleteClarificationModal(question = null) {
            this.crudRequest = question;
            $('#deleteClarificationModal').modal('show');
        },
        openRequestModal(question = null) {
            this.crudRequest = question;
            $('#clarificationRequestModal').modal('show');
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#clarificationQuestionInfoModal').modal('show');
        },
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#clarificationTeamInfoModal').modal('show');

            $('#clarificationTeamInfoModal').on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openTeamAttachmentsModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#clarificationTeamAttachmentsModal').modal('show');
            $('#clarificationTeamAttachmentsModal').on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        processIsTeamLeader(isTeamLeader) {
            this.isTeamLeader = isTeamLeader;
        },
        isClarificationVoted(freestyle) {
            if (freestyle.isVotedByMe) {
                return true;
            }
            return false;
        },
        addAttachments(question) {
            this.client
                .picker({
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.png',
                        '.csv',
                        '.doc',
                        '.docx',
                        '.dot',
                        '.dotx',
                        '.key',
                        '.numbers',
                        '.odt',
                        '.odp',
                        '.ods',
                        '.pages',
                        '.pdf',
                        '.ppt',
                        '.pot',
                        '.potx',
                        '.pps',
                        '.ppsx',
                        '.pub',
                        '.rtf',
                        '.sldx',
                        '.txt',
                        '.xls',
                        '.xlt',
                        '.xlsx',
                        '.mp4',
                        '.mp3',
                    ],
                    maxFiles: 20,
                    uploadInBackground: false,
                    onUploadStarted: function (file) {
                        //that.is_uploading = true;
                    },
                    onUploadDone: function (res) {
                        //that.is_uploading = false;
                        if (!question._attachments) {
                            question._attachments = [];
                        }
                        let currentLength = question._attachments.length;
                        for (var i = 0; i < res.filesUploaded.length; i++) {
                            _.set(question._attachments, currentLength++, res.filesUploaded[i]);
                        }
                        question._attachments.splice();
                    },
                })
                .open();
        },
        deleteAttachment(question, idx) {
            question._attachments.splice(idx, 1);
        },
        init() {
            this.clarified_questions = [];
            this.not_clarified_questions = [];
            let count = 0;
            for (var i = 0; i < this.test.clarifications.questions.length; i++) {
                for (var j = 0; j < this.test.clarifications.questions[i].length; j++) {
                    this.test.clarifications.questions[i][j].question_no = ++count;
                    let clarification = _.get(this.test.clarifications.questions, i + '.' + j + '.studentAnswer.clarifications', null);
                    let attachments = _.get(this.test.clarifications.questions, i + '.' + j + '.studentAnswer.attachments', null);
                    if (!attachments) {
                        attachments = [];
                    }
                    this.test.clarifications.questions[i][j]._clarifications = clarification;
                    this.test.clarifications.questions[i][j]._attachments = _.cloneDeep(attachments);
                    this.test.clarifications.questions[i][j].studentAnswer.attachments = attachments;
                    this.test.clarifications.questions[i][j]._edit = false;
                    this.test.clarifications.questions[i][j]._type = 'question';
                    if (clarification) {
                        this.test.clarifications.questions[i][j]._status = 'requested';
                    } else {
                        this.test.clarifications.questions[i][j]._status = '';
                    }
                    this.clarified_questions.push(_.clone(this.test.clarifications.questions[i][j]));
                    /*if(clarification){
                			this.test.clarifications.questions[i][j]._clarifications = clarification;
                			this.test.clarifications.questions[i][j]._attachments = _.cloneDeep(attachments);
                			this.test.clarifications.questions[i][j].studentAnswer.attachments = attachments;
                			this.test.clarifications.questions[i][j]._edit = false;
          					this.test.clarifications.questions[i][j]._type = 'question';
          					this.test.clarifications.questions[i][j]._status = 'requested';
                			this.clarified_questions.push(_.clone(this.test.clarifications.questions[i][j]));
                		}else{
                			this.test.clarifications.questions[i][j]._type = 'question';
                			this.not_clarified_questions.push(_.clone(this.test.clarifications.questions[i][j]));
                		}*/
                }
            }
            for (var i = 0; i < this.test.clarifications.freestyles.length; i++) {
                let freestyle = this.test.clarifications.freestyles[i];
                let attachments = _.get(this.test.clarifications.freestyles, i + '.attachments', null);
                if (!attachments) {
                    attachments = [];
                }
                if (freestyle.isRequestedByMe || (this.test.clarificationStatus == 'released' && freestyle.assignedTo && freestyle.assignedTo.length > 0)) {
                    freestyle._type = 'freestyle';
                    if (freestyle.isRequestedByMe) {
                        freestyle._status = 'requested';
                    }
                    freestyle._edit = false;
                    freestyle._clarifications = freestyle.freestyle;
                    freestyle.attachments = attachments;
                    freestyle._attachments = _.cloneDeep(attachments);
                    this.clarified_questions.push(_.clone(freestyle));
                } else {
                    if (this.isClarificationVoted(freestyle)) {
                        freestyle._type = 'freestyle';
                        freestyle._status = 'voted';
                        freestyle._edit = false;
                        freestyle._clarifications = freestyle.freestyle;
                        freestyle.attachments = attachments;
                        freestyle._attachments = _.cloneDeep(attachments);
                        this.clarified_questions.push(_.clone(freestyle));
                    }
                }
            }
        },
        /*initResults(){
				this.results = [];
				for(var i = 0; i<this.test.results.questions.length;i++){
					let clarification = this.test.results.questions[i];
					clarification._type = 'question';
					this.results.push(_.clone(clarification));
				}
				for(var i = 0; i<this.test.results.freestyles.length;i++){
					let clarification = this.test.results.freestyles[i];
					clarification._type = 'freestyle';
					this.results.push(_.clone(clarification));
				}
			},*/
        //freestyle clarifications only
        toggleVoteClarifications(clarification) {
            var that = this;
            axios.patch('student/tests/' + this.test.uuid + '/freestyles/' + clarification.uuid + '/vote').then(function (response) {
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have successfully toggled voting for this freestyle clarification',
                });
                //Events.fire('changed-test',response.data.data);
                that.clearAddClarificationsForm();
            });
        },
        clearAddClarificationsForm() {
            this.add_question._clarifications = '';
            this.add_question._attachments = [];
            this.add_question._type = 'freestyle';
        },
        addClarifications(question) {
            var that = this;
            if (question.type == 'question') {
                axios
                    .post('/student/tests/' + this.test.uuid + '/clarifications', {
                        userPlacementTestUuid: this.test.uuid,
                        activityQuestionUuid: question.question.uuid,
                        clarifications: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully added clarifications for this question',
                        });
                        //Events.fire('changed-test',response.data.data);
                        $('#clarificationRequestModal').modal('hide');
                        that.clearAddClarificationsForm();
                    });
            } else {
                //freestyle
                axios
                    .post('/student/tests/' + this.test.uuid + '/freestyles', {
                        freestyle: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully added a freestyle clarification',
                        });
                        //Events.fire('changed-test',response.data.data);
                        $('#clarificationRequestModal').modal('hide');
                        that.clearAddClarificationsForm();
                    });
            }
        },
        updateClarifications(question) {
            var that = this;
            if (question._type == 'question') {
                axios
                    .post('/student/tests/' + this.test.uuid + '/clarifications', {
                        userPlacementTestUuid: this.test.uuid,
                        activityQuestionUuid: question.uuid,
                        clarifications: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        question._edit = false;
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully updated clarifications for this question',
                        });
                        //Events.fire('changed-test',response.data.data);
                    });
            } else {
                axios
                    .patch('/student/tests/' + this.test.uuid + '/freestyles/' + question.uuid, {
                        freestyle: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        question._edit = false;
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully updated a freestyle clarification',
                        });
                        // Events.fire('changed-test',response.data.data);
                        that.clearAddClarificationsForm();
                    });
            }
        },
        removeClarifications(question) {
            var that = this;
            if (question._type == 'question') {
                axios.delete('/student/tests/' + this.test.uuid + '/clarifications/' + question.studentAnswer.uuid).then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'You have successfully deleted a question clarification',
                    });
                    //Events.fire('changed-test',response.data.data);
                    that.clearAddClarificationsForm();
                });
            } else {
                if (question._status == 'requested') {
                    axios.delete('/student/tests/' + this.test.uuid + '/freestyles/' + question.uuid).then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully deleted a freestyle clarification',
                        });
                        //Events.fire('changed-test',response.data.data);
                        that.clearAddClarificationsForm();
                    });
                } else {
                    this.toggleVoteClarifications(question);
                }
            }
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/clarifications');
        },
    },
    components: {
        'test-topbar': require(`./../partials/topbar.vue`).default,
        'team-leadership': require(`./../partials/team-leadership.vue`).default,
        'question-groups': require(`./../partials/questions/question-groups.vue`).default,
        'question-pagination': require(`./../partials/question-pagination.vue`).default,
        'question-display': require(`./../../../questions/partials/question-display.vue`).default,
    },
    computed: {
        canEditClarifications() {
            if (['ongoing'].includes(this.test.clarificationStatus) && this.isTeamLeader) {
                return true;
            }
            return false;
        },
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        Events.off('current-question-changed');
    },
};
</script>

<style scoped>
.optionBarsDanger {
    padding: 10px;
    border-radius: 3px;
    background-color: #ad2224;
    color: #fff;
}

.clarification .dropdown-toggle,
.clarification .dropdown-menu {
    width: 100%;
}

.clarification .dropdown-menu {
    padding: 0;
    margin-top: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    padding-top: 15px;
}

.clarification .dropdown-menu .footer .btn {
    margin-top: 0;
}

.clarification .dropdown-menu .footer {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border-top: 1px solid #d8d8d8;
    padding: 10px;
}

.clarification .dropdown-menu select {
    width: 100%;
    border: none;
    padding: 15px 15px 5px;
}

.clarification .dropdown-menu li span:first-child {
    font-size: 14px;
    font-weight: bold;
}

.clarification .dropdown-menu li.selected,
.clarification .dropdown-menu li:hover {
    background-image: linear-gradient(to bottom, #f1f4f8, #f5f7f9);
}

.clarification .dropdown-menu li.selected a,
.clarification .dropdown-menu li:hover a {
    color: #0071be;
}

.clarification .dropdown-menu li span:last-child {
    font-size: 12px;
}

.clarification ul {
    margin: 0;
    padding: 0 0 10px;
    max-height: 100px;
    overflow: auto;
    list-style-type: none;
}

.clarification ul a {
    color: #222;
}

.clarification ul li {
    padding: 5px 15px;
    white-space: nowrap;
    overflow: hidden;
}

.clarification-panel {
    border: 1px solid #ededed;
    border-radius: 3px;
}

.clarificationPanel {
    margin-top: 20px;
}

.clarificationPanel:first-child {
    margin-top: 0;
}

.clarification-panel .header {
    padding: 10px 20px;
    background-color: #495060;
    line-height: 1;
    color: #fff;
}

.clarification-panel .header span:first-child {
    font-size: 30px;
    font-weight: bold;
}

.clarification-panel .body {
    height: 173px;
    background-color: white;
    padding: 15px !important;
}

.clarification-panel .body .content {
    height: 92px;
    display: block;
    overflow: hidden;
    overflow-y: auto;
    white-space: pre-line;
    word-wrap: break-word;
}
.clarification-panel .body .content p {
    margin: 0;
    white-space: pre-line;
    word-wrap: break-word;
}
.clarification-panel .body .footer {
    height: 56px;
    padding-top: 15px;
}

.clarification .dropdown a:hover {
    background: none;
}

.clarifyBody textarea {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.clarifyFooter {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #d8d8d8;
    padding: 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.multiselect .col-xs-12.btn {
    margin-top: 15px;
}

.multiselect .col-xs-12.btn:first-child {
    margin-top: 0;
}

@media (min-width: 992px) {
    .clarificationPanel:nth-child(-n + 2) {
        margin-top: 0;
    }

    .clarificationPanel:nth-child(even) {
        padding-left: 10px;
    }

    .clarificationPanel:nth-child(odd) {
        padding-right: 10px;
    }
}

body.test .clarification .panel-body {
    height: auto;
    /* max-height: 364px; */
}

#deleteClarificationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#deleteClarificationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#deleteClarificationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>
