
.static-label-toggle {
    line-height: 10px;
    margin-left: 20px;
}
.optionDiv {
    margin-bottom: 15px;
}

.expectation-paragraph {
    border: 4px solid #0071be;
    max-width: 940px;
}
