<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="links">
            <ul>
                <li class="active">
                    <a class="nav-link" data-toggle="tab" href="#teacher" aria-label="Super admin Details">
                        {{ $t('modules.accounts.superadmins.view.superAdminDetailsTabLabel') }}
                    </a>
                </li>
                <li>
                    <a class="nav-link" data-toggle="tab" href="#courses" aria-label="Courses">{{ $t('modules.accounts.superadmins.view.coursesTabLabel') }}</a>
                </li>
            </ul>
        </div>
        <div class="row tab-content">
            <div id="teacher" class="tab-pane fade in active">
                <kr-panel :with-footer="false">
                    <template #title>
                        <template v-if="isProfile">
                            <div class="col-xs-6">My Profile</div>
                        </template>
                        <template v-else>
                            <div class="col-xs-6">
                                {{ teacher.displayName }}
                                <p>{{ teacher.email }}</p>
                            </div>
                        </template>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs hidden-sm">
                                <router-link
                                    v-if="isProfile"
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'profile.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button
                                        v-tooltip="auth.isImpersonation() ? 'Impersonation cannot edit profile' : ''"
                                        class="btn btn-default"
                                        :class="{ 'disabled hasTooltip': auth.isImpersonation() }"
                                        :aria-disabled="auth.isImpersonation()"
                                        @click="auth.isImpersonation() ? '' : navigate()"
                                    >
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" /> Edit
                                    </button>
                                </router-link>

                                <router-link
                                    v-else
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.superadmins.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button
                                        v-tooltip="auth.isImpersonation() ? 'Impersonation cannot edit profile' : ''"
                                        class="btn btn-default"
                                        :class="{ 'disabled hasTooltip': auth.isImpersonation() }"
                                        :aria-disabled="auth.isImpersonation()"
                                        @click="auth.isImpersonation() ? '' : navigate()"
                                    >
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" /> Edit
                                    </button>
                                </router-link>

                                <button
                                    v-if="!teacher.isSuspended && !isProfile"
                                    class="btn btn-default"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal()"
                                >
                                    <i class="fa fa-key marginRight8" aria-hidden="true"></i
                                    >{{ $t('modules.accounts.superadmins.view.resetPasswordButtonLabel') }}
                                </button>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-if="isProfile"
                                    v-slot="{ navigate }"
                                    v-tooltip="{ content: 'Edit' }"
                                    :to="{
                                        name: 'profile.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                </router-link>

                                <router-link
                                    v-else
                                    v-slot="{ navigate }"
                                    v-tooltip="{ content: 'Edit' }"
                                    :to="{
                                        name: 'accounts.superadmins.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                </router-link>

                                <button
                                    v-if="!teacher.isSuspended && !isProfile"
                                    v-tooltip="{ content: 'Reset Password' }"
                                    class="btn btn-default"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal()"
                                >
                                    <i class="fa fa-key" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div class="row flex col-xs-12">
                            <div class="profileImg">
                                <div>
                                    <template v-if="teacher.avatar">
                                        <div class="actual-image" :style="'background-image:url(' + teacher.avatar + ');'"></div>
                                    </template>
                                    <template v-else>
                                        <div class="profile_pic">
                                            <div
                                                class="badge_profile"
                                                style="width: 100px; line-height: 100px; height: 100px; background: #2a3f54; color: white"
                                            >
                                                <span> {{ initials }} </span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6 viewFullDetails">
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.roleLabel') }}</label>
                                            <p class="form-control-static">Super Admin</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.expressSignInLabel') }}</label>
                                            <p class="form-control-static">
                                                {{ teacher.canUseExpressSignIn ? 'YES' : 'NO' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.fullnameLabel') }}</label>
                                            <p class="form-control-static">
                                                {{ teacher.displayName }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.emailLabel') }}</label>
                                            <p class="form-control-static">
                                                {{ teacher.email }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.statusLabel') }}</label>
                                            <p class="form-control-static align-items">
                                                <template v-if="!teacher.isSuspended">
                                                    <template v-if="teacher.dateActivated">
                                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Active
                                                    </template>
                                                    <template v-else-if="!teacher.dateActivated">
                                                        <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true"></i>Invite Sent
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.activatedOnLabel') }}</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateActivated">
                                                    {{ convertToReadableDate(teacher.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.createdByLabel') }}</label>
                                            <p class="form-control-static">
                                                {{ teacher.createdBy }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.createdOnLabel') }}</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateCreated">
                                                    {{ convertToReadableDate(teacher.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{
                                                $t('modules.accounts.superadmins.view.accountLastLoginLabel')
                                            }}</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateLastLogin">
                                                    {{ convertToReadableDate(teacher.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{
                                                $t('modules.accounts.superadmins.view.accountLastModifiedLabel')
                                            }}</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.lastModified">
                                                    {{ convertToReadableDate(teacher.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="teacher.isSuspended" class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">{{ $t('modules.accounts.superadmins.view.suspendedOnLabel') }}</label>
                                            <p class="form-control-static">
                                                {{ convertToReadableDate(teacher.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(teacher.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
                <div v-if="(auth.user().roleId < 3 && !isProfile) || (auth.user().canAddTeacher && teacher.roleId == 3 && !isProfile)" class="flexRight">
                    <div class="dropup col-xs-2">
                        <button
                            id="viewDropdownMenuButton"
                            class="width100 flexSpaceBetween align-items"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ $t('modules.accounts.superadmins.view.actionsDropdownLabel') }}
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewModuleDropdownMenuButton">
                            <ul>
                                <li v-if="!teacher.isSuspended">
                                    <a
                                        v-tooltip="'Suspend Teacher'"
                                        class="danger-state"
                                        href="#"
                                        aria-label="Suspend Teacher"
                                        @click.prevent="openSuspendModal()"
                                    >
                                        <span>
                                            <i class="fa fa-archive marginRight8" aria-hidden="true"></i
                                            >{{ $t('modules.accounts.superadmins.view.suspendTeacherButtonLabel') }}
                                        </span>
                                    </a>
                                </li>

                                <li v-else>
                                    <a v-tooltip="'Reinstate'" class="primary-state" href="#" aria-label="Reinstate" @click.prevent="openSuspendModal()">
                                        <span>
                                            <i class="fa fa-refresh marginRight8" aria-hidden="true"></i
                                            >{{ $t('modules.accounts.superadmins.view.reinstateTeacherButtonLabel') }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="courses" class="tab-pane fade in">
                <div class="row col-xs-12">
                    <div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th colspan="12">
                                            Course that
                                            {{ teacher.displayName }} is in ({{ teacher.courses.length }})
                                        </th>
                                    </tr>
                                    <tr v-if="teacher.courses.length != 0">
                                        <th>{{ $t('modules.accounts.superadmins.view.table.courseLabel') }}</th>
                                        <th>{{ $t('modules.accounts.superadmins.view.table.codeLabel') }}</th>
                                        <th>{{ $t('modules.accounts.superadmins.view.table.courseAccessLabel') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="teacher.courses.length == 0">
                                        <td>{{ teacher.displayName }} is not associated with any courses yet</td>
                                    </tr>
                                    <tr v-for="course in teacher.courses" v-else>
                                        <td>
                                            {{ course.name }}
                                        </td>
                                        <td>
                                            {{ course.code }}
                                        </td>
                                        <td>
                                            <template v-if="course.accountType == 'Owner'">
                                                <i class="fa fa-circle ownerCircle marginRight8" aria-hidden="true"></i>Owner
                                            </template>
                                            <template v-if="course.accountType == 'Full Access'">
                                                <i class="fa fa-circle fullAccessCircle marginRight8" aria-hidden="true"></i>Full Access
                                            </template>
                                            <template v-if="course.accountType == 'Read Only'">
                                                <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true"></i>Read Only
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="false" id="log" class="tab-pane fade in">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">ACTIVITY LOG</div>
                        <div class="col-xs-6 flexRight">
                            <div class="col-xs-2 buttonGroup">
                                <div class="hidden-xs hidden-sm">
                                    <router-link v-if="!isFromAccount" v-slot="{ navigate }" :to="{ name: 'teachers.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                        </button>
                                    </router-link>

                                    <router-link
                                        v-else
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'accounts.view',
                                            params: { id: teacher.accountUuid },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                        </button>
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link
                                        v-if="!isFromAccount"
                                        v-slot="{ navigate }"
                                        v-tooltip="{ content: 'Back' }"
                                        :to="{ name: 'teachers.index' }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left" aria-hidden="true"></i>
                                        </button>
                                    </router-link>

                                    <router-link
                                        v-else
                                        v-slot="{ navigate }"
                                        v-tooltip="{ content: 'Back' }"
                                        :to="{
                                            name: 'accounts.view',
                                            params: { id: teacher.accountUuid },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <kr-search
                            name="log_listing"
                            :url="{ url: '/users/activity-log/index' }"
                            :columns="{
                                user: 'display:NAME|sortable:false|width:35%|mobile:hide',
                                event: 'display:ACTION|sortable:false|width:30%',
                                dateCreated: 'display:TIMESTAMP|sortable:false|width:45%',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: false,
                                query_fields: query_fields,
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #lower-left>
                                <div class="form-group periodBars">
                                    <label class="col-form-label">VIEW PERIOD</label>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="start_log_daterange"
                                            placeholder="Select start period"
                                            aria-label="Select Start Period"
                                        />
                                    </div>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="end_log_daterange"
                                            placeholder="Select end period"
                                            aria-label="Select End Period"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template #table-title="props">
                                <th colspan="6">All Logs ( {{ props.model.from }}-{{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>
                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }}-{{ props.model.to }}
                                    of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>
                            <template #cdata="props">
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.user }}
                                </td>
                                <td>
                                    {{ props.model.event }}
                                </td>
                                <td>
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>

        <div
            id="suspendModal"
            class="modal"
            :class="{ 'danger-modal': !teacher.isSuspended, 'warning-modal': teacher.isSuspended }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="!teacher.isSuspended"> Suspend Teacher </template>
                            <template v-else> Reinstate Teacher </template>
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">
                            <template v-if="!teacher.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h3 class="marginBottom20 fw-normal capitalize">
                            {{ teacher.displayName }}
                        </h3>

                        <template v-if="!teacher.isSuspended">
                            <p class="marginTop20">Suspending this teacher will prevent them</p>

                            <p>from sign in to the system.</p>

                            <p class="marginTop20"><b>Note!</b> they will not receive any</p>

                            <p>notification regarding this action.</p>

                            <p class="marginTop20">
                                <b>Are you sure to suspend the teacher?</b>
                            </p>
                        </template>

                        <template v-else>
                            <p class="marginTop20">Reinstating this teacher will restore their</p>

                            <p>ability to sign in to the system.</p>

                            <p class="marginTop20"><b>Note!</b> they will not receive any</p>

                            <p>notification regarding this action.</p>

                            <p class="marginTop20">
                                <b>Are you sure to reinstate the teacher?</b>
                            </p>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button v-if="!teacher.isSuspended" class="btn btn-danger" data-dismiss="modal" @click.prevent="suspend()">
                            <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Suspend
                        </button>
                        <button v-else class="btn btn-success" data-dismiss="modal" @click.prevent="unsuspend()">Yes, Reinstate</button>
                    </div>
                </div>
            </div>
        </div>
        <kr-reset-password></kr-reset-password>
    </div>
</template>

<script>
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            teacher: null,
            auth: auth,
            query_fields: { dateFrom: '', dateTo: '' },
            initials: '',
        };
    },
    computed: {
        isProfile() {
            return this.$route.params.id == this.auth.user().uuid;
        },
    },
    created() {
        if (this.isProfile) {
            Events.fire('topbar_update', {
                breadcrumb: [{ name: 'My Profile' }],
            });
        }
        this.fetch();
        document.title = `${this.isProfile ? 'My Profile | InteDashboard | TBL Makes Teams Work' : 'Teachers Profile | InteDashboard | TBL Makes Teams Work'}`;
    },
    methods: {
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        suspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                this.teacher.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been suspended',
                });
            }.bind(this);
            var del = function () {
                this.teacher.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .put('/users/' + this.teacher.uuid, {
                    accountUuid: this.teacher.account.uuid,
                    isSuspended: true,
                })
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                var that = this;
                this.teacher.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.teacher.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .put('/users/' + this.teacher.uuid, {
                    accountUuid: this.teacher.account.uuid,
                    isSuspended: false,
                })
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        search() {
            Events.fire('log_listing_refresh');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        openResetPasswordModal() {
            Events.fire('open-reset-password-modal', {
                user: this.teacher,
                organisation: this.teacher.account.organisationName,
            });
        },
        fetch() {
            let that = this;
            let route = '/users';
            axios.get(route + '/' + that.$route.params.id).then(function (response) {
                that.teacher = response.data.data;
                that.initials =
                    (that.teacher.firstname ? that.teacher.firstname.charAt(0) : '') + (that.teacher.lastname ? that.teacher.lastname.charAt(0) : '');
                if (that.isProfile) {
                } else {
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: that.$t('modules.accounts.view.breadcrumbLabel'), link: 'accounts.view', params: { id: that.teacher.account.uuid } },
                            { name: that.teacher.displayName },
                        ],
                    });
                }
                that.component_done_loading = true;
            });
        },
    },
};
</script>
<style scoped>
@media (min-width: 992px) {
    .periodBars {
        width: 524px;
    }
}
</style>
<style lang="scss">
.viewFullDetails {
    .col-xs-12.flex:last-child {
        .col-xs-12.col-md-6:last-child p {
            margin-bottom: 0;
        }
    }
}
</style>
