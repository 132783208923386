.pagination {
    margin: 0;

    & > li {
        & > a,
        & > span {
            padding: 12px 0;
            line-height: 1;
            border: 1px solid #d8d8d8;
            color: #222;
            line-height: 0.8;
            width: 36px;
            height: 36px;
            text-align: center;
        }

        &:first-child {
            & > a,
            & > span {
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }

        &:last-child {
            & > a,
            & > span {
                border-bottom-right-radius: 3px;
                border-top-right-radius: 3px;
            }
        }

        &.active {
            a,
            a:hover,
            a:focus,
            span,
            span:hover,
            span:focus {
                background: #f6f8fa;
                color: #717171;
                font-weight: bold;
                border-left: 1px solid #989b9c;
                border-right: 1px solid #989b9c;
                border-top: 2px solid #989b9c;
                border-bottom: 2px solid #989b9c;
                line-height: 0.7;
            }

            a.disabled {
                opacity: 1;
            }

            &.first > a {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-left: 2px solid #989b9c;
            }

            &.last > a {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-right: 2px solid #989b9c;
            }
        }
    }
}

.newPagination {
    a,
    button {
        padding: 12px 16.45px;
        height: auto;
        width: auto;
        line-height: 1;
        border: none;
        background: none;
        color: #0071be;

        &.padding5 {
            padding: 5px;
        }

        &:hover,
        &:focus-visible {
            line-height: 1;
            color: #0000ff;
            font-weight: bold;
            text-decoration: underline;
            background: #e7dcf9;
        }

        &:active {
            color: #ff00ff;
            background: none;
        }

        &.disabled {
            color: #6c6c6c;
            pointer-events: inherit;
        }
    }

    ul {
        padding-left: 0;
        margin: 0;

        li {
            &.first.last {
                a {
                    border-radius: 8px;
                }
            }

            list-style: none;

            a {
                border-radius: 8px;
            }
        }
    }

    .active {
        a {
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.24);
            background: #fff;
            border: 1px solid #222;

            &.disabled {
                color: #3c3c43;
            }
        }

        &.first {
            a {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.last {
            a {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
}

.night-mode {
    .newPagination {
        a,
        button {
            color: #fff;

            &:hover,
            &:focus-visible {
                color: #0000ff;
                background: #e7dcf9;
            }

            &:active {
                color: #ffcaff;
            }

            &.disabled {
                color: #babac1;
                pointer-events: inherit;
            }
        }

        .active {
            a {
                box-shadow: 4px 4px 8px rgba(255, 255, 255, 0.24);
                background: #36445c;
                border: 1px solid #ebd9f9;

                &:hover,
                &:focus-visible {
                    color: #7ec2ef;
                }

                &.disabled {
                    color: #babac1;
                }
            }
        }
    }
}
