/*Default*/
.vue-notification {
    padding: 10px;
    margin: 0 !important;
    font-size: 0.88rem !important;
    color: #ffffff;
    background: #0071be !important;
    border-left: 5px solid #1c4866 !important;

    &.warn {
        background: #fd8e39 !important;
        border-left-color: #492305 !important;
        color: #222;

        a {
            color: #222;
            text-decoration: underline;
        }
    }

    &.error {
        background: #ca3333 !important;
        border-left-color: #711a1a !important;
        color: #fff;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    &.success {
        background: #3c7858 !important;
        border-left-color: #023f1f !important;
        color: #fff;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            padding: 0;
        }
    }
}
/*Profile Notification*/
.profile-update-notification {
    position: relative;
    padding: 10px;
    margin: 0 5px 5px;

    font-size: 16px;

    color: #222222;
    background: #ffffff;
    border-left: 5px solid #2192dd;
    -webkit-box-shadow: -5px 5px 5px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: -5px 5px 5px 0px rgba(0, 0, 0, 0.18);
    box-shadow: -5px 5px 5px 0px rgba(0, 0, 0, 0.18);

    .notification-img {
        color: #ffffff;
        width: 24px;
        margin-right: 5px;
    }
    a.close {
        position: absolute;
        top: 0px;
        right: 0px;
        color: #717171;
    }
}
