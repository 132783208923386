<template>
    <div v-if="component_done_loading" class="dark_topBar flex align-items">
        <a href="#" class="d-block dark_logo">
            <img src="/img/icons/_newicons_intedashboardlogo.png" width="25px" alt="InteDashboard | TBL Makes Teams Work" role="presentation" />
        </a>
        <div class="dark_topBarContent flexSpaceBetween align-items hidden-xs hidden-sm">
            <div>
                <button v-if="testObj.type == 'application'" class="btn btn-default" @click="openClosePresentationModal">
                    <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                </button>
                <router-link
                    v-else
                    v-slot="{ navigate }"
                    :to="{
                        name: 'activities.tests.dashboard',
                        params: { id: testObj.uuid },
                    }"
                    custom
                >
                    <button class="btn btn-default" @click="navigate">
                        <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                    </button>
                </router-link>
            </div>

            <h1 class="dark_title">{{ testObj.name }} Presentation Screen</h1>

            <test-status
                v-if="['not started', 'paused', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                :test="testObj.status"
                role="topBarStatus"
            />
        </div>
        <div class="dark_topBarContent hidden-md hidden-lg">
            <div class="flexSpaceBetween align-items">
                <div>
                    <button v-if="testObj.type == 'application'" class="btn btn-default" @click="openClosePresentationModal">
                        <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                    </button>
                    <router-link
                        v-else
                        v-slot="{ navigate }"
                        :to="{
                            name: 'activities.tests.dashboard',
                            params: { id: testObj.uuid },
                        }"
                        custom
                    >
                        <button class="btn btn-default" @click="navigate">
                            <i class="fa fa-fw fa-close marginRight8" aria-hidden="true" />Close Presentation Mode
                        </button>
                    </router-link>
                </div>
                <test-status
                    v-if="['not started', 'paused', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                    :test="testObj.status"
                    role="topBarStatus"
                />
            </div>

            <h1 class="dark_title marginTop10">{{ testObj.name }} Presentation Screen</h1>
        </div>

        <div
            id="closePresentationModal"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="closePresentationModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="closePresentationModal-title" class="modal-title">Close Presentation Mode</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">Close Presentation</h2>

                        <h3 class="marginTop20 important">Please Take Note!</h3>
                        <h4>Any e-Gallery walks will be stopped.</h4>

                        <p class="marginTop20">
                            <b>Do you still want to continue?</b>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">No, cancel</button>

                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="closePresentationMode()">Yes, Close Presentation</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
        };
    },
    created() {
        const toUpperCase = this.testObj.type ? this.testObj.type.toUpperCase() : '';
        document.title = `${this.testObj.name} Presentation Screen | ${toUpperCase} | InteDashboard | TBL Makes Teams Work`;
    },
    mounted() {
        this.component_done_loading = true;
    },
    methods: {
        closePresentationMode() {
            this.$router.push({
                name: 'activities.tests.dashboard',
                params: { id: this.testObj.uuid },
            });
        },
        openClosePresentationModal() {
            $('#closePresentationModal').modal('show');
        },
    },
};
</script>

<style scoped>
.dark_topBarContent {
    padding: 10px 25px;
    background-color: #495060;
    flex: 1;
}

.dark_topBar {
    background-color: #273142;
    position: fixed;
    z-index: 200;
    width: 100%;
}

.dark_title {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
    text-align: left;
}

@media (min-width: 992px) {
    .dark_logo {
        padding: 10px 35px;
    }
}

@media (max-width: 991px) {
    .dark_logo {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .dark_topBarContent {
        padding: 15px;
    }
}
#closePresentationModal .modal-body .row:last-child {
    margin-top: 30px;
}
#closePresentationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#closePresentationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>
