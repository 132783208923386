<template>
    <div v-if="component_done_loading" id="account-view" class="col-xs-12 right-container">
        <div class="tabs-sticky marginBottom30" ref="tabsSticky">
            <nav class="links margin0">
                <ul role="tablist" aria-label="Tabs">
                    <li v-for="tab in filteredTabs" :key="tab.id" :class="{ active: currentTab == tab.id }" @click="currentTab = tab.id">
                        <a class="nav-link" data-toggle="tab" :href="`#${tab.id}`" :aria-controls="tab.id" :aria-selected="currentTab == tab.id">
                            {{ tab.label }}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="row tab-content">
            <div id="account" class="tab-pane fade in" :class="{ active: currentTab == 'account' }">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-12 col-sm-6">
                            <h1>{{ $t('modules.accounts.view.overviewTabLabel') }}</h1>
                        </div>
                        <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px, marginTop20: isBelow768px }">
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.edit',
                                    params: $route.params.id,
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />{{ $t('modules.accounts.view.editButtonLabel') }}
                                </button>
                            </router-link>

                            <button
                                v-if="!account.isSuspended"
                                id="top-reset-pwd-btn"
                                class="btn btn-default"
                                aria-label="Reset Password"
                                @click.prevent="openResetPasswordModal(account, 'account')"
                            >
                                <i class="fa fa-key marginRight8" aria-hidden="true" />{{ $t('modules.accounts.view.resetPasswordButtonLabel') }}
                            </button>

                            <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                                <button class="btn btn-default" @click="navigate">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true" />{{ $t('modules.accounts.view.backButtonLabel') }}
                                </button>
                            </router-link>
                        </div>
                    </template>
                    <template #content>
                        <div class="col-xs-12 col-md-12">
                            <div>
                                <div class="form-group">
                                    <label class="control-label">{{ $t('modules.accounts.view.organisationNameLabel') }}</label>
                                    <p class="form-control-static">
                                        {{ account.organisationName }}
                                    </p>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.fullnameSuperAdminLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.displayName }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.emailLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.email }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.jobDesignationLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.jobDescription }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.influencerLevelLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ getInfluencerLevelLabel(account.adminTeacherAccount.influencerLevel) }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.segmentLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.segment }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.fieldOfDisciplineLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ account.adminTeacherAccount.fieldOfDiscipline }}
                                            <template v-if="account.adminTeacherAccount.fieldOfDiscipline == 'Others'">
                                                -
                                                {{ account.adminTeacherAccount.otherFieldOfDiscipline }}
                                            </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.statusLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="!account.isSuspended">
                                                <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active
                                            </template>
                                            <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.lmsLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ account.lms }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.typeLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ account.type }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.paymentMethodLabel') }} </label>
                                        <p class="form-control-static">
                                            {{ account.paymentMethod }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.contractStartDateLabel') }} </label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(account.startDate, 'DD MMM YYYY').date }}
                                            ({{ convertToReadableDate(account.startDate, 'DD MMM YYYY').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.contractEndDateLabel') }} </label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').date }}
                                            ({{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.createdByLabel') }} </label>
                                        <p class="form-control-static">
                                            {{ account.createBy }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.createdOnLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="account.dateCreated">
                                                {{ convertToReadableDate(account.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(account.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <template v-if="account.paymentMethod != 'Student-Paid'">
                                    <div class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label"> {{ $t('modules.accounts.view.activatedOnLabel') }} </label>
                                            <p class="form-control-static">
                                                <template v-if="account.dateActivated">
                                                    {{ convertToReadableDate(account.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(account.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label"> {{ $t('modules.accounts.view.expiresOnLabel') }}</label>
                                            <p class="form-control-static">
                                                <template v-if="account.expiryDate">
                                                    {{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').date }}
                                                    ({{ convertToReadableDate(account.expiryDate, 'DD MMM YYYY').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.accountLastLoginLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="account.adminTeacherAccount.dateLastLogin">
                                                {{ convertToReadableDate(account.adminTeacherAccount.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{
                                                    convertToReadableDate(account.adminTeacherAccount.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone
                                                }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> {{ $t('modules.accounts.view.accountLastModifiedLabel') }} </label>
                                        <p class="form-control-static">
                                            <template v-if="account.lastModified">
                                                {{ convertToReadableDate(account.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(account.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">{{ $t('modules.accounts.view.versionLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="account.version == 'full'"> Full </template>
                                            <template v-else> Lite </template>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="(account.type == 'Paid' || account.type == 'Paid Trial') && account.paymentMethod == 'Student-Paid'"
                                class="col-xs-12 marginTop30"
                            >
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Plan</th>
                                                <th>Price</th>
                                                <th>Plan Period</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(plan, idx) in account.paymentPlans" :key="idx">
                                                <tr>
                                                    <template v-if="plan.plan">
                                                        <td>
                                                            {{ plan.plan }}
                                                        </td>
                                                        <td>
                                                            {{ plan.price }}
                                                        </td>
                                                        <td>
                                                            {{ plan.planPeriod }}
                                                        </td>
                                                    </template>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>

                <div class="viewDropdown flexRight" @click.prevent="toggle">
                    <div class="dropup">
                        <button
                            id="viewDropdownMenuButton"
                            class="width100 flexSpaceBetween align-items"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            aria-label="More Actions"
                        >
                            <span class="marginRight10"> More Actions </span>
                            <span>
                                <i
                                    :class="{
                                        'fa fa-caret-up': isOpened,
                                        'fa fa-caret-down': !isOpened,
                                    }"
                                />
                            </span>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                            <ul>
                                <li v-if="!account.isSuspended">
                                    <a class="danger-state" href="#" @click.prevent="openSuspendModal()">
                                        <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Suspend Account </span>
                                    </a>
                                </li>

                                <li v-else>
                                    <a class="primary-state" href="#" @click.prevent="openSuspendModal()">
                                        <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate Account </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <kr-panel v-if="false" :with-footer="false" class="marginTop30">
                    <template #title>
                        <div class="col-xs-6">Estimated Usage</div>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.usage.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="{
                                        content: 'Edit Estimated Usage',
                                    }"
                                    :to="{
                                        name: 'accounts.usage.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate" aria-label="Edit Estimated Usage">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>

                    <template #content>
                        <div class="col-xs-12 col-md-12 viewFullDetails">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> No Of Faculty</label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.noOfFaculty }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> No Of Student Licenses </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.noOfStudentLicences }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> Activity Interests </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.activityInterests }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> Usage Type </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.usageType }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> Expansion Potential </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.expansionPotential }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> Course Start Date </label>
                                        <p class="form-control-static">
                                            {{ account.estimatedUsage.courseStartDate }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>

                <kr-panel v-if="false" :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">Onboarding</div>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.onboard.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                                    </button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="{
                                        content: 'Edit Onboarding',
                                    }"
                                    :to="{
                                        name: 'accounts.onboard.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate" aria-label="Edit Onboarding">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>

                    <template #content>
                        <div class="col-xs-12 col-md-12 viewFullDetails">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> Onboarding </label>
                                        <p class="form-control-static">
                                            {{ account.onboarding.onboarding }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> Date & Time </label>
                                        <p class="form-control-static">
                                            <template v-if="account.onboarding.date">
                                                {{ convertToReadableDate(account.onboarding.date, 'DD MMM YYYY').date }}
                                                ({{ convertToReadableDate(account.onboarding.date, 'DD MMM YYYY').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label"> Assigned Customer Advisor </label>
                                        <p class="form-control-static">
                                            {{ account.onboarding.customerAdvisor }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label class="control-label">LMS</label>
                                        <p class="form-control-static">
                                            {{ account.onboarding.lms }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="form-group">
                                    <label class="control-label"> School Year </label>
                                    <p class="form-control-static">
                                        {{ account.onboarding.schoolYear }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>

                <kr-panel v-if="false" :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">Notes</div>
                        <div class="col-xs-6 textAlignRight">
                            <div class="hidden-xs">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.notes.create',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-primary" @click="navigate"><i class="fa fa-plus marginRight8" aria-label="Add" />New Note</button>
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <router-link
                                    v-slot="{ navigate }"
                                    v-tooltip="{
                                        content: 'Add New Note',
                                    }"
                                    :to="{
                                        name: 'accounts.notes.create',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate" aria-label="Add New Note">
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </template>

                    <template #content>
                        <template v-for="note in notes" :key="note.uuid">
                            <div class="col-xs-12 col-md-12 viewFullDetails">
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label">Type</label>
                                            <p class="form-control-static">
                                                {{ note.type }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="['Customer', 'Onboarding'].includes(note.type)" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <template v-if="note.type == 'Onboarding'"> Onboarding </template>
                                                <template v-else> Call </template>
                                                Date
                                            </label>
                                            <p class="form-control-static">
                                                <template v-if="note.date">
                                                    {{ convertToReadableDate(note.date, 'DD MMM YYYY').date }}
                                                    ({{ convertToReadableDate(note.date, 'DD MMM YYYY').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="note.type != 'Basic'" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label"> Assigned Customer Advisor </label>
                                            <p class="form-control-static">
                                                {{ note.customerAdvisor }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="note.type == 'Onboarding'" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label"> School Year </label>
                                            <p class="form-control-static">
                                                {{ note.schoolYear }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="note.type == 'Customer'" class="col-xs-12 col-md-3">
                                        <div class="form-group">
                                            <label class="control-label"> Call Type </label>
                                            <p class="form-control-static">
                                                {{ note.callType }}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="note.type == 'Customer'">
                                    <div class="form-group">
                                        <label class="control-label"> Call Status </label>
                                        <p class="form-control-static">
                                            {{ note.status }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="noteDiv marginTop20">
                                <div class="alert alert-warning">
                                    <div style="white-space: prewrap" v-dompurify-html="note.notes" />
                                </div>
                                <div class="flex">
                                    <div class="col-xs-6 col-md-6">
                                        {{ note.createBy }} at
                                        {{ convertToReadableDate(note.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(note.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </div>
                                    <div class="col-xs-6 textAlignRight actions">
                                        <router-link
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'accounts.notes.edit',
                                                params: {
                                                    id: note.uuid,
                                                    account_id: $route.params.id,
                                                },
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default" @click="navigate" aria-label="Edit Note">
                                                <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                            </button>
                                        </router-link>

                                        <button class="btn btn-danger" aria-label="Delete" @click="openDeleteNoteModal(note)">
                                            <i class="fa fa-trash-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="notes.length == 0">
                            <div>This account currently has no notes.</div>
                        </template>
                    </template>
                </kr-panel>
            </div>

            <div id="superadmin" class="tab-pane fade in" :class="{ active: currentTab == 'superadmin' }">
                <kr-panel :with-footer="false" class="overflowXinherit">
                    <template #title> {{ $t('modules.accounts.superadmins.listing.headingLabel') }} </template>

                    <template #content>
                        <kr-search
                            name="superadmins_listing"
                            :url="{
                                url: '/accounts/' + $route.params.id + '/account-users',
                            }"
                            :columns="{
                                name: 'display:' + $t('modules.accounts.superadmins.listing.fullnameLabel') + '|sortable:false|width:22%',
                                email: 'display:' + $t('modules.accounts.superadmins.listing.emailLabel') + '|sortable:false|width:22%|mobile:hide',
                                isSuspended: 'display:' + $t('modules.accounts.superadmins.listing.statusLabel') + '|sortable:false|width:130px|mobile:hide',
                                dateLastLogin:
                                    'display:' + $t('modules.accounts.superadmins.listing.lastLoginLabel') + '|sortable:false|width:256px|mobile:hide',
                                action: 'display:|sortable:false|width:132px',
                            }"
                            :options="{
                                suspended_field: true,
                                params: { role: '2' },
                                search_field: true,
                                action_column: false,
                            }"
                            @retrieve-data="getSuperAdminSearchData"
                        >
                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'accounts.superadmins.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-primary" @click="navigate">
                                            <i class="fa fa-plus marginRight8" aria-label="Add" />{{
                                                $t('modules.accounts.superadmins.listing.newSuperadminButtonLabel')
                                            }}
                                        </button>
                                    </router-link>
                                    <router-link v-slot="{ navigate }" :to="{ name: 'accounts.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true" />{{
                                                $t('modules.accounts.superadmins.listing.backButtonLabel')
                                            }}
                                        </button>
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link
                                        id="top-mobile-add-superadmin-btn"
                                        :to="{
                                            name: 'accounts.superadmins.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        tag="button"
                                        class="btn btn-primary"
                                        aria-label="New Super Admin"
                                    >
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </router-link>
                                    <router-link
                                        id="top-mobile-back-superadmin-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </router-link>
                                </div>
                            </template>

                            <template #top-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #table-title="props">
                                <th colspan="6">
                                    <template v-if="superAdminSearchData.query.isSuspended == '0'"> Active </template>
                                    <template v-if="superAdminSearchData.query.isSuspended == '1'"> Suspended </template>
                                    <template v-if="superAdminSearchData.query.isSuspended == '*'"> All </template>
                                    {{ $t('modules.accounts.superadmins.listing.headingLabel') }} ( {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }} )
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <router-link
                                        v-tooltip="{
                                            content: 'View Teacher',
                                        }"
                                        :to="{
                                            name: 'accounts.superadmins.view',
                                            params: { id: props.model.uuid },
                                        }"
                                        class="link"
                                    >
                                        {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                                    </router-link>
                                </td>
                                <td class="hidden-sm hidden-xs">
                                    {{ props.model.email }}
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="!props.model.isSuspended">
                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active
                                    </template>
                                    <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateLastLogin">
                                        {{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>
                                <td>
                                    <div class="dropup">
                                        <button
                                            id="viewDropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Actions"
                                        >
                                            {{ $t('modules.accounts.superadmins.listing.actionsButtonLabel')
                                            }}<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'View Super Admin'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'accounts.superadmins.view',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="View Super Admin"
                                                    >
                                                        <span>
                                                            <i class="fas fa-eye marginRight8" aria-hidden="true" />{{
                                                                $t('modules.accounts.superadmins.listing.viewButtonLabel')
                                                            }}
                                                        </span>
                                                    </router-link>
                                                </li>

                                                <template v-if="!props.model.isSuspended">
                                                    <li>
                                                        <a
                                                            v-tooltip="'Reset Password'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Reset Password"
                                                            @click.prevent="openResetPasswordModal(props.model, 'teacher')"
                                                        >
                                                            <span>
                                                                <i class="fa fa-key marginRight8" aria-hidden="true" />{{
                                                                    $t('modules.accounts.superadmins.listing.resetPasswordButtonLabel')
                                                                }}
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a
                                                            v-tooltip="'Impersonate User'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Impersonate User"
                                                            @click.prevent="openImpersonationModal(props.model)"
                                                        >
                                                            <span>
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />{{
                                                                    $t('modules.accounts.superadmins.listing.impersonateUserButtonLabel')
                                                                }}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li v-if="superAdminSearchData.meta.paginator.total > 1">
                                                        <a
                                                            v-tooltip="'Remove Superadmin role'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Remove Superadmin role"
                                                            @click.prevent="openRemoveSuperAdminConfirmationModal(props.model)"
                                                        >
                                                            <span>
                                                                <i class="fa fa-trash marginRight8" aria-hidden="true" />{{
                                                                    $t('modules.accounts.superadmins.listing.removeSuperadminRoleLabel')
                                                                }}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
            <div id="teacher" class="tab-pane fade in" :class="{ active: currentTab == 'teacher' }">
                <kr-panel :with-footer="false" class="overflowXinherit">
                    <template #title> {{ $t('modules.accounts.teachers.listing.headingLabel') }} </template>

                    <template #content>
                        <kr-search
                            name="teachers_listing"
                            :url="{
                                url: '/accounts/' + $route.params.id + '/account-users',
                            }"
                            :columns="{
                                name: 'display:' + $t('modules.accounts.teachers.listing.fullnameLabel') + '|sortable:false|width:22%',
                                role: 'display:' + $t('modules.accounts.teachers.listing.roleLabel') + '|sortable:false|width:142px|mobile:hide',
                                email: 'display:' + $t('modules.accounts.teachers.listing.emailLabel') + '|sortable:false|width:22%|mobile:hide',
                                isSuspended: 'display:' + $t('modules.accounts.teachers.listing.statusLabel') + '|sortable:false|width:130px|mobile:hide',
                                dateLastLogin: 'display:' + $t('modules.accounts.teachers.listing.lastLoginLabel') + '|sortable:false|width:256px|mobile:hide',
                                action: 'display:|sortable:false|width:132px',
                            }"
                            :options="{
                                suspended_field: true,
                                params: { role: '3' },
                                search_field: true,
                                action_column: false,
                            }"
                            @retrieve-data="getTeacherSearchData"
                        >
                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link
                                        id="top-add-teacher-btn"
                                        :to="{
                                            name: 'accounts.teachers.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        tag="button"
                                        class="btn btn-primary"
                                        aria-label="New Teacher"
                                    >
                                        <i class="fa fa-plus marginRight8" aria-hidden="true" />
                                        {{ $t('modules.accounts.teachers.listing.newTeacherButtonLabel') }}
                                    </router-link>
                                    <router-link
                                        id="top-back-teacher-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />{{
                                            $t('modules.accounts.teachers.listing.backButtonLabel')
                                        }}
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link
                                        :to="{
                                            name: 'accounts.teachers.create',
                                            params: {
                                                account_id: account.uuid,
                                            },
                                        }"
                                        tag="button"
                                        class="btn btn-primary"
                                        aria-label="New Teacher"
                                    >
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </router-link>
                                    <router-link
                                        id="top-mobile-back-teacher-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </router-link>
                                </div>
                            </template>

                            <template #top-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #table-title="props">
                                <th colspan="6">
                                    <template v-if="teacherSearchData.query.isSuspended == '0'"> Active </template>
                                    <template v-if="teacherSearchData.query.isSuspended == '1'"> Suspended </template>
                                    <template v-if="teacherSearchData.query.isSuspended == '*'"> All </template>
                                    {{ $t('modules.accounts.teachers.listing.headingLabel') }} ( {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }} )
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <router-link
                                        v-tooltip="{
                                            content: 'View Teacher',
                                        }"
                                        :to="{
                                            name: 'accounts.teachers.view',
                                            params: { id: props.model.uuid },
                                        }"
                                        class="link"
                                    >
                                        {{ props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') }}
                                    </router-link>
                                </td>
                                <td class="hidden-sm hidden-xs">
                                    <template v-if="props.model.role == 'Super Admin'"> Super Admin </template>
                                    <template v-else-if="props.model.canAddTeacher"> Admin Teacher </template>
                                    <template v-else> Teacher </template>
                                </td>
                                <td class="hidden-sm hidden-xs">
                                    {{ props.model.email }}
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="!props.model.isSuspended">
                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Active
                                    </template>
                                    <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended </template>
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateLastLogin">
                                        {{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <button
                                            id="viewDropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Actions"
                                        >
                                            {{ $t('modules.accounts.teachers.listing.actionsButtonLabel') }}
                                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'View Teacher'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'accounts.teachers.view',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="View Teacher"
                                                    >
                                                        <span>
                                                            <i class="fas fa-eye marginRight8" aria-hidden="true" />{{
                                                                $t('modules.accounts.teachers.listing.viewButtonLabel')
                                                            }}
                                                        </span>
                                                    </router-link>
                                                </li>

                                                <template v-if="!props.model.isSuspended">
                                                    <li>
                                                        <a
                                                            v-tooltip="'Reset Password'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Reset Password"
                                                            @click.prevent="openResetPasswordModal(props.model, 'teacher')"
                                                        >
                                                            <span>
                                                                <i class="fa fa-key marginRight8" aria-hidden="true" />{{
                                                                    $t('modules.accounts.teachers.listing.resetPasswordButtonLabel')
                                                                }}
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a
                                                            v-tooltip="'Impersonate User'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Impersonate User"
                                                            @click.prevent="openImpersonationModal(props.model)"
                                                        >
                                                            <span>
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />{{
                                                                    $t('modules.accounts.teachers.listing.impersonateUserButtonLabel')
                                                                }}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>

            <div id="student" class="tab-pane fade in" :class="{ active: currentTab == 'student' }">
                <kr-panel :with-footer="false" class="overflowXinherit">
                    <template #title> {{ $t('modules.accounts.students.listing.headingLabel') }} </template>
                    <template #content>
                        <kr-search
                            ref="students_listing"
                            name="students_listing"
                            :url="{ url: `students` }"
                            :columns="{
                                displayName: 'display:' + $t('modules.accounts.students.listing.fullnameLabel') + '|sortable:true|width:30%',
                                email: 'display:' + $t('modules.accounts.students.listing.emailLabel') + '|sortable:true|width:12%|mobile:hide',
                                organisationID: 'display:' + $t('modules.accounts.students.listing.studentIdLabel') + '|sortable:true|width:13%|mobile:hide',
                                status: 'display:' + $t('modules.accounts.students.listing.statusLabel') + '|sortable:true|width:20%|mobile:hide',
                                origin: 'display:' + $t('modules.accounts.students.listing.originLabel') + '|sortable:true|width:20%|mobile:hide',
                                action: 'display:|sortable:false|width:132px',
                            }"
                            :options="{
                                per_page: 10,
                                query_fields: student_query_fields,
                                default_query_fields: {
                                    sort: 'dateCreated',
                                    order: 'desc',
                                },
                                search_history_id: 'accounts_students_index',
                                action_column: false,
                                newServerMode: {
                                    includes: ['q', 'isSuspended', 'sort', 'order', 'origin', 'courseUuid', 'status', 'enrolled'],
                                },
                            }"
                            @retrieve-data="getStudentSearchData"
                        >
                            <template #lower-left>
                                <div style="display: flex">
                                    <div class="marginLeft20">
                                        <div class="form-group displayContent">
                                            <div class="form-dropdown viewFilter marginLeft0">
                                                <select
                                                    v-model="student_query_fields.status"
                                                    class="form-control"
                                                    :aria-label="'Show ' + student_query_fields.status + ' status'"
                                                    @change="$refs['students_listing'].debounced_search(true)"
                                                >
                                                    <option value="">All Status</option>
                                                    <option value="Invite Sent">Invite Sent</option>
                                                    <option value="Not Yet Invited">Not Yet Invited</option>
                                                    <option v-if="![3].includes(paidSettings)" value="Active">Active</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Active (Not Yet Paid)">Active (Not Yet Paid)</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Active (Paid)">Active (Paid)</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Lapsed">Lapsed</option>
                                                    <option v-if="[1, 3].includes(paidSettings)" value="Expired">Expired</option>
                                                    <option value="Suspended">Suspended</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown marginLeft20 new-custom-search-select">
                                        <button
                                            id="viewDropdownMenuButton"
                                            class="btn"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Search Course"
                                        >
                                            <template v-if="course === 'all'"> All Enrollments </template>
                                            <template v-else-if="course === 'enrolled'"> Enrolled in any course </template>
                                            <template v-else-if="course === 'not enrolled'"> Not enrolled in any course </template>
                                            <template v-else>
                                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                            </template>

                                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                            <div class="form-group form-search has-feedback has-feedback-left">
                                                <input
                                                    v-model="studentSearchData.q"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search..."
                                                    @keyup="searchCourseDropdown"
                                                />
                                                <i class="fa fa-search form-control-feedback" aria-hidden="true"></i>
                                            </div>

                                            <ul>
                                                <div
                                                    v-if="studentSearchData.q && (resultQuery.length / perPage).toFixed() >= 2 && resultQuery.length != 0"
                                                    class="padding10 paddingTop0 flexSpaceBetween align-items"
                                                >
                                                    <a
                                                        class="link noBg flexOnly align-items lineHeight1"
                                                        href="#"
                                                        :class="{
                                                            disabled: pageNumber == 0,
                                                        }"
                                                        @click="previous"
                                                    >
                                                        <i class="fa fa-angle-double-left marginRight8" aria-hidden="true"></i>Prev
                                                    </a>

                                                    <span class="lineHeight1">
                                                        Page
                                                        {{ pageNumber + 1 }} of
                                                        {{ (resultQuery.length / perPage).toFixed() }}
                                                    </span>

                                                    <a
                                                        class="link noBg flexOnly align-items lineHeight1"
                                                        href="#"
                                                        :class="{
                                                            disabled: pageNumber >= (resultQuery.length / perPage).toFixed() - 1,
                                                        }"
                                                        @click="next"
                                                    >
                                                        Next<i class="fa fa-angle-double-right marginLeft8" aria-hidden="true"></i>
                                                    </a>
                                                </div>

                                                <template v-if="studentSearchData.isSearching">
                                                    <template
                                                        v-for="course in resultQuery.slice(pageNumber * perPage, pageNumber * perPage + perPage)"
                                                        :key="course.uuid"
                                                    >
                                                        <li
                                                            @click="
                                                                $refs['students_listing'].debounced_search(true);
                                                                select(course);
                                                            "
                                                            v-dompurify-html="courseHighlight(course.code ? course.code + ' - ' + course.name : course.name)"
                                                        ></li>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <li
                                                        key="allCourse"
                                                        @click="
                                                            select('all');
                                                            $refs['students_listing'].debounced_search(true);
                                                        "
                                                    >
                                                        All Enrollments
                                                    </li>
                                                    <li
                                                        key="enrolledCourse"
                                                        @click="
                                                            select('enrolled');
                                                            $refs['students_listing'].debounced_search(true);
                                                        "
                                                    >
                                                        Enrolled in any course
                                                    </li>
                                                    <li
                                                        key="notEnrolledCourse"
                                                        @click="
                                                            select('not enrolled');
                                                            $refs['students_listing'].debounced_search(true);
                                                        "
                                                    >
                                                        Not enrolled in any course
                                                    </li>

                                                    <label v-if="coursesList.length > 0" class="dropdownHeading">
                                                        Course (Showing 8 of
                                                        {{ coursesList.length }})
                                                    </label>
                                                    <template v-if="coursesList.length > 0">
                                                        <template v-for="course in coursesList.slice(0, 8)" :key="course.uuid">
                                                            <li
                                                                @click="
                                                                    select(course);
                                                                    $refs['students_listing'].debounced_search(true);
                                                                "
                                                            >
                                                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="viewFilter floatLeft marginLeft20">
                                        <div class="form-group displayContent">
                                            <div class="form-dropdown viewFilter marginLeft0">
                                                <select
                                                    v-model="student_query_fields.origin"
                                                    class="form-control"
                                                    :aria-label="
                                                        student_query_fields.origin == ''
                                                            ? 'Show ' + student_query_fields.origin
                                                            : 'Show ' + student_query_fields.origin + ' origin'
                                                    "
                                                    @change="$refs['students_listing'].debounced_search(true)"
                                                >
                                                    <option value="">All Origins</option>
                                                    <option value="Express Sign-in">Express Sign-in</option>
                                                    <option value="LTI">LTI</option>
                                                    <option value="Email">Email</option>
                                                    <option value="Generic">Generic</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #upper-right>
                                <button class="btn btn-primary" aria-label="Export" @click="downloadStudents">
                                    <i class="fa fa-download marginRight8" aria-hidden="true" />{{ $t('modules.accounts.students.listing.exportButtonLabel') }}
                                </button>
                            </template>

                            <template #table-title="props">
                                <th colspan="8">
                                    {{ $t('modules.accounts.students.listing.headingLabel') }}
                                    <template v-if="course">
                                        in
                                        {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                    </template>
                                    &nbsp;
                                    <template v-if="props.model.meta.paginator.total > 0">
                                        ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                                    </template>
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    {{ props.model.displayName }}
                                    <template v-if="props.model.hasTeacherAccount">
                                        <span style="color: #717171"> (Also a teacher) </span>
                                    </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.email ? props.model.email : '-' }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.organisationID ? props.model.organisationID : '' }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <i
                                        class="fa fa-circle marginRight8"
                                        :class="{
                                            suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(props.model.status),
                                            readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(props.model.status),
                                            activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(props.model.status),
                                        }"
                                        aria-hidden="true"
                                    />
                                    {{ props.model.status }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.origin }}
                                </td>

                                <td>
                                    <div class="dropdown">
                                        <button
                                            id="viewDropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="Actions"
                                        >
                                            {{ $t('modules.accounts.students.listing.actionsButtonLabel')
                                            }}<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'View Student'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'accounts.students.view',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="View Student"
                                                    >
                                                        <span>
                                                            <i class="fas fa-eye marginRight8" aria-hidden="true" />{{
                                                                $t('modules.accounts.students.listing.viewButtonLabel')
                                                            }}</span
                                                        >
                                                    </router-link>
                                                </li>

                                                <li v-if="[1, 2, 3].includes(auth.user().roleId) && props.model.email">
                                                    <a
                                                        v-tooltip="'Reset Password'"
                                                        class="primary-state"
                                                        href="#"
                                                        aria-label="Reset Password"
                                                        @click.prevent="openResetPasswordModal(props.model)"
                                                    >
                                                        <span>
                                                            <i class="fa fa-key marginRight8" aria-hidden="true" />{{
                                                                $t('modules.accounts.students.listing.resetPasswordButtonLabel')
                                                            }}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>

            <div id="codes" class="tab-pane fade in" :class="{ active: currentTab == 'code' }">
                <kr-panel :with-footer="false">
                    <template #title> ACCESS CODES </template>

                    <template #content>
                        <kr-search
                            name="codes_listing"
                            :url="{
                                url: '/accounts/' + account.uuid + '/access-codes',
                            }"
                            :columns="{
                                checkbox: 'display:|sortable:false|width:5%|slotted:true',
                                bookstoreName: 'display:BOOKSTORE|sortable:false|width:8%',
                                accessCode: 'display:ACCESS CODE|sortable:false|width:8%|mobile:hide',
                                planName: 'display:PAYMENT PLAN|sortable:false|width:8%|mobile:hide',
                                dateCreated: 'display:DATE CREATED|sortable:false|width:8%|mobile:hide',
                                validityPeriod: 'display:VALIDITY PERIOD (WEEKS)|sortable:false|width:8%|mobile:hide',
                                toBeActivatedBeforeDate: 'display:TO BE USED BEFORE|sortable:false|width:8%|mobile:hide',
                                validForRefundDate: 'display:VALID FOR REFUND BEFORE|sortable:false|width:8%|mobile:hide',
                                status: 'display:CURRENT STATUS|sortable:false|width:10%',
                                dateUsed: 'display:USED ON|sortable:false|width:9%|mobile:hide',
                                usedBy: 'display:USED BY|sortable:false|width:9%|mobile:hide',
                                expiryDate: 'display:ACCOUNT EXPIRY DATE|sortable:false|width:9%|mobile:hide',
                                deactivatedOn: 'display:INVALIDATED ON|sortable:false|width:9%|mobile:hide',
                                refundedOn: 'display:REFUNDED ON|sortable:false|width:9%|mobile:hide',
                            }"
                            :options="{
                                activated_field: true,
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: true,
                                query_fields: query_fields,
                            }"
                            @retrieve-data="getAccessCodeData"
                        >
                            <template #upper-right>
                                <div class="buttonGroup">
                                    <div class="hidden-xs hidden-sm">
                                        <button class="btn btn-primary margin0" aria-label="Download Access Codes Report" @click="download">
                                            <i class="fa fa-download marginRight8" aria-hidden="true" />Access Codes Report
                                        </button>

                                        <router-link
                                            :to="{
                                                name: 'accounts.codes.create',
                                                params: {
                                                    account_id: account.uuid,
                                                },
                                            }"
                                            tag="button"
                                            class="btn btn-primary"
                                            aria-label="New Access Codes"
                                        >
                                            <i class="fa fa-plus marginRight8" aria-hidden="true" />New Access Codes
                                        </router-link>
                                    </div>
                                    <div class="hidden-md hidden-lg">
                                        <button class="btn btn-primary margin0" aria-label="Download Access Codes Report" @click="download">
                                            <i class="fa fa-download" aria-hidden="true" />
                                        </button>

                                        <router-link
                                            :to="{
                                                name: 'accounts.codes.create',
                                                params: {
                                                    account_id: account.uuid,
                                                },
                                            }"
                                            tag="button"
                                            class="btn btn-primary marginLeft20"
                                            aria-label="New Access Codes"
                                        >
                                            <i class="fa fa-plus" aria-hidden="true" />
                                        </router-link>
                                    </div>
                                </div>
                            </template>

                            <template #table-title="props">
                                <th colspan="14">
                                    All Access Codes ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )
                                    <div class="inlineFlex align-items marginLeft20">
                                        <div v-if="codeFlags.canRefund" class="btn btn-default" @click="openAccessCodeActionModal('refund')">
                                            <i class="fas fa-undo marginRight8" aria-hidden="true" />Refund
                                        </div>

                                        <div v-if="codeFlags.canSuspend" class="btn btn-danger" @click="openAccessCodeActionModal('suspend')">
                                            <i class="fas fa-ban marginRight8" aria-hidden="true" />Suspend
                                        </div>

                                        <div v-if="codeFlags.canDeactivate" class="btn btn-default" @click="openAccessCodeActionModal('deactivate')">
                                            <i class="fas fa-user-times marginRight8" aria-hidden="true" />Deactivate
                                        </div>

                                        <div v-if="codeFlags.canExtend" class="btn btn-default" @click="openAccessCodeActionModal('extend')">
                                            <i class="fas fa-clock marginRight8" aria-hidden="true" />Extend Validity
                                        </div>

                                        <div v-if="codeFlags.canReactivate" class="btn btn-danger" @click="openAccessCodeActionModal('reactivate')">
                                            <i class="fas fa-redo-alt marginRight8" aria-hidden="true" />Reactivate
                                        </div>
                                    </div>
                                </th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #checkbox>
                                <div class="checkboxField">
                                    <input v-model="toggleAccessCodes" type="checkbox" aria-label="Toggle Access Codes" @change="toggleCodes" />
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <div v-if="canSelectAccessCode(props.model)" class="checkboxField">
                                        <input v-model="codeData" type="checkbox" :value="props.model.uuid" />
                                    </div>
                                </td>

                                <td>
                                    {{ props.model.bookstoreName }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.code }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.planName }}
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.validityPeriod">
                                        {{ props.model.validityPeriod }}
                                    </template>
                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.toBeActivatedBeforeDate">
                                        {{ convertToReadableDate(props.model.toBeActivatedBeforeDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.toBeActivatedBeforeDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.validForRefundDate">
                                        {{ convertToReadableDate(props.model.validForRefundDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.validForRefundDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td>
                                    <template v-if="props.model.status == 'unactivated'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Unactivated
                                    </template>

                                    <template v-else-if="props.model.status == 'activated'">
                                        <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true" />Activated
                                    </template>

                                    <template v-else-if="props.model.status == 'suspended'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Suspended
                                    </template>

                                    <template v-else-if="props.model.status == 'deactivated'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Deactivated
                                    </template>

                                    <template v-else-if="props.model.status == 'refunded'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Refunded
                                    </template>

                                    <template v-else-if="props.model.status == 'expired'">
                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true" />Expired
                                    </template>

                                    <template v-else> - </template>
                                    <br />
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.dateUsed">
                                        {{ convertToReadableDate(props.model.dateUsed, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateUsed, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.usedByUuid">
                                        <router-link
                                            v-tooltip="{
                                                content: 'View Student',
                                            }"
                                            tag="a"
                                            :to="{
                                                name: 'accounts.students.view',
                                                params: {
                                                    id: props.model.usedByUuid,
                                                },
                                            }"
                                            class="link"
                                            :aria-label="props.model.usedBy"
                                        >
                                            {{ props.model.usedBy }}
                                        </router-link>
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.expiryDate">
                                        {{ convertToReadableDate(props.model.expiryDate, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.expiryDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.deactivatedOn">
                                        {{ convertToReadableDate(props.model.deactivatedOn, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.deactivatedOn, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td class="hidden-xs hidden-sm">
                                    <template v-if="props.model.refundedOn">
                                        {{ convertToReadableDate(props.model.refundedOn, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.refundedOn, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>
                            </template>

                            <template #ndata="props">
                                <td colspan="5" style="text-align: center; color: #717171">No access codes</td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>

            <div v-if="false" id="log" class="tab-pane fade in" :class="{ active: currentTab == 'log' }">
                <kr-panel :with-footer="false">
                    <template #title> ACTIVITY LOG </template>

                    <template #content>
                        <kr-search
                            name="log_listing"
                            :url="{ url: '/accounts/activity-log/index' }"
                            :columns="{
                                user: 'display:USER|sortable:false|width:35%|mobile:hide',
                                event: 'display:ACTION|sortable:false|width:30%',
                                dateCreated: 'display:TIMESTAMP|sortable:false|width:45%',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: false,
                                query_fields: query_fields,
                                params: { accountUuid: $route.params.id },
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #lower-left>
                                <div class="form-group periodBars">
                                    <label class="col-form-label">VIEW PERIOD</label>

                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="start_log_daterange"
                                            placeholder="Select start period"
                                            aria-label="Select Start Period"
                                        />
                                    </div>

                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="end_log_daterange"
                                            placeholder="Select end period"
                                            aria-label="Select End Period"
                                        />
                                    </div>
                                </div>
                            </template>

                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link
                                        id="top-back-log-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </router-link>
                                </div>

                                <div class="hidden-md hidden-lg marginLeft20">
                                    <router-link
                                        id="top-mobile-back-log-btn"
                                        :to="{ name: 'accounts.index' }"
                                        tag="button"
                                        class="btn btn-default"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </router-link>
                                </div>
                            </template>

                            <template #table-title="props">
                                <th colspan="6">All Logs ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>

                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>

                            <template #cdata="props">
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.user }}
                                </td>

                                <td>
                                    {{ props.model.event }}
                                </td>

                                <td>
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>

            <div id="payment-log" class="tab-pane fade in" :class="{ active: currentTab == 'payment-log' }">
                <kr-panel :with-footer="false">
                    <template #title> Payment Log </template>
                    <template #content>
                        <kr-search
                            name="payment_log_listing"
                            :url="{
                                url: `/accounts/${$route.params.id}/payment-logs`,
                            }"
                            :columns="{
                                type: 'display:TYPE|sortable:false|width:35%|mobile:hide',
                                timestamp: 'display:TIMESTAMP|sortable:false',
                                id: 'display:ID|sortable:false',
                                name: 'display:NAME|sortable:false',
                                email: 'display:EMAIL|sortable:false',
                                status: 'display:STATUS|sortable:false',
                                details: 'display:DETAILS|sortable:false',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: true,
                                query_fields: query_fields,
                                params: { accountUuid: $route.params.id },
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #table-title="props">
                                <th colspan="7">All Logs ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>
                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }} - {{ props.model.to }} of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>
                            <template #cdata="props">
                                <td>
                                    {{ props.model.paymentMode }}
                                </td>

                                <td>
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>

                                    <template v-else> - </template>
                                </td>

                                <td>
                                    {{ props.model.id }}
                                </td>

                                <td>
                                    {{ props.model.fullname }}
                                </td>

                                <td>
                                    {{ props.model.email }}
                                </td>

                                <td>
                                    <span :class="props.model.status">
                                        {{ props.model.status }}
                                    </span>
                                </td>

                                <td>
                                    <button
                                        v-if="props.model.paymentMethod != 'paypal'"
                                        class="btn btn-primary"
                                        aria-label="Details"
                                        @click="openDetailsModal(props.model.details, props.model.status)"
                                    >
                                        Details
                                    </button>
                                    <span v-else :class="props.model.status">{{ props.model.details }}</span>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>

        <div
            id="suspendModal"
            class="modal"
            :class="{ 'danger-modal': !account.isSuspended, 'warning-modal': account.isSuspended }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="!account.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">
                            <template v-if="!account.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h3 class="marginBottom20 fw-normal capitalize">
                            {{ account.organisationName }}
                        </h3>

                        <template v-if="!account.isSuspended">
                            <p class="marginTop20">Suspending this organisation will hide it</p>

                            <p>from your active organisations list.</p>

                            <p class="marginTop20">
                                <i>You can reinstate it later by filtering</i>
                            </p>

                            <p>
                                <i>your organisations and showing Suspended organisations.</i>
                            </p>

                            <p class="marginTop20">
                                <b>Are you sure to suspend the organisations?</b>
                            </p>
                        </template>

                        <template v-else>
                            <p class="marginTop20">Reinstating this organisations will visible it</p>

                            <p>in your active organisation list</p>

                            <p class="marginTop20">
                                <i>You can suspend it later if needed by </i>
                            </p>

                            <p>
                                <i>filtering your organisation and showing Active organisations.</i>
                            </p>

                            <p class="marginTop20">
                                <b>Are you sure to reinstate the organisations?</b>
                            </p>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button v-if="!account.isSuspended" class="btn btn-danger" data-dismiss="modal" @click.prevent="suspend()">
                            <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Suspend
                        </button>
                        <button v-else class="btn btn-success" data-dismiss="modal" @click.prevent="unsuspend()">Yes, Reinstate</button>
                    </div>
                </div>
            </div>
        </div>

        <!--- Refund --->
        <div id="refund-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="refundModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="refundModal-title" class="modal-title">Refund</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to refund the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success" data-dismiss="modal" aria-label="Confirm" @click="refundCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Suspend --->
        <div id="suspend-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="suspendModal-title" class="modal-title">Suspend</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to suspend the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success" data-dismiss="modal" aria-label="Confirm" @click="suspendCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Deactivate --->
        <div id="deactivate-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="deactivateModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deactivateModal-title" class="modal-title">Deactivate</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to deactivate the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success" data-dismiss="modal" aria-label="Confirm" @click="deactivateCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Reactivate --->
        <div id="reactivate-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="deactivateModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deactivateModal-title" class="modal-title">Reactivate</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to reactivate the following codes</div>
                        </div>
                        <div class="col-xs-12 textAlignCenter">
                            <ul class="modal-points">
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li>
                                        <div>
                                            <i class="fas fa-circle" aria-hidden="true" />
                                            <div>
                                                {{ code.code }} ({{ code.validityPeriod }}
                                                months)
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-success" data-dismiss="modal" aria-label="Confirm" @click="reactivateCodes">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--- Extend Validity --->
        <div id="extend-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="extendModal-title">
            <div class="modal-dialog primary-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="extendModal-title" class="modal-title">Extend Validity</h1>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2>Extending the validity</h2>

                        <div class="col-xs-12 col-md-8 col-md-offset-2 marginTop20">
                            <ul class="textAlignLeft">
                                <h3 class="control-label">List of {{ cDataFiltered.length }} Codes:</h3>
                                <template v-for="(code, idx) in cDataFiltered">
                                    <li class="fs-18px"><b>Code:</b> {{ code.code }} ({{ code.validityPeriod }} Months)</li>
                                </template>
                            </ul>
                        </div>

                        <p class="col-xs-12 col-md-6 col-md-offset-3">How many more months would you like to extend the validity of these access codes by?</p>

                        <div class="textAlignCenter marginTop20">
                            <label for="extendMonths-input" class="control-label">Set Extend Validity</label>
                            <div class="flex justifyCenter">
                                <div class="position-relative width68px height45px">
                                    <input
                                        id="extendMonths-input"
                                        v-model="extendMonths"
                                        type="number"
                                        class="form-control"
                                        aria-labelledby="extendMonths-description"
                                    />
                                    <span class="quantity-nav">
                                        <div class="quantity-button quantity-up" @click="extendMonths++">
                                            <i class="fas fa-plus" aria-hidden="true" />
                                        </div>
                                        <div class="quantity-button quantity-down" @click="extendMonths > 0 ? extendMonths-- : ''">
                                            <i class="fas fa-minus" aria-hidden="true" />
                                        </div>
                                    </span>
                                </div>
                                <div id="extendMonths-description" class="input-group-addon fs-14px widthAuto flex align-items">Months</div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click="extendCodes">Yes, Confirm</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="details-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="detailsModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="detailsModal-title" class="modal-title">Details</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15">
                            <pre v-dompurify-html="payment_detail" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="delete-note-modal" class="modal danger-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deleteNoteModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deleteNoteModal-title" class="modal-title">Delete Note</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">
                            <b>Delete Note</b>
                        </h2>
                        <p>Once note deleted can't be reverse</p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteNote()">
                            <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Yes, Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <kr-reset-password />

        <div id="impersonationModal" class="modal primary-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="impersonationModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="impersonationModal-title" class="modal-title">Impersonation</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <h2>Impersonate</h2>
                        <h3 class="fw-normal">
                            {{ user.displayName }}
                        </h3>

                        <p class="marginTop20">Impersonate user allows you access to the</p>
                        <p>user's account and their courses and more</p>

                        <p class="marginTop20">
                            <b>Are you sure to impersonate the user?</b>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="impersonate()">
                            <i class="fa fa-user marginRight8" />Impersonate
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="remove-superadmin-modal"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="remove-superadmin-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="remove-superadmin-title" class="modal-title">Remove Super Admin role</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" role="presentation" />

                        <h2 class="marginTop40">Remove Role From</h2>
                        <h3 class="fw-normal">{{ teacher.displayName }}</h3>

                        <p class="marginTop20">Removing their super admin role</p>
                        <p>will revoke their access to the course</p>
                        <p>unless, they are also invited as a teacher</p>

                        <p class="marginTop20">
                            <b>Do you wish to continue?</b>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="removeSuperAdmin()">
                            <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Yes, Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
import { useAuthUserStore } from '../../../stores/auth';
export default {
    data() {
        var auth = new KrAuth();
        var store = useAuthUserStore();
        let history = store.search_history;
        let search_history_id = 'accounts_students_index';
        return {
            component_done_loading: false,
            auth: auth,
            account: null,
            query_fields: { dateFrom: '', dateTo: '' },
            teacherSearchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
            },
            isOpened: false,
            payment_detail: '',
            accessCodes: [],
            codeData: [],
            cDataFiltered: [],
            codeFlags: {
                canRefund: false,
                canSuspend: false,
                canReactivate: false,
                canDeactivate: false,
                canExtend: false,
            },
            extendMonths: 3,
            toggleAccessCodes: false,
            notes: [],
            note: {}, //note to delete
            user: {},
            studentSearchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: {},
            },
            coursesList: [],
            paidSettings: false,
            course: 'all',
            student_query_fields: {
                status: _.get(history, `${search_history_id}.status`, ''),
                origin: _.get(history, `${search_history_id}.origin`, ''),
                courseUuid: _.get(history, `${search_history_id}.courseUuid`, ''),
                enrolled: _.get(history, `${search_history_id}.enrolled`, ''),
                accountUuid: this.$route.params.id,
            },
            resultQuery: [],
            perPage: 10,
            pageNumber: 0,
            teacher: {},
            superAdminSearchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
            },
            tabs: [],
            currentTab: this.$route.query.tab || 'account',
        };
    },
    computed: {
        filteredTabs() {
            return this.tabs.filter((tab) => tab.show);
        },
    },
    watch: {
        codeData: {
            handler: function () {
                if (this.codeData.length == 0) {
                    this.toggleAccessCodes = false;
                }
                if (this.accessCodes.length === this.codeData.length) {
                    this.toggleAccessCodes = true;
                }
                this.processFlags();
            },
            deep: true,
        },
        currentTab: {
            handler: function () {
                this.$router.replace({ query: { ...this.$route.query, tab: this.currentTab } }).then(() => {
                    this.breadCrumbTitle(this.currentTab);
                    this.tabStickyTop();
                });
            },
        },
        component_done_loading: {
            handler: function () {
                if (this.component_done_loading === true) {
                    setTimeout(() => {
                        this.tabStickyTop();
                        this.breadCrumbTitle(this.currentTab);
                    }, 100);
                }
            },
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: this.$t('modules.accounts.listing.breadcrumbLabel'), link: 'accounts.index' }],
        });
        this.fetch();
        this.fetchNotes();
    },
    methods: {
        searchCourseDropdown() {
            this.resultQuery = [];
            this.pageNumber = 0;

            if (this.studentSearchData.q.match(/[a-z]/i) != null) {
                this.studentSearchData.isSearching = true;
                this.studentSearchData.matched = 0;
                for (var i = 0; i < this.coursesList.length; i++) {
                    //Vue.set(this.coursesList[i], '_matched', 0);
                    this.coursesList[i]._matched = 0;
                    let course = this.coursesList[i];
                    let haystack = (course.name + course.code + '').toLowerCase();
                    let doesContain = haystack.indexOf(this.studentSearchData.q.toLowerCase());
                    if (doesContain != -1) {
                        couse._searchDisplay = true;
                        //Vue.set(course, '_searchDisplay', true);
                        this.studentSearchData.matched++;
                        this.coursesList[i]._matched++;

                        this.resultQuery.push(this.coursesList[i]);
                    } else {
                        //Vue.set(course, '_searchDisplay', false);
                        couse._searchDisplay = false;
                    }
                }
            } else {
                this.searchData.isSearching = false;
            }
        },
        select(option) {
            var that = this;
            this.course = false;
            this.student_query_fields.courseUuid = '';
            if (option === 'all') {
                this.course = 'all';
                this.student_query_fields.enrolled = '';
            } else if (option === 'enrolled') {
                this.course = 'enrolled';
                this.student_query_fields.enrolled = 1;
            } else if (option === 'not enrolled') {
                this.course = 'not enrolled';
                this.student_query_fields.enrolled = 0;
            } else {
                this.student_query_fields.courseUuid = option.uuid;
                if (this.student_query_fields.courseUuid != '') {
                    this.course = _.find(this.coursesList, function (o) {
                        return o.uuid == that.student_query_fields.courseUuid;
                    });
                }
            }
        },
        next(event) {
            this.pageNumber++;
            event.stopPropagation();
        },
        previous(event) {
            this.pageNumber--;
            event.stopPropagation();
        },
        courseHighlight(text) {
            if (this.studentSearchData.q != '') {
                if (text != null) {
                    return text.replace(new RegExp(this.studentSearchData.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
                }
            } else {
                return text;
            }
        },
        syntaxHighlight(json, status) {
            if (typeof json != 'string') {
                json = JSON.stringify(json, undefined, 2);
            }
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = status;
                return '<span class="' + cls + '">' + match + '</span>';
            });
        },
        getInfluencerLevelLabel(influencerLevel) {
            let idx = _.findIndex(this.kr_var.influencerLevels, function (o) {
                return o.value == influencerLevel;
            });
            if (idx != -1) {
                return this.kr_var.influencerLevels[idx].description;
            }
            return '';
        },
        toggleCodes() {
            this.codeData = [];
            if (this.toggleAccessCodes) {
                for (var i = 0; i < this.accessCodes.length; i++) {
                    let obj = this.accessCodes[i];
                    if (this.canSelectAccessCode(obj)) {
                        this.codeData.push(obj.uuid);
                    }
                }
            }
        },
        openAccessCodeActionModal(type) {
            if (type == 'extend') {
                this.extendMonths = 3;
            }
            this.cDataFiltered = [];
            for (var i = 0; i < this.codeData.length; i++) {
                let obj = this.getAccessCodeObject(this.codeData[i]);
                if (['unactivated', 'suspended'].includes(obj.status)) {
                    if (type == 'refund' || type == 'deactivate' || type == 'extend') {
                        this.cDataFiltered.push(obj);
                    }
                }
                if (['unactivated'].includes(obj.status)) {
                    if (type == 'suspend') {
                        this.cDataFiltered.push(obj);
                    }
                }
                if (['suspended'].includes(obj.status)) {
                    if (type == 'reactivate') {
                        this.cDataFiltered.push(obj);
                    }
                }
                if (['expired'].includes(obj.status)) {
                    if (type == 'extend') {
                        this.cDataFiltered.push(obj);
                    }
                }
            }
            $('#' + type + '-modal').modal('show');
        },
        refundCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/refund`, {
                    data: this.cDataFiltered,
                })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been refunded',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        suspendCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/suspend`, {
                    data: this.cDataFiltered,
                })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been suspended',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        deactivateCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/deactivate`, { data: this.cDataFiltered })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been deactivated',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        reactivateCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/reactivate`, { data: this.cDataFiltered })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes have been reactivated',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        extendCodes() {
            var that = this;
            axios
                .put(`/accounts/${this.$route.params.id}/access-codes/bulk/update-period`, { months: this.extendMonths, data: this.cDataFiltered })
                .then(function (response) {
                    that.codeData = [];
                    that.codeData.splice();
                    that.processFlags();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Access Codes validity have been extended',
                    });
                    Events.fire('codes_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        getAccessCodeData(ret) {
            this.accessCodes = ret.data.data.data;
        },
        getAccessCodeObject(uuid) {
            let idx = _.findIndex(this.accessCodes, function (o) {
                return o.uuid == uuid;
            });
            if (idx != -1) {
                return this.accessCodes[idx];
            } else {
                return {};
            }
        },
        processFlags() {
            let refund = false;
            let suspend = false;
            let reactivate = false;
            let deactivate = false;
            let extend = false;

            for (var i = 0; i < this.codeData.length; i++) {
                let obj = this.getAccessCodeObject(this.codeData[i]);
                if (['unactivated', 'suspended'].includes(obj.status)) {
                    refund = true;
                    deactivate = true;
                    extend = true;
                }
                if (['unactivated'].includes(obj.status)) {
                    suspend = true;
                }
                if (['suspended'].includes(obj.status)) {
                    reactivate = true;
                }
                if (['expired'].includes(obj.status)) {
                    extend = true;
                }
            }
            this.codeFlags.canRefund = refund;
            this.codeFlags.canSuspend = suspend;
            this.codeFlags.canReactivate = reactivate;
            this.codeFlags.canDeactivate = deactivate;
            this.codeFlags.canExtend = extend;
        },
        canSelectAccessCode(code) {
            if (['activated', 'deactivated', 'refunded'].includes(code.status)) {
                return false;
            }
            return true;
        },
        openDetailsModal(details, status) {
            this.payment_detail = this.syntaxHighlight(details, status);
            $('#details-modal').modal('show');
        },
        download() {
            var that = this;
            axios({
                url: `/accounts/${this.$route.params.id}/access-codes/export`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.account.organisationName}_ACCESS_CODES_REPORT_` + moment().format('YYYY_MM_DD') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openImpersonationModal(user) {
            this.user = user;
            $('#impersonationModal').modal('show');
        },
        impersonate() {
            var that = this;
            let userUuid = this.user.uuid;
            axios
                .post('auth/impersonate', { userUuid: userUuid })
                .then(function (response) {
                    let token = response.data.data.token;
                    axios
                        .post('/auth/validate?impersonate', {}, { headers: { Authorization: `Bearer ${token}` } })
                        .then(function (response) {
                            that.auth.login(response.data, {
                                impersonate: true,
                            });
                        })
                        .catch(function (errors) {
                            that.$notify({
                                group: 'form',
                                type: 'error',
                                title: 'Error',
                                text: 'Something went wrong, please try again later ',
                            });
                        });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        getSuperAdminSearchData(data) {
            this.superAdminSearchData = data;
        },
        getTeacherSearchData(data) {
            this.teacherSearchData = data;
        },
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        suspend() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.account.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been suspended',
                });
            }.bind(this);
            var del = function () {
                this.account.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .put('/accounts/' + this.account.uuid + '/suspend')
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function () {
                var that = this;
                this.account.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.account.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .put('/accounts/' + this.account.uuid + '/suspend')
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        search() {
            Events.fire('log_listing_refresh');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        openResetPasswordModal(user, module) {
            if (module == 'account') {
                Events.fire('open-reset-password-modal', {
                    user: user.adminTeacherAccount,
                    organisation: this.account.organisationName,
                });
            } else {
                Events.fire('open-reset-password-modal', {
                    user: user,
                    organisation: this.account.organisationName,
                });
            }
        },
        fetchNotes() {
            let that = this;
            axios.get('/accounts/' + this.$route.params.id + '/notes').then(function (response) {
                that.notes = response.data.data;
            });
        },
        fetch() {
            let that = this;
            axios.get('/accounts/' + this.$route.params.id).then(function (response) {
                that.account = response.data.data;
                that.coursesList = that.account.courses;
                if (['Paid', 'Paid-Trial'].includes(_.get(that.account, 'type'))) {
                    if (['Institution'].includes(_.get(that.account, 'paymentMethod'))) {
                        that.paidSettings = 2;
                    } else {
                        that.paidSettings = 3;
                    }
                } else {
                    that.paidSettings = false;
                }
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        { name: that.$t('modules.accounts.listing.breadcrumbLabel'), link: 'accounts.index' },
                        { name: that.account.organisationName },
                    ],
                });

                that.tabs = [
                    {
                        id: 'account',
                        label: 'Overview',
                        show: true,
                    },
                    {
                        id: 'superadmin',
                        label: 'Super Admins',
                        show: true,
                    },
                    {
                        id: 'teacher',
                        label: 'Teachers',
                        show: true,
                    },
                    {
                        id: 'student',
                        label: 'Students',
                        show: true,
                    },
                    {
                        id: 'code',
                        label: 'Access Codes',
                        show: that.account.type == 'Paid' && that.account.paymentMethod == 'Student-Paid',
                    },
                    {
                        id: 'payment-log',
                        label: 'Payment Logs',
                        show: that.account.type == 'Paid' && that.account.paymentMethod == 'Student-Paid',
                    },
                ];

                that.component_done_loading = true;
            });
        },
        toggle(event) {
            this.isOpened = !this.isOpened;
        },

        openDeleteNoteModal(note) {
            this.note = note;
            $('#delete-note-modal').modal('show');
        },
        deleteNote() {
            var that = this;
            axios
                .delete(`/accounts/${this.$route.params.id}/notes/${this.note.uuid}`)
                .then(function (response) {
                    that.fetchNotes();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Note has been deleted',
                    });
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        getStudentSearchData(data) {
            this.studentSearchData = data;
        },
        downloadStudents() {
            var that = this;
            let d = moment().format('YYYY[_]MM[_]DD');
            axios({
                url: `students?filter[q]=${this.studentSearchData.query.q}&accountUuid=${this.$route.params.id}&filter[origin]=${this.studentSearchData.query.origin}&filter[status]=${this.studentSearchData.query.status}&filter[courseUuid]=${this.studentSearchData.query.courseUuid}&filter[enrolled]=${this.studentSearchData.query.enrolled}&export=true`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Students_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        courseChange() {
            var that = this;
            this.course = false;
            if (this.student_query_fields.courseUuid != '') {
                this.course = _.find(this.coursesList, function (o) {
                    return o.uuid == that.student_query_fields.courseUuid;
                });
            }
        },
        openRemoveSuperAdminConfirmationModal(teacher) {
            this.teacher = teacher;
            $('#remove-superadmin-modal').modal('show');
        },
        removeSuperAdmin() {
            var that = this;
            axios
                .delete(`/accounts/${this.$route.params.id}/superadmin`, {
                    data: { superadminUuid: this.teacher.uuid },
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Super Admin role has been removed',
                    });
                    Events.fire('superadmins_listing_refresh');
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        tabStickyTop() {
            var topOffset = 0;

            if ($(window).width() < 992) {
                if (isNaN($('.fixed-banner-container').outerHeight())) {
                    topOffset = $('.top_nav.mobile').outerHeight();
                } else {
                    topOffset = $('.fixed-banner-container').outerHeight() + $('.top_nav.mobile').outerHeight();
                }
            } else {
                if (isNaN($('.fixed-banner-container').outerHeight())) {
                    topOffset = $('.top_nav.desktop').outerHeight();
                } else {
                    topOffset = $('.fixed-banner-container').outerHeight() + $('.top_nav.desktop').outerHeight();
                }
            }

            this.$refs.tabsSticky.style.top = `${topOffset}px`;
            this.$refs.tabsSticky.style.paddingTop = '10px';
        },
        breadCrumbTitle(tab) {
            var tabName =
                tab == 'account'
                    ? 'Overview'
                    : tab == 'superadmin'
                    ? 'Super Admins'
                    : tab == 'teacher'
                    ? 'Teachers'
                    : tab == 'student'
                    ? 'Students'
                    : tab == 'code'
                    ? 'Access Codes'
                    : tab == 'payment-log'
                    ? 'Payment Logs'
                    : '';

            document.title = `${tabName} | Accounts | InteDashboard | TBL Makes Teams Work`;

            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: this.$t('modules.accounts.listing.breadcrumbLabel'), link: 'accounts.index' },
                    { name: this.account.organisationName },
                    { name: tabName },
                ],
            });
        },
    },
};
</script>

<style scoped>
.noteDiv {
    margin-bottom: 30px;
}
.noteDiv .alert {
    min-height: 50px;
}
.noteDiv .actions {
    margin-top: 10px;
}
.failed {
    color: #ca3333;
}
.success {
    color: #3c7858;
}
.drop-down {
    width: 172px;
    height: 36px;
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #d8d8d8;
}

/* .dropdown .dropdown-menu {
    top: 10%;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom:0;
} */

#viewDropdownMenuButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.viewDropdown {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 992px) {
    .periodBars {
        width: 524px;
    }
}
</style>
<style>
pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}
.success {
    color: #3c7858;
}
.failed {
    color: #ca3333;
}
</style>
