
body {
    &.test {
        .panel {
            height: 500px;
            border-radius: 3px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            background-color: #ffffff;
            border: 1px solid #ededed;

            &:focus:focus-visible {
                outline: 4px solid #1941a6;
                outline-offset: 3px;
                box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
            }
        }
    }
}

body.test .panel-heading {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
}

body.test .panel-body {
    white-space: pre-line;
    padding: 15px 20px;
}

body.test .panel-footer {
    padding: 20px;
    border-top: 1px solid #d8d8d8;
    box-shadow: 0 -2px 4px 0 rgba(168, 168, 168, 0.5);
    background: #fff;
}
