
.timer_Div {
    .time .number-div {
        background: #fff;
        padding: 6px;
        border-radius: 3px;
        font-size: 28px;
        font-weight: bold;
        line-height: 0.8;
    }

    .time .number-div:last-child {
        margin-left: 5px;
    }

    .colon {
        color: #fff;
        padding: 0 10px;
    }

    .timeText {
        font-size: 0.778rem;
        margin-bottom: 5px;
    }
}

.miniTimer {
    position: fixed;
    top: 10px;
    right: 100px;
    z-index: 1001;

    &.timer_Div {
        .timeText {
            text-align: center;
            font-size: 10px;
        }
        .time {
            .number-div {
                font-size: 20px;
            }
        }
    }
}
