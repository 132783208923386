
.actual-image {
    width: 24px;
    height: 24px;

    background-position: center;
    background-size: cover;
}

.badge_profile {
    font-size: 12px;
}
