<template>
    <div v-if="component_done_loading" class="col-xs-12 presentation-mode-cr">
        <div v-if="!fromDashboard" class="nav-tabsContainer">
            <div class="links">
                <ul>
                    <li :class="{ active: tab == 'leaderboard' }" @click="tab = 'leaderboard'">
                        <a class="nav-link" data-toggle="tab" href="#leaderboard" aria-label="Leaderboard"> Leaderboard </a>
                    </li>
                    <li :class="{ active: tab == 'question' }" @click="tab = 'question'">
                        <a class="nav-link" data-toggle="tab" href="#questions" aria-label="Questions"> Questions </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" :class="{ 'tab-content': !fromDashboard }">
            <div
                v-if="!fromDashboard && tab == 'leaderboard' && test.leaderboard"
                id="leaderboard"
                :class="{ active: tab == 'leaderboard' }"
                class="tab-pane fade in"
            >
                <e-gallery-timer-progress :test-obj="test"></e-gallery-timer-progress>
            </div>
            <div
                v-if="tab == 'question' && test.questions"
                id="questions"
                :class="{
                    'tab-pane fade in': !fromDashboard,
                    active: tab == 'question' && !fromDashboard,
                }"
            >
                <div v-if="component_done_loading && fromDashboard" class="gradient panel">
                    <div class="panel-heading flex gap10">
                        <h2 class="fs-18px margin0">
                            <template v-if="!fromDashboard">
                                {{ test.name }}
                            </template>
                            Questions
                        </h2>

                        <button v-if="showWindowLink" class="btn btn-default margin0 flexInherit" @click="applicationGoTo">
                            <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Open Presentation / e-Gallery Walk Presentation Mode
                        </button>

                        <button
                            v-tooltip="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? 'You are not allowed to manage this test' : ''"
                            class="btn btn-primary margin0 flexInherit"
                            :class="{ 'disabled hasToolTip': !$can('manage_test', 'Course-' + testObj?.course?.uuid) }"
                            :aria-disabled="!$can('manage_test', 'Course-' + testObj?.course?.uuid)"
                            @click="!$can('manage_test', 'Course-' + testObj?.course?.uuid) ? '' : downloadQuestions"
                        >
                            <i class="fa fa-download marginRight8" aria-hidden="true"></i>Export All Questions
                        </button>
                    </div>
                    <div class="panel-body">
                        <div class="col-xs-12">
                            <question-groups
                                :test-obj="test"
                                :activities-status="activitiesStatus"
                                :options="{
                                    showRealtimeToggle: !fromDashboard,
                                }"
                            ></question-groups>
                        </div>
                    </div>
                </div>
                <div v-else-if="component_done_loading && !fromDashboard">
                    <question-groups :test-obj="test" :options="{ showRealtimeToggle: !fromDashboard }"></question-groups>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../../../../../components/auth/auth';
export default {
    props: ['testObj', 'options', 'activitiesStatus'],
    data() {
        let auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            test: {},
            fromDashboard: false,
            showWindowLink: false,
            tab: 'question',
            readOnlyLabel: 'You have read-only access',
            debouncedUpdate: null,
        };
    },
    watch: {
        testObj: {
            handler: function () {
                if (this.fromDashboard) {
                    this.test = this.testObj;
                }
            },
            deep: true,
        },
        tab: {
            handler: function () {
                var that = this;
                this.fetchTest().then(function (response) {
                    let a = {};
                    _.merge(a, that.test, response.data.data);
                    that.test = a;
                    that.$nextTick(function () {
                        that.$forceUpdate();
                    });
                });
            },
            deep: true,
        },
    },
    created() {
        var that = this;
        this.showWindowLink = _.get(this.options, 'showWindowLink', true);
        if (this.$route.name == 'activities.tests.dashboard') {
            this.fromDashboard = true;
            this.test = this.testObj;
            this.component_done_loading = true;
            this.processEchoListeners();
        } else {
            this.fetchTest().then(function (response) {
                let a = {};
                _.merge(a, that.test, response.data.data);
                that.test = a;
                that.component_done_loading = true;
                that.processEchoListeners();
            });
        }
        this.debouncedUpdate = _.debounce(function () {
            that.fetchTest({ _internal: { hasLoadingBar: false } }).then(function (response) {
                let questions = _.cloneDeep(that.test.questions);
                let a = {};
                _.merge(a, that.test, response.data.data);
                that.test = a;
                that.test.questions = response.data.data.questions;
                if (that.test.type == 'application' && that.test.questions) {
                    for (var i = 0; i < that.test.questions.length; i++) {
                        for (var j = 0; j < that.test.questions[i].length; j++) {
                            let question = that.test.questions[i][j];
                            question.answers = _.get(question, 'answers', []);
                            if (question.answers instanceof Array) {
                                for (var k = 0; k < question.answers.length; k++) {
                                    let old_answer_idx = _.findIndex(questions[i][j].answers, function (o) {
                                        return o.uuid == question.answers[k].uuid;
                                    });
                                    if (old_answer_idx != -1) {
                                        question.answers[k].isGoodAnswer = questions[i][j].answers[old_answer_idx].isGoodAnswer;
                                        question.answers[k].isSelected = questions[i][j].answers[old_answer_idx].isSelected;
                                        question.answers[k].applicationGrade = questions[i][j].answers[old_answer_idx].applicationGrade;
                                    }
                                }
                            }
                        }
                    }
                }
            });
        });
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c1 = window.Echo.private(`course.${that.test.course.uuid}.teacher`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });
            let h2 = (e) => {
                if (e.testUuid != that.test.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c2 = window.Echo.private(`test.${that.test.uuid}.teacher`).listen('StudentAnswerSaved', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'StudentAnswerSaved',
                handler: h2,
            });

            let h3 = (e) => {
                that.debouncedUpdate();
            };
            let c3 = window.Echo.private(`test.${that.test.uuid}.teacher`).listen('TeamDiscussionUpdated', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'TeamDiscussionUpdated',
                handler: h3,
            });
            let h4 = (e) => {
                if (e.testUuid != that.testObj.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c4 = window.Echo.private(`test.${that.test.uuid}.teacher`).listen('TeamAnswerSaved', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'TeamAnswerSaved',
                handler: h4,
            });
            let h5 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                this.debouncedUpdate();
            };
            let c5 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('TestVisibilityUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'TestVisibilityUpdated',
                handler: h5,
            });
        },
        toggleSync() {
            var that = this;
            axios
                .post('tests/' + this.test.uuid + '/discussions/sync', {
                    allowDisplayAnswer: false,
                    allowDisplayAnswerStatistics: true,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question answers displayed for all questions',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to display question answers at this moment, please try again later',
                    });
                });
        },
        fetchQuestions(options) {
            return axios.get('tests/' + this.$route.params.id + '/presentation/questions', options);
        },
        fetchLeaderboard() {
            return axios.get('tests/' + this.$route.params.id + '/presentation/leaderboard');
        },
        fetchTest(options) {
            if (this.tab == 'question') {
                return this.fetchQuestions(options);
            } else if (this.tab == 'leaderboard') {
                return this.fetchLeaderboard();
            }
        },
        downloadQuestions() {
            var that = this;
            axios({
                url: '/activities/' + this.test.activityUuid + '/questions/activity-questions/export',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(' + that.test.type + ')' + that.test.name + '_Questions_' + moment().format('YYYY_MM_DD') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        applicationGoTo() {
            this.$router.push({
                name: 'activities.tests.questions',
                params: { id: this.test.uuid },
            });
        },
    },
    components: {
        'question-pagination': require(`./partials/question-pagination.vue`).default,
        'question-groups': require(`./partials/questions/question-groups.vue`).default,
        'e-gallery-timer-progress': require(`./e-gallery-timer-progress.vue`).default,
    },
    beforeUnmount() {
        //from mixin.
        Events.off('current-question-changed');
    },
};
</script>
<style scoped>
.nav-tabsContainer {
    margin-top: 10px;
}

.presentation-mode-cr .links {
    margin: 0;
}

/* .presentation-mode-cr .links ul {
		border-bottom: 3px solid #273142;	
	} */

.presentation-mode-cr .links ul li a,
.presentation-mode-cr .links ul li.active a {
    border-bottom: 0;
    padding-bottom: 0;
}

.presentation-mode-cr .links ul li:not(:first-child) {
    margin-left: 15px;
}

.presentation-mode-cr .links ul li a {
    color: #273142;
}

.presentation-mode-cr .links ul li.active a {
    color: #fff;
}

.presentation-mode-cr .links ul li.active {
    border: 1px solid #273142;
    padding: 10px;
    border-bottom: 0;
    line-height: 1;
    border-radius: 4px 4px 0 0;
    background: #273142;
    border-bottom: 0;
}

.presentation-mode-cr .links ul li {
    border: 1px solid #d8d8d8;
    padding: 10px;
    border-bottom: 0;
    line-height: 1;
    border-radius: 4px 4px 0 0;
    background: #fff;
    border-bottom: 0;
}

.presentation-mode-cr .nav-tabsContainer {
    margin: 0;
}
</style>
