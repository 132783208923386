
.test-listing-row.selected {
    background-color: rgba(0, 255, 0, 0.3);
}
.btn.selected {
    background-color: yellow !important;
    color: black !important;
}

.new-studentTest-index-table-container {
    min-height: calc(100vh - 100px);
    .new-studentTest-index-table {
        margin-bottom: 30px;
        overflow: auto;
    }
}

.studentIndexGrid {
    display: flex;
    align-items: center;

    .btn {
        flex: auto;
    }
}
