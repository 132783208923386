
.wrap {
    position: relative;
}

.scroll-container {
    overflow: auto;

    table {
        margin-bottom: 0;
    }
}

.shadow-top,
.shadow-right,
.shadow-bottom,
.shadow-left {
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.shadow-top {
    top: 0;
}

.shadow-bottom {
    bottom: 0;
    transform: rotate(180deg);
}

.shadow-right,
.shadow-left {
    top: 0;
    bottom: 0;
    width: 15px;
    background-image: linear-gradient(90deg, rgba(0, 0, 20, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
}

.shadow-right {
    right: 0;
    transform: rotate(180deg);
}

.shadow-left {
    left: 0;
}

.is-active {
    opacity: 1;
}
