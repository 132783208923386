<template>
    <div>
        <div class="col-xs-12 col-md-6">
            <div>
                <div class="pb-30px">
                    <kr-select
                        class="no-error"
                        display="Payment Plan"
                        name="plan"
                        :form="form"
                        :items="account.paymentPlans"
                        item_value_idx="value"
                        item_desc_idx="description"
                    >
                        <template #option="props">
                            <template v-for="plan in props.model" :key="plan.type">
                                <option :value="plan.type">{{ plan.plan }} ({{ plan.price }})</option>
                            </template>
                        </template>
                    </kr-select>
                </div>
                <div>
                    <kr-field
                        display="Bookstore Name"
                        :form="form"
                        name="bookstoreName"
                        aria-label="bookstore name"
                        :options="{
                            placeholder: 'Enter Bookstore Name',
                            required: true,
                        }"
                    ></kr-field>
                </div>

                <div>
                    <kr-field
                        display="Quantity"
                        :form="form"
                        name="quantity"
                        aria-label="quantity"
                        :options="{
                            type: 'number',
                            min: 1,
                            placeholder: 'Enter quantity',
                            required: true,
                        }"
                    ></kr-field>
                </div>

                <div>
                    <kr-field
                        display="Access Code Prefix"
                        :form="form"
                        name="prefix"
                        aria-label="access code prefix"
                        :options="{
                            placeholder: 'Enter Access Code Prefix',
                            required: true,
                        }"
                    ></kr-field>
                </div>

                <!--<div class="form-group">
                    <label class="control-label">DATE CREATED</label>
                    <p class="form-control-static">
                        <template v-if="account.dateCreated"> 
                            {{convertToReadableDate(form.model.dateCreated,'DD MMM YYYY, hh:mm a').date}} ({{convertToReadableDate(form.model.dateCreated,'DD MMM YYYY').current_timezone}})
                        </template> 
                        <template v-else>
                            -
                        </template>
                    </p>
                </div>-->

                <div class="flex align-items toggle-display marginBottom30">
                    <div class="flexOnly align-items justifyCenter marginRight10">
                        <label class="form-switch">
                            <input
                                type="checkbox"
                                id="validityPeriodToggle"
                                :checked="localSetValidityPeriod"
                                @change="handleToggleChange($event.target.checked)"
                                aria-label="Toggle Validity Period"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div>
                        <label for="validityPeriodToggle" class="control-label fontBlack margin0"> Set Validity Period </label>
                    </div>
                </div>

                <div v-if="localSetValidityPeriod" class="marginBottom30">
                    <kr-field
                        display="Validity Period (Weeks)"
                        :form="form"
                        name="validityPeriod"
                        aria-label="validity period by weeks"
                        :options="{
                            placeholder: 'Enter no. of weeks',
                            type: 'number',
                            min: '1',
                            required: true,
                        }"
                        @changed="processValidityPeriodChange"
                    />

                    <div>
                        <kr-date :form="form" name="validForRefundDate" :options="options" display="Valid For Refund Before"></kr-date>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Valid Until</label>
                    <p class="form-control-static">
                        <template v-if="localSetValidityPeriod">
                            {{ activatedBefore }} ({{ convertToReadableDate(account.dateCreated, 'DD MMM YYYY').current_timezone }})
                        </template>
                        <template v-else> No Validity Period Set </template>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        form: Object,
        account: Object,
        setValidityPeriod: {
            type: Boolean,
            default: true,
            required: true,
        },
    },
    data() {
        return {
            localSetValidityPeriod: this.setValidityPeriod,
            options: {
                minDate: null,
                maxDate: null,
            },
            activatedBefore: null,
        };
    },
    mounted() {
        if (this.localSetValidityPeriod) {
            this.processValidityPeriodChange();
        }
    },
    watch: {
        setValidityPeriod: {
            immediate: true,
            handler(value) {
                this.localSetValidityPeriod = value;
            },
        },
    },
    methods: {
        handleToggleChange(value) {
            this.localSetValidityPeriod = value;
            this.$emit('update:setValidityPeriod', value);
            if (!value) {
                this.form.model.validityPeriod = '';
                this.form.model.validForRefundDate = '';
            } else {
                this.form.model.validityPeriod = 6;
                this.processValidityPeriodChange();
            }
        },
        processValidityPeriodChange() {
            let now = moment();
            if (this.form.model.validityPeriod) {
                this.options.minDate = now.format('YYYY-MM-DD');
                this.options.maxDate = _.cloneDeep(now).add(this.form.model.validityPeriod, 'weeks').format('YYYY-MM-DD');
                this.activatedBefore = _.cloneDeep(now).add(this.form.model.validityPeriod, 'weeks').format('DD MMM YYYY');

                if (this.form.model.validityPeriod == 1) {
                    this.form.model.validForRefundDate = _.cloneDeep(now).add(4, 'days').format('YYYY-MM-DD');
                } else {
                    this.form.model.validForRefundDate = _.cloneDeep(now)
                        .add(this.form.model.validityPeriod / 2, 'weeks')
                        .format('YYYY-MM-DD');
                }
            }
        },
    },
};
</script>

<style scoped>
.pb-30px {
    padding-bottom: 30px;
}
</style>
