
.mini_badgeProfile {
    height: 24px;
    width: 24px;
    background: #989b9c;
    border-radius: 50%;
}

.profile_image.marginRight5 {
    margin-right: 5px;
}

.gridBase table {
    table-layout: fixed;
    width: 100%;
}
.transparent.table,
table.transparent th,
table.transparent td {
    border: 0;
    box-shadow: none;
    text-align: center;
}

.transparent.table thead tr {
    background-image: none;
    background: none;
    box-shadow: none;
}

table.transparent tbody tr td {
    padding: 20px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:first-child td {
    padding: 20px 0 0;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:first-child td:first-child {
    padding: 20px 20px 0 0;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr td {
    padding: 20px 0 0;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

/*table.transparent tbody tr:not(:first-child) td:first-child {
	padding:10px 20px 0 0;
	text-align:left;
	font-weight:bold;
	vertical-align:middle;
}*/

table.transparent tbody tr:last-child td {
    padding-bottom: 0;
}

table.transparent td:last-child div {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table > thead > tr > th {
    vertical-align: middle;
}

.transparent.table > thead > tr:first-child > th {
    padding: 0 0 5px;
}

.transparent.table > thead > tr:nth-child(2) > th {
    padding: 5px 20px 10px;
}

.transparent.table > thead > tr:last-child > th {
    padding: 5px 20px 0;
}

.transparent.table td:nth-child(2) div.gradient.btn-default {
    border-left: 1px solid #d8d8d8;
    border-right: 0;
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.transparent.table td:not(:nth-child(2)) div.gradient.btn-default {
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    border-radius: 0;
    box-shadow: none;
}

.transparent.table td:last-child div.gradient.btn-default {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table td:nth-child(2):last-child div.gradient.btn-default {
    border: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) th:not(:first-child) {
    border: 1px solid #d8d8d8;
    border-right: 0;
    padding: 20px;
    text-transform: none;
}

.transparent.table thead tr:nth-child(3) th:last-child {
    border-right: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) {
    margin-bottom: 10px;
}

.transparent {
    td {
        .ratingRadioButton {
            border: solid 1px #d8d8d8;
            box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
            border-left: none;
            border-right: none;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
            padding: 6px 12px !important;
            height: 36px;
            border-radius: 0;
        }

        &:nth-child(2) {
            .ratingRadioButton {
                border-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-left: solid 1px #d8d8d8;
            }
        }

        &:last-child {
            .ratingRadioButton {
                border-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-right: solid 1px #d8d8d8;
            }
        }
    }
}

// .radioField {
//     input {
//         display: block;
//         margin: 0;
//         appearance: none;
//         -moz-appearance: none;
//         -webkit-appearance: none;
//         position: relative;
//         width: 15px;
//         height: 15px;
//         border-radius: 50%;
//         box-shadow: inset 0 0 0 2px #fff;
//         border: solid 2px #222;
//         background: #fff;
//         margin-right: 5px;

//         &:hover {
//             border: 2px solid #0071be;
//         }

//         &:checked {
//             border-color: #0071be;
//             box-shadow: inset 0 0 0 3px #fff;
//             background: #0071be;

//             &:before {
//                 content: inherit;
//             }

//             &:disabled {
//                 box-shadow: inset 0 0 0 3px #fff;
//                 border-color: #d8d8d8;
//                 background: #d8d8d8;
//             }
//         }

//         &:disabled {
//             border-color: #d8d8d8;
//             background: #d8d8d8;
//         }
//     }
// }

@media (max-width: 991px) {
    .evaluation.radioField.ratingRadioButton:not(:last-child) {
        margin-bottom: 20px;
    }
}

.width15percent {
    width: 15%;
}

.width150px {
    min-width: 60px;
}

.badge_profile {
    font-size: 12px;
}
