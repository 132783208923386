body.modal-open {
    overflow: hidden;
}

.modal {
    background-color: rgba(0, 0, 0, 0.3);
}
.modal-backdrop {
    position: relative;
}
.modal-xl {
    width: 98vw;
}

.modal {
    text-align: center;
    overflow-y: auto;
}

.modal-backdrop.fade.in {
    z-index: 50001 !important;
}

.modal-open {
    overflow: auto;
    padding-right: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}
.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-content {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #ededed;
    border: none;
}

.modal-header {
    padding: 20px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    border-bottom: solid 1px #ededed;
}

.modal-title {
    color: #222;
    font-size: 1.33rem;
    line-height: 1;
    font-weight: bold;
}

.modal-header .close {
    margin-top: 0;
    margin-right: 0;
    color: #222;
    opacity: 1;
    font-size: 16px;
    padding: 0;
    text-shadow: none;

    i {
        font-size: 1.2rem;
        line-height: 1;
    }
}

.close {
    opacity: 1;
    color: #222;
    text-shadow: none;

    i {
        font-size: 1.2rem;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.modal-body {
    padding: 20px;
    display: inline-block;
    width: 100%;
    background: #fff;

    &.d-flex {
        display: flex;
    }
}

.modal-footer {
    background-color: #495060;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 5px 15px;
}

.modal-dialog textarea {
    resize: none;
    height: 150px;
    border: solid 1px #717171;
    outline: none;

    &:focus,
    &:focus-visible {
        border: 1px solid #0071be;
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }

    &[rows] {
        height: inherit;
    }
}

.modal {
    .blue-modal-new {
        border-radius: 24px;
        overflow: hidden;

        .modal-body {
            background: #f1f7ff;

            img {
                border-radius: 10px;
            }
        }
    }
    &.modal-manual {
        right: inherit;
        bottom: inherit;
        padding: 0 !important;
        overflow: hidden;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        .modal-dialog {
            margin: 0;
        }

        .ui-resizable {
            .modal-content {
                height: 100%;
                overflow: auto;
            }
        }

        .ui-resizable-handle {
            &:after {
                font-family: FontAwesome;
                color: black;
                font-size: 15px;
            }
        }
    }
}

.ui-draggable .ui-dialog-titlebar {
    cursor: move;
}
.ui-draggable-handle {
    -ms-touch-action: none;
    touch-action: none;
}

.ui-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    -ms-touch-action: none;
    touch-action: none;
    transform: rotate(45deg);
}

.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
    display: none;
}

.ui-resizable-n {
    cursor: n-resize;
    height: 15px;
    width: 15px;
    top: 0.5%;
    left: 0.5%;

    &:after {
        content: '\f0d9';
    }
}

.ui-resizable-nw {
    cursor: nw-resize;
    height: 15px;
    width: 15px;
    top: 0.5%;
    left: 0.5%;

    &:after {
        content: '\f0d9';
    }
}

.ui-resizable-ne {
    cursor: ne-resize;
    width: 15px;
    height: 15px;
    top: 0.5%;
    right: 0.5%;

    &:after {
        content: '\f0d8';
    }
}

.ui-resizable-e {
    cursor: e-resize;
    width: 15px;
    height: 15px;
    top: 0.5%;
    right: 0.5%;

    &:after {
        content: '\f0d8';
    }
}

.ui-resizable-s {
    cursor: s-resize;
    height: 15px;
    width: 15px;
    bottom: 0.5%;
    right: 0.5%;

    &:after {
        content: '\f0da';
    }
}

.ui-resizable-se {
    cursor: se-resize;
    height: 15px;
    width: 15px;
    bottom: 0.5%;
    right: 0.5%;

    &:after {
        content: '\f0da';
    }
}

.ui-resizable-sw {
    cursor: sw-resize;
    height: 15px;
    width: 15px;
    bottom: 0.5%;
    left: 0.5%;

    &:after {
        content: '\f0d7';
    }
}

.ui-resizable-w {
    cursor: w-resize;
    width: 15px;
    height: 15px;
    bottom: 0.5%;
    left: 0.5%;

    &:after {
        content: '\f0d7';
    }
}

@media (min-width: 768px) {
    .ui-resizable {
        min-width: 600px;
        width: 600px;

        .modal-dialog {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        .modal-content {
            height: 100%;
        }
    }
}

.warning-modal,
.primary-modal,
.danger-modal,
.default-modal,
.dark-modal {
    .warning-icon {
        position: absolute;
        left: 45%;
        top: -20px;
        width: 50px;
    }

    .modal-header {
        background: #ca3333;
        padding: 16px;

        .modal-title {
            color: #fff;
            font-size: 1.333rem;
        }

        .close {
            height: auto;

            i {
                font-size: 1.2rem;
                line-height: 1;
            }
        }
    }

    .modal-body {
        padding-top: calc(20px + 5%);
        font-size: 0.875rem;
        padding: 20px;

        &.padding20px82px {
            padding: 20px 82px;
        }

        dl {
            dt,
            dd {
                font-size: 1.111rem;
            }
        }

        p {
            font-size: 1.111rem;

            &.fontSize14 {
                font-size: 0.778rem;
            }

            &.fs-18px {
                font-size: 1rem;
            }

            &.fs-24px {
                font-size: 1.33rem;
            }

            &.fs-26px {
                font-size: 1.44rem;
            }
        }

        &.modalFs-18px {
            p {
                font-size: 1rem;
            }
        }

        .alert {
            p {
                font-size: 1rem;
                padding-top: 2px;
            }
        }

        .questionBankDetailsContent {
            .header {
                background: #495060;
                padding: 5px 10px;
                margin-bottom: 5px;

                label {
                    color: #fff;
                }
            }

            p {
                font-size: 1rem;
            }
        }

        .questionBankModalText {
            color: #717171;
            font-size: 1rem;
        }

        .fs-16 {
            font-size: 0.889rem;
        }

        .fs-18px {
            p {
                font-size: 1rem;
            }
        }

        .timer {
            display: inline-block;
            padding: 5px 10px;
            background: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            color: #3c7858;

            @media (min-width: 992px) {
                margin-left: 5px;
            }
        }
    }

    .modal-footer {
        padding: 16px;
        padding-top: 10px;
        background: #fff;
        border-top: none;
    }

    @media (min-width: 992px) {
        .modal-footer {
            .btn + .btn {
                margin-left: 16px;
            }
        }
    }

    @media (max-width: 992px) {
        .modal-footer {
            .btn + .btn {
                margin-left: 0;
            }
        }
    }

    .box-shadow-box {
        display: inline-block;
        padding: 5px 10px;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        color: #3c7858;
    }

    @media (min-width: 450px) {
        .modal-footer {
            .btn + .btn {
                margin-left: 16px;
                margin-top: 0;
            }
        }
    }

    @media (max-width: 450px) {
        .modal-footer {
            .btn {
                width: 100%;
            }

            .btn + .btn {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}

.warning-modal {
    .modal-header {
        background: #e69b23;

        .modal-title {
            color: #222;
        }

        .close {
            color: #222;
        }
    }
}

.primary-modal {
    .modal-header {
        background: #23356d;

        .modal-title {
            color: #fff;
        }

        .close {
            color: #fff;
        }
    }
}

.default-modal {
    .modal-header {
        background: linear-gradient(to bottom, #ffffff, #eff2f6);

        .modal-title {
            color: #222;
        }
    }
}

.danger-modal {
    .modal-header {
        .close {
            color: #fff;
        }
    }
}

.modalListingArray {
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        font-size: 1rem;
    }
}

.dark-modal {
    .modal-header {
        background: #273142;
        color: #fff;

        .modal-title {
            color: #fff;
        }

        .close {
            color: #fff;
        }
    }

    .modal-body {
        background: #273142;
        color: #fff;

        legend {
            color: #fff;
        }

        .bg-white {
            legend {
                color: #3c3c43;
            }

            color: #222;
        }
    }

    .modal-footer {
        border-top: solid 1px #ededed;
        background: #273142;
    }
}
