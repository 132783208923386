
.remove-btn,
.remove-btn:active,
.remove-btn:visited,
.remove-btn:focus,
.remove-btn:hover {
    z-index: 3;
    bottom: 0;
    right: 0;
    background: #ca3333;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    color: #fff;
    position: absolute;
    display: -ms-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
}

.actual-image {
    background-color: #273142;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    background-position: center;
    background-size: cover;
}

.preview-image {
    .actual-image {
        margin: 0;
        border-radius: 0;

        .remove-btn {
            right: -5px;
            bottom: -5px;
        }
    }
}

.upload-profile-picture-button {
    display: block;
    background-color: #273142;
    width: 100px;
    height: 100px;
    border-radius: 0;
    color: #fff;
    font-weight: normal;
    padding: 6px 6px;
    font-size: 16px;
}
