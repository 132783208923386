.logs-container {
    .logs-item {
        display: flex;
        margin-bottom: 20px;
        position: relative;
    }

    .logs-time {
        width: 162px;
        text-align: right;
        padding-right: 20px;
        flex-shrink: 0;
        font-size: 1rem;
        color: #333;

        p {
            font-size: 1rem;
        }
    }

    .vertical-line {
        width: 4px;
        background-color: #6f42c1;
        position: absolute;
        top: 0;
        bottom: -20px;
        left: 0;
        transform: translateX(-50%);
    }

    .horizontal-line {
        position: absolute;
        width: 16px;
        height: 2px;
        background-color: #6f42c1;
        left: -8px;
        top: 0;
        transform: translateY(-50%);
    }

    .logs-box {
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 10px 15px;
        margin-left: 25px;

        p {
            font-size: 1rem;
        }
    }
}
