
#test_confirm_modal .modal-body .message {
    text-align: center;
}
#test_confirm_modal .modal-body .message .fa {
    font-size: 40px;
    color: #e69618;
}
.individual {
    padding: 91px 25px 95px !important;
}

@media (min-width: 992px) {
    .timer.individual {
        padding: 130px 25px 95px !important;
    }

    .test .evaluation.right-container {
        padding: 0 25px 78px !important;
    }
}

@media (max-width: 991px) {
    .timer.individual {
        padding: 103px 25px 95px !important;
    }

    .test .evaluation.right-container {
        padding: 0 25px 78px !important;
    }
}
