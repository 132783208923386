
.outlineSuccess {
    color: #3c7858;
    border: 1px solid #3c7858;
    padding: 6px 12px;
    font-size: 0.88rem;
    border-radius: 3px;
}
.outlineDanger {
    color: #ca3333;
    border: 1px solid #ca3333;
    padding: 6px 12px;
    font-size: 0.88rem;
    border-radius: 3px;
}
.outlineGrey {
    color: #717171;
    border: 1px solid #989b9c;
    padding: 6px 12px;
    font-size: 0.88rem;
    border-radius: 3px;
}

a .fa-stack,
button .fa-stack {
    width: 18px;
    height: 18px;
    line-height: 1;
}

.timeText {
    color: #222;
    font-size: 0.667rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: -17px;
}
#dashboardResetModal .modal-body .row:last-child {
    margin-top: 20px;
}

#dashboardResetModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#dashboardResetModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.timerTopBar {
    background: rgb(255, 255, 255);
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);

    &.bg-light-blue {
        background: #dbfbff;
    }
    .btn {
        flex: inherit;
    }
}

.krToggle {
    display: flex;
    align-items: center;
}

.krToggle .control-label {
    margin-bottom: 0;
    line-height: 0.8;
}

.toggleDescriptWidth {
    width: calc(100% - 60px);
}
#gradesExportConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#gradesExportConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#gradesExportConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

#gradesPassbackConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#gradesPassbackConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#gradesPassbackConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
#settingsModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#settingsModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.dashboard-nav {
    position: relative;

    .nav.nav-pills {
        overflow: hidden;
        max-height: 34px;

        & > li + li {
            margin-left: 10px;
        }

        &.show {
            overflow: visible;
        }

        .nav-item {
            .nav-link {
                line-height: 1;
                padding: 0.5rem 1rem;
                border: 1px solid #989b9c;
                background: #fff;
                border-radius: 0.25rem;

                .activity_name,
                .test_name {
                    display: none;
                }

                &.active,
                &:hover {
                    color: #fff;
                    background-color: #007bff;
                    border: 1px solid #007bff;
                }

                &:hover {
                    .activity_name,
                    .test_name {
                        display: block;
                    }
                }
            }

            &.drop {
                width: 150px;
                max-width: 200px;
                display: block;
                position: absolute;
                right: 0;
                z-index: 20;
                margin-left: 0;

                .nav-link {
                    border: none;
                    border-radius: 0;

                    &.active,
                    &:hover {
                        color: #007bff;
                        background-color: #fff;
                        border: none;
                    }
                }

                &::after {
                    content: none;
                }

                &:first-child {
                    margin-top: 10px;
                    .nav-link {
                        border-top-left-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                    }
                }

                &:last-child {
                    .nav-link {
                        border-bottom-left-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                    }
                }
            }
        }
    }

    .nav > li > a {
        display: flex;
    }

    .click {
        position: absolute;
        line-height: 1;
        padding: 0.5rem 1rem;
        border: 1px solid #989b9c;
        background: #fff;
        border-radius: 0.25rem;
        color: #717171;
        top: 0;
        right: 0;

        &:hover {
            cursor: pointer;
        }
    }

    .emptyNavDropdown {
        position: absolute;
        right: 0;
        background: #fff;
        border-radius: 4px;
        z-index: 20;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .nav-item {
                padding: 0.25rem 1rem;
                max-width: 150px;
                line-height: 1.2;

                .activity_name,
                .test_name {
                    display: none;
                }

                &.active,
                &:hover {
                    .nav-link {
                        color: #007bff;
                        background-color: #fff;
                        border: none;
                    }
                }

                &:hover {
                    .activity_name,
                    .test_name {
                        display: block;
                    }
                }
            }
        }
    }
}

.dashboard-tab {
    display: inline-flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;

    .dashboard-activities-nav {
        max-width: 100%;
        width: calc(100% - 52px - 52px);
        border-bottom: 4px solid #ddd;

        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .links {
        margin-bottom: 0;
        border-bottom: none;
    }

    ul {
        margin: 0;
        position: relative;
        color: white;
        display: inline-block;
        padding: 0;
        outline: 0;
        display: inline-flex;
        list-style: none;
        white-space: nowrap;

        max-width: 100%;
        width: 100%;
        overflow: auto;
        margin-bottom: -4px;
        padding: 0 4px;

        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            padding: 3px;
            border-bottom: 4px solid transparent;

            &:not(:first-child) {
                margin-left: 25px;
            }

            &.active {
                border-bottom: 4px solid #0071be;
            }

            a {
                font-size: 0.88rem;
                font-weight: bold;
            }
        }
    }
}

.tableStatusTag,
.borderGrey,
.outlineSuccess {
    display: flex;
    align-items: center;
    line-height: 0.8;
}
