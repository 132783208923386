
i.fa-trophy {
    display: inline-block;
    border-radius: 60px;
    padding: 0.5em;
    color: #fff;
    font-size: 8px;
}

.studentAnalysis .first {
    background-color: #fdfbf4;
}

.first i.fa-trophy {
    box-shadow: 0px 0px 2px #dab025;
    background: #facd36;
    border: 1px solid #dab025;
}

.studentAnalysis .second {
    background-color: #fbfbfb;
}

.second i.fa-trophy {
    box-shadow: 0px 0px 2px #a3a3a3;
    background: #dadada;
    border: 1px solid #a3a3a3;
}

.studentAnalysis .third {
    background-color: #fdf8f3;
}

.third i.fa-trophy {
    box-shadow: 0px 0px 2px #9c4317;
    background: #c6937a;
    border: 1px solid #9c4317;
}

.fixedHeight69px {
    height: 69px;
}

.openended-space {
    width: 250px;
    height: 50px;
    overflow: hidden;
}

.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv-app {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv-app .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv-app1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tableDiv-app1 {
    min-width: 618px;
    width: 618px;
}

.tableDiv-app {
    width: calc(100% - 618px);
}

.tableDiv-app1 .table > thead > tr > th,
.tableDiv-app1 .table > thead > tr > td,
.tableDiv-app1 .table > tbody > tr > th,
.tableDiv-app1 .table > tbody > tr > td,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app .table > thead > tr > th,
.tableDiv-app .table > thead > tr > td,
.tableDiv-app .table > tbody > tr > th,
.tableDiv-app .table > tbody > tr > td,
.tableDiv-app .table > tfoot > tr > th,
.tableDiv-app .table > tfoot > tr > th {
    padding: 15px;
}

thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}

table {
    border-top: 0;
}

#header-fixed,
#studentAnalysisIRAT-clone-left-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}

#studentAnalysisIRAT-clone-left-fixed {
    border-right: 1px solid #ddd;
}

#studentAnalysisIRAT-clone-left-fixed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 0;
}

#studentAnalysisIRAT-clone-left {
    border-top-left-radius: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: none;
}

#studentAnalysisIRAT-clone-right {
    border-right: 0;
    border-bottom: 0;
    box-shadow: none;
}

#studentAnalysisIRATOverall {
    border: 1px solid #ddd;
    border-radius: 3px;
}

#studentAnalysisIRAT-tableOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.width112px {
    min-width: 112px;
}

.width50px {
    max-width: 50px;
}

.nameColumn {
    word-wrap: break-word;
    max-width: 130px;
}

/* new table */

.new-table-container-analysis {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 52.84px;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .sticky-top-header {
        position: sticky;
        top: 0;
        left: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        text-transform: uppercase;
    }

    .new-left-table-IRAT-student-analysis {
        min-width: 600px;
        width: 600px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-right-table-IRAT-student-analysis {
        width: calc(100% - 600px);
        max-height: 500px;
        scrollbar-width: none;

        .sticky-top-header {
            left: 0;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 5px;
            background: #d8d8d8;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #989b9c;
            border-radius: 2px;
        }
    }

    .new-left-table-scrollbar-IRAT-student-analysis {
        width: 600px;
    }

    .new-right-table-scrollbar-IRAT-student-analysis {
        width: calc(100% - 600px);
    }

    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar-IRAT-student-analysis,
            .new-right-table-scrollbar-IRAT-student-analysis {
                border: 0;
            }
        }

        & > div {
            border: 1px solid #ddd;
            overflow: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}
