
.assume-leadership-div {
    border: 1px solid #0071be;
    border-radius: 3px;
    background: #cfe2f3;
    padding: 10px 20px;
}

span.assume-leadership {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
}
span.team-initial {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #495060;
    line-height: 26px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
}

.fas.fa-user-circle {
    font-size: 20px;
    color: #8a8a8a;
}

.assign_reporter .profile_image {
    width: 48px;
    height: 48px;
    border-radius: 30px;
    margin-right: 10px;
}

.assign_reporter .actual-image {
    width: 100%;
    height: 100%;
    background: #222;

    background-position: center;
    background-size: cover;
}

.assignDiv {
    margin-top: 30px;
}

.assignDiv:first-child {
    margin-top: 0;
}

.status-avatar {
    min-width: 48px;
    height: 48px;
    margin-right: 10px;
    position: relative;

    .status-avatar-content {
        width: 100%;
        height: 100%;
        background: #2a3f54;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 18px;
        color: white;

        &.absent {
            opacity: 0.5;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .position-absolute {
        position: absolute;
        bottom: -2px;
        right: -5px;
        line-height: 0.8;

        &.present {
            color: #3c7858;
        }

        &.absent {
            color: #717171;
        }

        .fas.fa-circle {
            border: 3px solid #fff;
            border-radius: 50%;
        }
    }
}

.attendanceMarker {
    border-radius: 8px;
    padding: 6px 12px;

    &.present {
        background: #0071be;
        border: 1px solid #0071be;
        color: #fff;

        &:hover {
            background: #1941a6;
            border: 1px solid #1941a6;
        }
    }
}

.assume-leadership-div {
    .fa-user-circle {
        color: #222;
    }
}
