
.qns_Qns,
.qnsBuilderQnsOptions,
.qns_Attachment,
.qns_allAttachments,
.difficultyLevel,
.questionsTopic,
.faculityNotes {
    margin-top: 30px;
}

.hoverTooltip {
    width: 200px;
    color: #000;
    text-align: center;
    padding: 5px;
    border-radius: 3px;
    background: #fff;
    border: solid 1px #e69618;
}

.tags {
    width: auto;
    padding: 10px;
    height: 36px;
    border-radius: 3px;
    background-color: rgba(71, 166, 71, 0.1);
}

.questionsTopicLabel {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questionsTopicLabel span {
    line-height: 1;
}

.questionsTopicLabel .fa-times {
    font-size: 12px;
    color: #717171;
}

.questionsTopic.marginTop0 {
    margin-top: 0;
}

@media (min-width: 992px) {
    .questionsTopicCons {
        display: flex;
        flex-wrap: wrap;
    }

    .questionsTopicLabel,
    .questionsTopicCons input {
        min-width: 150px;
    }

    .questionsSubs {
        margin-right: 20px;
    }
}

@media (max-width: 991px) {
    .questionsTopicLabel,
    .questionsTopicCons input {
        width: 100%;
    }

    .questionsSubs {
        margin-top: 10px;
    }

    .questionsSubs:first-child {
        margin-top: 0;
    }
}

.modal .modal-body .row:last-child {
    margin-top: 20px;
}

.modal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
.modal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
