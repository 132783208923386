
.modal-content {
    width: 400px !important;
}
.modal-body {
    width: 400px;
}

.calculator {
    display: grid;
    grid-template-rows: repeat(7, minmax(60px, auto));
    grid-template-columns: repeat(4, 60px);
    grid-gap: 12px;
    padding: 35px;
    font-weight: 300;
    font-size: 18px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 80px -30px rgba(13, 81, 134, 1);
}

.btn {
    margin-left: 0px;
}

.btn,
.zero {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    /* outline: none; */
    color: #ffffff;
    background-color: #135b79;
    border: 2px solid #135b79;
    border-radius: 5px;

    &:hover {
        color: #135b79;
        border-color: #135b79;
        background: #fff;
    }

    &:active {
        color: #fff;
        background: #083243;
        border-color: #083243;
    }
}

.display,
.answer {
    grid-column: 1 / 5;
    display: flex;
    align-items: center;
}

.display {
    color: #222222;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: clip;
}

.answer {
    font-weight: 500;
    color: #135b79;
    font-size: 55px;
    height: 65px;
    overflow-x: auto;
    overflow-y: hidden;
}

.zero {
    grid-column: 1 / 3;
}

.operator {
    background-color: #0e435a;
    border: 2px solid #0e435a;
    color: #ffffff;

    &:hover {
        color: #0e435a;
        border-color: #0e435a;
        background: #fff;
    }

    &:active {
        color: #fff;
        background: #082633;
        border-color: #082633;
    }
}
