
#test_confirm_modal .modal-body .message {
    text-align: center;
}
#test_confirm_modal .modal-body .message .fa {
    font-size: 40px;
    color: #e69618;
}
.individual {
    padding: 91px 25px 95px !important;
}
.group {
    padding: 91px 25px 95px !important;
}

@media (min-width: 992px) {
    .timer.individual,
    .timer.group {
        padding: 130px 25px 95px !important;
    }
}

@media (max-width: 991px) {
    .timer.individual,
    .timer.group {
        padding: 103px 25px 95px !important;
    }

    .test .right-container {
        padding: 105px 25px 73px 25px !important;
    }
}
#previewChooseTestTypeModal .modal-body .row:last-child {
    margin-top: 30px;
}

#previewChooseTestTypeModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#previewChooseTestTypeModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.print {
    display: none;
}
@media print {
    .print {
        display: block;
    }
}
