
.option div:first-child {
    font-weight: bold;
}

.option div:last-child {
    margin-left: 10px;
    font-weight: normal;
}

.option {
    margin-top: 20px;
    line-height: 1.2;
}

.option:first-child {
    margin-top: 0;
}

.option.correct {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #f3fffd;
    border: 1px solid #3c7858;
    border-radius: 3px;
    padding: 10px;
}

.option .fa-check-circle {
    color: #3c7858;
    margin-right: 10px;
}
.mini-panel .panel-heading {
    font-size: 14px;
}

.mini-panel .panel-heading .link {
    /* color: #0071be; */
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

.discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams {
    white-space: normal;
}

.commentTeams div {
    margin-top: 15px;
}

.commentTeams div:first-child {
    margin-top: 0;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 10px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}

table.barChart {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: 330px;
    max-height: 330px;
    position: relative;
    overflow: auto;
    border: none;
    box-shadow: none;
}

.barChart .bars {
    display: flex;
    flex-direction: column;
}

.barChart .bars tr {
    flex-grow: 1;
}

.barChart .bars td {
    text-align: center;
    display: block;
    margin-right: 0.5em;
    line-height: 0em;
}

.barChart .bars td::after {
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    position: absolute;
}

.barChart .body {
    display: flex;
    flex-grow: 1;
    max-height: 200px;
    border-bottom: 2px solid #d8d8d8;
}

.barChart .body tr {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 2% 0 0;
    position: relative;
    width: 150px;
    max-height: 200px;
}

.barChart .body tr:first-child {
    margin-left: 2%;
}

.barChart .record {
    text-align: center;
    position: absolute;
    top: 200px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0071be;
    font-weight: bold;
    line-height: 1;
    width: 150px;
    padding: 10px;
}

.barChart .bars {
    background: #989b9c;
    display: block;
    width: 30px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0;
    max-height: 184px;
}

.barChart .percentage {
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
}

.barChart {
    tr {
        flex-grow: 1;

        &:nth-child(6n + 1) .btn {
            margin: 0;
            background: #e48b3a;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 2) .btn {
            margin: 0;
            background: #4e669b;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 3) .btn {
            margin: 0;
            background: #81359f;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 4) .btn {
            margin: 0;
            background: #428459;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 5) .btn {
            margin: 0;
            background: #bfa677;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 6) .btn {
            margin: 0;
            background: #ba4747;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.barChart .bars.correct {
    background: #3c7858;
}
.barChart .bars.wrong {
    background: #ca3333;
}
