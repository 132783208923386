.form-switch,
.switch {
    position: relative;
    display: inline-block;
    min-width: 64px;
    width: 64px;
    height: 32px;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 34px;
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #c8c8c8;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: '';
            height: 24px;
            width: 24px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23FFFFFF' /%3E%3Cpath d='m4 13H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z' fill='%233C3C43' /%3E%3C/svg%3E");
        }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }
    }

    input {
        opacity: 0;
        width: 64px;
        height: 32px;

        & + .slider {
            cursor: pointer;
        }

        &:disabled,
        &.disabled {
            & + .slider {
                background-color: #6c6c6c;
                cursor: not-allowed;

                &:before {
                    background-color: #eeeeee;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23EEEEEE' /%3E%3Cpath d='m4 13H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z' fill='%233c3c4c' /%3E%3C/svg%3E");
                }
            }
        }

        &:checked {
            & + .slider {
                background-color: #761fbb;

                &:before {
                    -webkit-transform: translateX(32px);
                    -ms-transform: translateX(32px);
                    transform: translateX(32px);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23ffffff' /%3E%3Cpath d='M9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z' fill='%23761fbb'/%3E%3C/svg%3E");
                }
            }

            &:disabled,
            &.disabled {
                & + .slider {
                    background-color: #6c6c6c;

                    &:before {
                        -webkit-transform: translateX(32px);
                        -ms-transform: translateX(32px);
                        transform: translateX(32px);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23eeeeee' /%3E%3Cpath d='M9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z' fill='%236c6c6c'/%3E%3C/svg%3E");
                    }
                }
            }
        }

        &:focus {
            & + .slider {
                box-shadow: 0 0 1px #2196f3;
            }
        }
    }
}
